import React, {useEffect, useState} from 'react';
import {Button, Input, message, Modal, Table, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import axiosInstance from "../../axios/axiosInstance";

function StoreCategory(props) {

   const [categories, setCategories] = useState([]);
   const [isOpen, setIsOpen] = useState(false);
   const [modalMode, setModalMode] = useState('add'); // 'add' or 'edit'
   const [selectedCategory, setSelectedCategory] = useState(null);
   const [categoryName, setCategoryName] = useState('');


   const fetchCategories = async () => {
      try {
         const response = await axiosInstance.get('/financiers/categories');
         setCategories(response.data.data);
      } catch (error) {
         console.error('Error fetching categories:', error);
      }
   };

   useEffect(() => {
      fetchCategories();
   }, []);

   const openModal = (mode, category = null) => {
      setModalMode(mode);
      if (mode === 'edit' && category) {
         setSelectedCategory(category);
         setCategoryName(category.name);
      } else {
         setCategoryName('')
      }
      setIsOpen(true);
   };

   const closeModal = () => {
      setIsOpen(false);
      setSelectedCategory(null);
      setCategoryName('')
   };

   const deleteCategory = async (categoryId) => {
      try {
         await axiosInstance.delete(`/financiers/categories/${categoryId}`);
         message.success('category deleted successfully');
      } catch (error) {
         console.error('Error deleting category:', error);
         message.error('Error deleting category');
      }
      fetchCategories();
   };

   const addOrUpdateCategory = async () => {
      try {
         if (modalMode === 'add') {
            const response = await axiosInstance.post('/financiers/categories', {name: categoryName});
            setCategories([...categories, response.data]);
            message.success('category added successfully');
         } else if (modalMode === 'edit' && selectedCategory) {
            await axiosInstance.put(`/financiers/categories/${selectedCategory.id}`, {name: categoryName});
            message.success('Category updated successfully');
         }
         closeModal();
         fetchCategories();
      } catch (error) {
         console.error('Error adding/updating category:', error);
         message.error('Error adding/updating category');
      }
   };

   const columns = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id',
      },
      {
         title: 'номи',
         dataIndex: 'name',
         key: 'name',
      },
      {
         title: 'Харакат',
         key: 'action',
         render: (text, record) => (
             <div>
                <Tooltip title="Ўзгартириш">
                   <Button
                       type="dashed"
                       icon={<EditOutlined/>}
                       onClick={() => openModal('edit', record)}
                       style={{marginRight: 8}}
                   />
                </Tooltip>
                <Tooltip title="Ўчириш">
                   <Button
                       type="dashed"
                       danger
                       icon={<DeleteOutlined/>}
                       onClick={() => deleteCategory(record.id)}
                   />
                </Tooltip>
             </div>
         ),
      },
   ];

   return (
       <div>
          <div className="flex px-10 justify-between items-center mb-4">
             <h1 className="text-xl font-semibold mb-3">Барча категориялар</h1>
             <Button type="primary" icon={<PlusOutlined/>} onClick={() => openModal('add')}>
                Янги қўшиш
             </Button>
          </div>
          <Table columns={columns} dataSource={categories} rowKey="id" pagination={{pageSize: 10}}/>

          <Modal
              title={modalMode === 'add' ? 'категория қўшиш' : 'категория ўргатириш'}
              open={isOpen}
              onCancel={closeModal}
              onOk={addOrUpdateCategory}
              okText={modalMode === 'add' ? 'Қўшиш' : 'Янгилаш'}
          >
             <Input
                 placeholder="номи"
                 value={categoryName}
                 onChange={(e) => setCategoryName(e.target.value)}
                 style={{marginBottom: 16}}
             />
          </Modal>
       </div>
   );
}

export default StoreCategory;