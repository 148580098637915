import React, { useState } from 'react';
import {Button, Drawer, Space, Table, Steps, Form, Input, message, Radio} from 'antd';
import dayjs from 'dayjs';
import { TbLayoutBottombarCollapseFilled } from "react-icons/tb";
import axiosInstance from "../axios/axiosInstance";
import toast from "react-hot-toast";

const { Step } = Steps;

const ServiceDrawer = ({ visible, onCancel,  data, fetchStationary, fetchOrder }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [form] = Form.useForm();
    const [orders, setOrders] = useState([]);

    const handleDischarge = async () => {
        if (!data) {
            toast.error('Сервисы не выбраны.');
            return;
        }

        try {
            await axiosInstance.put(`/stationary/${data.id}`, {
                doctor_id: data.doctor?.id,
                bed_id: data.bed_id?.id,
                start_at: data.start_at,
                end_at: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                remark: 'Выличеное',
                status: 'discharged'
            });

            toast.success('Бемор муваффақиятли даваоланди!');
            fetchStationary();
        } catch (error) {
            toast.error('Ошибка при выписке пациента.');
        }
    };

    const handleNext =  async () => {
        if (currentStep === 0) {
            handleDischarge();
            const fetchedOrders = await fetchOrder();
            setOrders(fetchedOrders);
            console.log(orders.orders);
            setCurrentStep(1);
        } else if (currentStep === 1) {
            form.validateFields()
                .then(async (values) => {
                    try {
                        const serviceId = orders.orders.id[0];
                        const response = await axiosInstance.post(`/visit/pay/${serviceId}`, {
                            amount: values.amount,
                            type: values.type,
                        });
                        toast.success('Оплата успешно обработана!');
                        onCancel();
                    } catch (error) {
                        toast.error('Ошибка при обработке платежа.');
                    }
                })
                .catch((info) => {
                    message.error('Пожалуйста, проверьте данные формы.');
                });
        }
    };

    const handleClose = () => {
        setCurrentStep(0);
        onCancel();
    };

    const steps = [
        {
            title: 'Информация о пациентах',
            content: (
                <div className="max-w-4xl mx-auto mt-10 p-6 bg-white rounded-lg">
                    {/* Заголовок */}
                    <div className="border-b border-gray-200 pb-4">
                        <h2 className="text-xl font-semibold text-gray-800">Информация по пациенту</h2>
                    </div>
                    {/* Основная информация */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-6">
                        {/* Карточка данных */}
                        <div className="p-4 bg-gray-50 rounded-md shadow-sm">
                            <h3 className="text-sm font-medium text-gray-500">ID пациента</h3>
                            <p className="mt-1 text-lg font-semibold text-gray-800">{data?.id}</p>
                        </div>

                        <div className="p-4 bg-gray-50 rounded-md shadow-sm">
                            <h3 className="text-sm font-medium text-gray-500">Имя пациента</h3>
                            <p className="mt-1 text-lg font-semibold text-gray-800">{data.patient?.name}</p>
                        </div>

                        <div className="p-4 bg-gray-50 rounded-md shadow-sm">
                            <h3 className="text-sm font-medium text-gray-500">Доктор</h3>
                            <p className="mt-1 text-lg font-semibold text-gray-800">{data.doctor?.name}</p>
                        </div>

                        <div className="p-4 bg-gray-50 rounded-md shadow-sm">
                            <h3 className="text-sm font-medium text-gray-500">Кровать</h3>
                            <p className="mt-1 text-lg font-semibold text-gray-800">
                                Палата № {data.bed_id?.apartment} / Ётоқ хона № {data.bed_id?.number}
                            </p>
                        </div>

                        <div className="p-4 bg-gray-50 rounded-md shadow-sm">
                            <h3 className="text-sm font-medium text-gray-500">Дата начала</h3>
                            <p className="mt-1 text-lg font-semibold text-gray-800">
                                {dayjs(data?.start_at).format('YYYY-MM-DD HH:mm')}
                            </p>
                        </div>

                        <div className="p-4 bg-gray-50 rounded-md shadow-sm">
                            <h3 className="text-sm font-medium text-gray-500">Дата окончания</h3>
                            <p className="mt-1 text-lg font-semibold text-gray-800">
                                {dayjs().format('YYYY-MM-DD HH:mm')}
                            </p>
                        </div>
                    </div>
                </div>

            ),
        },
        {
            title: 'Оплата',
            content: (
                <div className="flex">
                    <div className="w-1/2 pr-4">
                        <h3 className="text-lg font-semibold">Счет-фактура</h3>
                        <Table
                            size="small"
                            rowClassName="even:bg-gray-50"
                            dataSource={orders.orders}
                            columns={[
                                {
                                    title: 'ID',
                                    dataIndex: 'id',
                                    key: 'id',
                                },
                                {
                                    title: 'Хизмат',
                                    dataIndex: ['service', 'name'],
                                    key: 'service',
                                },
                                {
                                    title: 'Кўрсатилган сони',
                                    dataIndex: 'count',
                                    key: 'count',
                                    render: (count) => `${count}`,
                                },
                                {
                                    title: 'Нарх',
                                    dataIndex: 'amount',
                                    key: 'amount',
                                    render: (amount) => `${amount.toLocaleString('ru-RU')} сум`,
                                },
                            ]}
                            rowKey="id"
                            pagination={false}
                        />
                    </div>
                    <div className="w-1/2 pl-4">
                        <Form form={form} layout="vertical">
                            <Form.Item
                                name="amount"
                                label="Сумма"
                                rules={[{required: true, message: 'Пожалуйста, введите сумму!'}]}
                            >
                                <Input type="number" />
                            </Form.Item>


                            <Form.Item
                                name="paymentType"
                                label="Тип оплаты"
                                rules={[{ required: true, message: 'Пожалуйста, выберите тип оплаты!' }]}
                            >
                                <Radio.Group className="w-full">
                                    <div className="w-full">
                                        <Radio className="p-2  bg-gray-50 w-full rounded" value="cash">
                                           <div className="flex items-center">
                                               <img src="/icons8-euro-banknote-96.png" className="h-8" style={{ marginRight: 8 }} />
                                               Наличными
                                           </div>
                                        </Radio>
                                    </div>
                                    <div className="w-full">
                                        <Radio className="p-2 bg-gray-50 w-full rounded mt-1" value="card">
                                            <div className="flex items-center">
                                                <img src="/icons8-credit-card-96.png" className="h-8" style={{ marginRight: 8 }} />
                                                Карта
                                            </div>
                                        </Radio>
                                    </div>
                                </Radio.Group>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <Drawer
            title="Сервисы стационара"
            placement="right"
            onClose={handleClose}
            open={visible}
            extra={
                <Space>
                    <Button className="flex items-center" icon={<TbLayoutBottombarCollapseFilled size="20" />} type="primary" onClick={onCancel}>Йопиш</Button>
                </Space>
            }
            height='100%'
            width="1000"
        >
            <Steps current={currentStep} style={{ marginBottom: 16 }}>
                {steps.map((item) => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>
            <div>{steps[currentStep].content}</div>
            <div className="flex w-full" style={{ marginTop: 24 }}>
                <Button className="h-10" block type="primary" onClick={handleNext}>
                    {currentStep === steps.length - 1 ? 'Завершить' : 'Далее'}
                </Button>
                {currentStep > 0 && (
                    <Button className="h-10" block style={{ margin: '0 8px' }} onClick={() => handleClose()}>
                        Қарздорлар рўйхатига юбориш
                    </Button>
                )}
            </div>
        </Drawer>
    );
};

export default ServiceDrawer;
