import React, {useEffect, useState} from "react";
import makeAnimated from "react-select/animated";
import toast from "react-hot-toast";

import plugins from "suneditor/src/plugins";
import {
    Card,
    CardFooter,

} from "@material-tailwind/react";
import {ArrowPathIcon} from "@heroicons/react/24/solid";
import DatePicker from "./DatePicker";
import SendAnalysis from "./SignAnalysis";
import SunEditor from "suneditor-react";
import Mkb10List from "../pages/Mkb10/components/Mkb10List";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import {sendDateToServer, uploadFiles, uploadRemark} from "../services/visitService";
import {fetchMkb10Data, saveMKB10Data, searchMkb10Data} from "../services/MKB10Service";
import {Icon} from "./PatientDetailTabs";
import customPluginSubmenu from "../config/customPluginSubmenu";
import {postDispensaryData} from "../services/dispansery";
import {
    Switch,
    Upload,
    Button as ButtonAnt,
    Badge,
    Tag,
    Collapse,
    Table,
    message,
    Pagination,
    Spin,
    Radio,
    Tabs,
    Input, Modal, Select, Tooltip, Button
} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import TextEditor from "./TextEditor";
import {fetchTemplates, saveTemplate} from "../services/templateService";
import TreatmentPlan from "./TreatmentPlan";
import axiosInstance from "../axios/axiosInstance";
const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Option } = Select;
export default function AccordionCustomIcon({ patientId, mkb10, visitId, discount}) {
    const [open, setOpen] = React.useState(0);
    const [alwaysOpen, setAlwaysOpen] = React.useState(true);
    const [selectedDisease, setSelectedDisease] = useState(null);
    const [mkb10Data, setMkb10Data] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedMKB10, setSelectedMKB10] = useState([]);
    const TABLE_HEAD = ["Код", "Номланиши", "Харакат"];
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const animatedComponents = makeAnimated();
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isVisitStarted, setIsVisitStarted] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 30,
            total: 0,
        },
    });

    const [editorContent, setEditorContent] = useState('');
    const [templateName, setTemplateName] = useState(""); // New template name
    const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);
    const defaultEditorValue =
        `
      <p><strong>UNITY: o'z o'yiningizni qanday qilish kerak? </strong></p><p><br></p><p><strong>Tavsif</strong>:Taqdim etilgan yuqori sifatli darslarda biz sizga zamonaviy kompyuter o'yinini yaratishning barcha bosqichlarini batafsil aytib beramiz; Keling, o'yinlarni yaratish uchun zamonaviy dasturiy ta'minotning interfeysi, asosiy tushunchalari va tushunchalarini ko'rib chiqaylik. Bu qismda biz nima qilamiz:</p><p><br></p><ul><li>Unity ko'rinishini o'rnatish va sozlash;</li><li><br></li><li>Keling, ushbu "dvigatel" ning asosiy tushunchalarini ko'rib chiqaylik: o'yin ob'ekti, aktiv, prefabrik, komponent, sahna va boshqalar;</li><li><br></li><li>virtual uch o'lchamli fazoda o'yin ob'ektlarini joylashtirish va o'zgartirishni o'rganamiz;</li><li><br></li><li>Keling, kamera, yorug'lik manbalari, zarralar nima ekanligini ko'rib chiqaylik;</li><li><br></li><li>Unity Technologies kompaniyasining Asset Store o'yin ob'ektlari do'konini ko'rib chiqing;</li><li><br></li><li>Keling, o'yinimizda fizikadan qanday foydalanishni bilib olaylik.</li></ul><p><br></p><p>Kurs harakatlar ketma-ketligiga bo'linadi, ular orqali muallif Unity yordamida kompyuter o'yinlarini yaratishning ba'zi sirlarini batafsil ochib beradi. Kursning birinchi qismini tugatgandan so'ng, siz sertifikatga qo'shimcha ravishda o'yin sohasida martabangizni oshirish imkonini beradigan amaliy ko'nikmalarga ega bo'lasiz. Unity bugungi kunda ham mustaqil ishlab chiquvchilar, ham yirik studiyalar tomonidan yuqori sifatli kompyuter o'yinlarini yaratish uchun faol foydalanilmoqda.</p><p><br></p><p><strong>Bu kurs kimlar uchun</strong>:</p><ul><li>Kompyuter o'yinlarini rivojlantirishni orzu qiladigan maktab o'quvchilari, talabalar, kattalar</li><li>O'z kasbini dasturlash bilan bog'lamoqchi bo'lganlar</li><li>Kompyuterga ishtiyoqli va yangi bilimlarga tashnalar</li></ul><p><br></p>
  `;

    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileChange = ({ fileList }) => {
        setSelectedFiles(fileList);
    };

    const handleUpload = async () => {
        try {
            if (selectedFiles.length === 0) {
                console.error("No files selected");
                return;
            }

            const formData = new FormData();
            selectedFiles.forEach((file, index) => {
                formData.append(`upload[${index}]`, file.originFileObj);
            });

            await uploadFiles(visitId, formData);
            console.log("Files uploaded successfully");
            toast.success('Расмлар юборилди !');
        } catch (error) {
            console.error("Error uploading files:", error);
            toast.error('Файлларни юклашда хатолик юз берди!');
        }
    };

    useEffect(() => {
        const loadTemplates = async () => {
            try {
                const templatesData = await fetchTemplates();
                setTemplates(templatesData);

                if (templatesData.length > 0) {
                    setSelectedTemplate(templatesData[0].name);
                    setEditorContent(templatesData[0].html);
                }

                setLoading(false);
            } catch (error) {
                console.error('Ошибка при загрузке шаблонов:', error);
                setLoading(false);
            }
        };

        loadTemplates();
    }, []);

    const handleTemplateChange = (templateName) => {
        setSelectedTemplate(templateName);

        const selected = templates.find((template) => template.name === templateName);

        // Проверяем, найден ли шаблон и устанавливаем контент
        if (selected && selected.html) {
            setEditorContent(selected.html); // Устанавливаем контент шаблона в редактор
        } else {
            setEditorContent(''); // Сбрасываем содержимое, если шаблон не найден
        }
    };

    const handleSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append('text', editorContent);

            const result = await uploadRemark(visitId, formData);
            message.success('Комментарий успешно отправлен!');
            console.log(result);
        } catch (error) {
            message.error('Ошибка при отправке комментария.');
            console.error('Ошибка:', error);
        }
    };

    const handleSubmitTemplate = async () => {
        try {
            await saveTemplate(templateName, editorContent);
            message.success('Шаблон успешно отправлен!');
            const updatedTemplates = await fetchTemplates();
            setTemplates(updatedTemplates);
            setTemplateName('');
            setEditorContent('');
            setIsTemplateModalVisible(false);
        } catch (error) {
            message.error('Ошибка при отправке шаблона.');
            console.error('Ошибка:', error);
        }
    };





    useEffect(() => {
        const status = localStorage.getItem("visitStatus");
        setIsVisitStarted(status === "started");
    }, []);

    useEffect(() => {
        fetchData();
    }, [tableParams.pagination.current]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const { formattedData, meta } = await fetchMkb10Data(tableParams.pagination.current);

            const initialSelectedMKB10 = mkb10.map(item => item.id);
            const selectedData = formattedData.filter(item => initialSelectedMKB10.includes(item.id));
            const remainingData = formattedData.filter(item => !initialSelectedMKB10.includes(item.id));

            const combinedData = [...selectedData, ...remainingData];
            setData(combinedData);
            setFilteredData(combinedData);
            setSelectedMKB10(initialSelectedMKB10);

            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: meta.total,
                },
            });
        } catch (error) {
            console.error('Error fetching data:', error);
            message.error('Failed to load data');
        }
        setLoading(false);
    };

    const handleTableChange = (pagination) => {
        setTableParams({
            pagination,
        });
    };

    const handleOpen = () => {
        setOpen(open);
    };

    const handleSwitchChange = (id, checked) => {
        setSelectedMKB10(prevState => {
            const updatedMKB10 = checked
                ? [...prevState, id]
                : prevState.filter(item => item !== id);
            return updatedMKB10;
        });
    };

    const handleSelectChange = selectedOptions => {
        setSelectedDisease(selectedOptions);
    };

    const handleSaveMKB10 = async () => {
        try {
            // Передаем идентификатор пациента и выбранные MKB-10 коды
            await saveMKB10Data(patientId, selectedMKB10);
            toast.success('МКБ қўшилди !');
        } catch (error) {
            console.error("Error sending MKB-10 data:", error);
        }
    };

    const sendDateData = async () => {
        if (!selectedDate) {
            toast.error('Илтимос кунни танланг!');
            return;
        }

        const payload = { date_at: selectedDate };

        try {
            await sendDateToServer(visitId, payload);
            toast.success(`Кайта кабул: ${selectedDate}`);
        } catch (error) {
            console.error("Error sending date data:", error);
        }
    };

    const sendDateDespansery = async (patientId) => {

        const payload = {
            mouth_days: [selectedDate],
            patient_id: patientId,
            visit_id: visitId,
            service_id: 1,
        };

        try {
            await postDispensaryData(payload);
            console.log('sen!!!')
            toast.success(`Деспансер рўйхати: ${selectedDate}`);
        } catch (error) {
            console.error("Error sending date data:", error);
        }
    };

    const handleSearch = async () => {
        setLoading(true);
        try {
            const responseData = await searchMkb10Data(searchQuery);
            setData(responseData);
        } catch (error) {
            console.error('Error fetching MKB-10 data:', error);
            message.error('Failed to search data');
        }
        setLoading(false);
    };

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        if (query) {
            const lowerCaseQuery = query.toLowerCase();
            const filtered = data.filter(item =>
                item.code.toLowerCase().includes(lowerCaseQuery) ||
                item.name.toLowerCase().includes(lowerCaseQuery)
            );
            setFilteredData(filtered);
        } else {
            setFilteredData(data);
        }
    };



    plugins.custom_plugin_submenu = customPluginSubmenu(setTemplates);

    const editorOptions = {
        plugins: plugins,
        buttonList: [
            ['undo', 'redo'],
            ['font', 'fontSize', 'formatBlock'],
            ['paragraphStyle', 'blockquote'],
            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
            ['fontColor', 'hiliteColor', 'textStyle'],
            ['removeFormat'],
            '/',
            ['outdent', 'indent'],
            ['align', 'horizontalRule', 'list', 'lineHeight'],
            ['table', 'link', 'image', 'video'],
            ['fullScreen', 'showBlocks', 'codeView'],
            ['preview', 'print'],
            ['custom_plugin_submenu'],
            ['template'],
            ['save'],
        ],
        templates: templates,
    };


    const columns = [
        {
            title: 'Код',
            dataIndex: 'code',
            sorter: true,
            width: '20%',
        },
        {
            title: 'Номланиши',
            dataIndex: 'name',
            width: '50%',
        },
        {
            title: 'Харакат',
            dataIndex: 'id',
            render: (id) => (
                <Switch
                    checked={selectedMKB10.includes(id)}
                    onChange={(checked) => handleSwitchChange(id, checked)}
                />
            ),
        },
    ];

    const jawAreas = [
        { id: 1, name: 'Верхняя челюсть' },
        { id: 2, name: 'Нижняя челюсть' },
        { id: 3, name: 'Левая сторона' },
        { id: 4, name: 'Правая сторона' }
    ];

    const orthodonticIssues = [
        { id: 1, issue: 'Неправильный прикус' },
        { id: 2, issue: 'Перекрещенный прикус' },
        { id: 3, issue: 'Диастема' },
        { id: 4, issue: 'Кривизна зубов' },
        { id: 5, issue: 'Неправильное положение зубов' }
    ];


    const teethData = [
        {id: 18, margin: '0px 3px', name: 'Клык (Canine)'},
        {id: 17, margin: '0px -1px', name: 'Клык (Canine)'},
        {id: 16, margin: '0px', name: 'Первый премоляр (First premolar)'},
        {id: 15, margin: '0px -9px', name: 'Второй премоляр (Second premolar)'},
        {id: 14, margin: '0px -5px', name: 'Первый моляр (First molar)'},
        {id: 13, margin: '0px -11px', name: 'Второй моляр (Second molar)'},
        {id: 12, margin: '0px -5px', name: 'Третий моляр (Third molar)'},
        {id: 11, margin: '0px -10px', name: 'Центральный резец (Central incisor)'},
        {id: 21, margin: '0px -5px', name: 'Центральный резец (Central incisor)'},
        {id: 22, margin: '0px -10px', name: 'Боковой резец (Lateral incisor)'},
        {id: 23, margin: '0px -7px', name: 'Боковой резец (Lateral incisor)'},
        {id: 24, margin: '0px -10px', name: 'Клык (Canine)'},
        {id: 25, margin: '0px -8px 0px -5px', name: 'Первый моляр (First molar)'},
        {id: 26, margin: '0px', name: 'Второй моляр (Second molar)'},
        {id: 27, margin: '0px', name: 'Третий моляр (Third molar)'},
        {id: 28, margin: '0px 3px', name: 'Клык (Canine)'},
    ];

    const bottomTeethData = [
        {id: 48, margin: '0px', name: 'Первый моляр (First molar)'},
        {id: 47, margin: '0px 0px 0px -2px', name: 'Второй моляр (Second molar)'},
        {id: 46, margin: '0px', name: 'Третий моляр (Third molar)'},
        {id: 45, margin: '0px -6px', name: 'Центральный резец (Central incisor)'},
        {id: 44, margin: '0px -9px', name: 'Боковой резец (Lateral incisor)'},
        {id: 43, margin: '0px -7px', name: 'Клык (Canine)'},
        {id: 42, margin: '0px -12px', name: 'Первый премоляр (First premolar)'},
        {id: 41, margin: '0px -8px', name: 'Второй премоляр (Second premolar)'},
        {id: 31, margin: '0px -12px', name: 'Первый моляр (First molar)'},
        {id: 32, margin: '0px -8px', name: 'Второй моляр (Second molar)'},
        {id: 33, margin: '0px -6px', name: 'Третий моляр (Third molar)'},
        {id: 34, margin: '0px -6px', name: 'Клык (Canine)'},
        {id: 35, margin: '0px -6px', name: 'Первый премоляр (First premolar)'},
        {id: 36, margin: '0px -7px', name: 'Второй премоляр (Second premolar)'},
        {id: 37, margin: '0px -5px', name: 'Центральный резец (Central incisor)'},
        {id: 38, margin: '0px 0px', name: 'Боковой резец (Lateral incisor)'},
    ];

    const getToothNameById = (toothId) => {
        const tooth = teethData.find(t => t.id === toothId) || bottomTeethData.find(t => t.id === toothId);
        return tooth ? tooth.name : 'Без имени';
    };


    const [selectedTeeth, setSelectedTeeth] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [teethRemarks, setTeethRemarks] = useState({});

    const [selectedOption, setSelectedOption] = useState("none");

    const handleToothClick = (toothId) => {
        setSelectedTeeth((prev) =>
            prev.includes(toothId) ? prev.filter((id) => id !== toothId) : [...prev, toothId]
        );
    };

    const handleRemarkChange = (toothId, remark) => {
        setTeethRemarks((prev) => ({ ...prev, [toothId]: remark }));
    };


    const handleSelectionChange = (e) => {
        setSelectedOption(e.target.value);
        if (e.target.value === "upper") {
            setSelectedTeeth(teethData.map(teeth => teeth.id));
        } else if (e.target.value === "lower") {
            setSelectedTeeth(bottomTeethData.map(teeth => teeth.id));
        } else if (e.target.value === "all") {
            setSelectedTeeth([
                ...teethData.map(teeth => teeth.id),
                ...bottomTeethData.map(teeth => teeth.id),
            ]);
        } else {
            setSelectedTeeth([]);
        }
    };


    const handleTeethSubmit = () => {
        const data = new FormData();

        selectedTeeth.forEach((toothId, index) => {
            data.append(`teeth[${index}]`, toothId);
            data.append(`remark[${index}]`, teethRemarks[toothId] || "");
        });


        for (let pair of data.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
        }

        axiosInstance.post(`/visit/teethes/${visitId}`, data)
            .then(response => {
                console.log('Teeth data submitted successfully:', response.data);
                toast.success('Тиш малумотлари мувафакиятли юборилди!')
            })
            .catch(error => {
                console.error('Error submitting teeth data:', error);
            });
    };

    return (
        <>

            <div >
                <Tabs  tabPosition="left" defaultActiveKey="1">

                    <TabPane tab="Врач хулосаси" key="1">
                        <div className="flex flex-col gap-3 mb-2">
                            <Upload.Dragger
                                beforeUpload={() => false}
                                fileList={selectedFiles}
                                multiple={true}
                                onChange={handleFileChange}
                                style={{border: '1px dashed #d9d9d9', borderRadius: '4px', padding: '16px'}}
                            >
                                <p className="ant-upload-drag-icon">
                                    <DownloadOutlined/>
                                </p>
                                <p className="ant-upload-text">Файл(лар)ни бу ерга ташанг ёки</p>
                                <p className="ant-upload-hint">
                                    файл(лар)ни танлаш учун босинг
                                </p>
                            </Upload.Dragger>
                            <ButtonAnt className="shadow-none text-sm rounded-sm" size="large" type="primary"
                                       onClick={handleUpload}>Саклаш ва юбориш</ButtonAnt>
                        </div>
                        {loading ? (
                            <Spin tip="Загрузка шаблонов..."/>
                        ) : (
                            <>
                                <h3>Шаблонни танланг:</h3>
                                <div className="flex items-center gap-4">
                                    <Select
                                        onChange={handleTemplateChange}
                                        value={selectedTemplate}
                                        style={{width: '100%'}}
                                        placeholder="Шаблонни танланг"
                                    >
                                        {templates.map((template) => (
                                            <Option key={template.name} value={template.name}>
                                                {template.name}
                                            </Option>
                                        ))}
                                    </Select>
                                    <ButtonAnt
                                        className="shadow-none" block type="primary"
                                        onClick={() => setIsTemplateModalVisible(true)}>
                                        Шаблон Қўшиш
                                    </ButtonAnt>
                                </div>

                                {/* Modal for Adding a Template */}
                                <Modal
                                    title="Добавить шаблон"
                                    visible={isTemplateModalVisible}
                                    onCancel={() => setIsTemplateModalVisible(false)}
                                    footer={null}
                                >
                                    <Input
                                        placeholder="Введите имя шаблона"
                                        value={templateName}
                                        onChange={(e) => setTemplateName(e.target.value)}
                                    />
                                    <div className="my-4">
                                        <SunEditor
                                            setOptions={{
                                                buttonList: [
                                                    ['undo', 'redo'],
                                                    ['font', 'fontSize', 'formatBlock'],
                                                    ['bold', 'underline', 'italic'],
                                                    ['fontColor', 'hiliteColor', 'indent', 'outdent'],
                                                    ['align', 'list', 'table'],
                                                    ['link', 'image', 'video'],
                                                    ['fullScreen', 'showBlocks', 'codeView'],
                                                ],
                                            }}
                                            height="300px"
                                            setContents={editorContent}
                                            setDefaultStyle="font-family: Arial; font-size: 16px;"
                                            onChange={setEditorContent}
                                        />
                                    </div>
                                    <ButtonAnt
                                        block
                                        size="large"
                                        type="primary"
                                        onClick={handleSubmitTemplate}
                                        className="mt-4 text-sm"
                                    >
                                        Отправить Шаблон
                                    </ButtonAnt>
                                </Modal>

                                <div className="my-4">
                                    <SunEditor
                                        setOptions={{
                                            buttonList: [
                                                ['undo', 'redo'],
                                                ['font', 'fontSize', 'formatBlock'],
                                                ['bold', 'underline', 'italic'],
                                                ['fontColor', 'hiliteColor', 'indent', 'outdent'],
                                                ['align', 'list', 'table'],
                                                ['link', 'image', 'video'],
                                                ['fullScreen', 'showBlocks', 'codeView'],
                                            ],
                                        }}
                                        height="300px"
                                        setContents={editorContent}
                                        setDefaultStyle="font-family: Arial; font-size: 16px;"
                                        onChange={setEditorContent}
                                    />
                                </div>

                                <ButtonAnt block size="large" type="primary" onClick={handleSubmit}
                                           className="mt-4 text-sm">
                                    Отправить
                                </ButtonAnt>
                            </>
                        )}
                    </TabPane>
                    {/*<TabPane tab="Тиш Формуласи" key="0">*/}
                    {/*    <div className="flex gap-5 mb-5">*/}
                    {/*        /!* Sidebar *!/*/}
                    {/*        /!*<div className="sidebar w-1/4 p-1 bg-[#fafafa] rounded-lg shadow-sm">*!/*/}
                    {/*        /!*    <Tabs type="card" className="w-full px-4" defaultActiveKey="1">*!/*/}
                    {/*        /!*        <TabPane tab="Проблемы" key="1">*!/*/}
                    {/*        /!*            <div className="problems-list">*!/*/}
                    {/*        /!*                <Input.Search*!/*/}
                    {/*        /!*                    placeholder="Поиск по челюсти и проблемам"*!/*/}
                    {/*        /!*                    onChange={(e) => setSearchTerm(e.target.value)}*!/*/}
                    {/*        /!*                    style={{marginBottom: '1rem'}}*!/*/}
                    {/*        /!*                />*!/*/}

                    {/*        /!*                <div className="jaw-areas mb-4">*!/*/}
                    {/*        /!*                    <h3>Области челюсти</h3>*!/*/}
                    {/*        /!*                    <Select*!/*/}
                    {/*        /!*                        style={{width: '100%'}}*!/*/}
                    {/*        /!*                        showSearch*!/*/}
                    {/*        /!*                        placeholder="Выберите область челюсти"*!/*/}
                    {/*        /!*                        optionFilterProp="children"*!/*/}
                    {/*        /!*                        filterOption={(input, option) =>*!/*/}
                    {/*        /!*                            option.children.toLowerCase().includes(input.toLowerCase())*!/*/}
                    {/*        /!*                        }*!/*/}
                    {/*        /!*                    >*!/*/}
                    {/*        /!*                        {jawAreas.map((area) => (*!/*/}
                    {/*        /!*                            <Option key={area.id} value={area.name}>*!/*/}
                    {/*        /!*                                {area.name}*!/*/}
                    {/*        /!*                            </Option>*!/*/}
                    {/*        /!*                        ))}*!/*/}
                    {/*        /!*                    </Select>*!/*/}
                    {/*        /!*                </div>*!/*/}

                    {/*        /!*                <div className="orthodontic-issues mt-4">*!/*/}
                    {/*        /!*                    <h3>Проблемы</h3>*!/*/}
                    {/*        /!*                    <Select*!/*/}
                    {/*        /!*                        style={{width: '100%'}}*!/*/}
                    {/*        /!*                        showSearch*!/*/}
                    {/*        /!*                        placeholder="Выберите проблему"*!/*/}
                    {/*        /!*                        optionFilterProp="children"*!/*/}
                    {/*        /!*                        filterOption={(input, option) =>*!/*/}
                    {/*        /!*                            option.children.toLowerCase().includes(input.toLowerCase())*!/*/}
                    {/*        /!*                        }*!/*/}
                    {/*        /!*                    >*!/*/}
                    {/*        /!*                        {orthodonticIssues.map((issue) => (*!/*/}
                    {/*        /!*                            <Option key={issue.id} value={issue.issue}>*!/*/}
                    {/*        /!*                                {issue.issue}*!/*/}
                    {/*        /!*                            </Option>*!/*/}
                    {/*        /!*                        ))}*!/*/}
                    {/*        /!*                    </Select>*!/*/}
                    {/*        /!*                </div>*!/*/}
                    {/*        /!*            </div>*!/*/}
                    {/*        /!*        </TabPane>*!/*/}

                    {/*        /!*        <TabPane tab="Рентгены" key="2">*!/*/}
                    {/*        /!*            <div className="x-rays">*!/*/}
                    {/*        /!*                <p>Нет рентгеновских снимков</p>*!/*/}
                    {/*        /!*            </div>*!/*/}
                    {/*        /!*        </TabPane>*!/*/}
                    {/*        /!*    </Tabs>*!/*/}

                    {/*        /!*</div>*!/*/}

                    {/*        /!* Main Content *!/*/}
                    {/*        <div className="content flex-1">*/}
                    {/*            <div*/}
                    {/*                className="dental-formula flex flex-col items-center py-5 bg-[#fafafa] rounded-lg shadow-sm">*/}
                    {/*                <div className="teeth-container">*/}
                    {/*                    <div className="teeth-top flex items-center justify-center mb-4">*/}
                    {/*                        {teethData.map(({id, margin, name}) => (*/}
                    {/*                            <Tooltip title={name} key={id}>*/}
                    {/*                                <img*/}
                    {/*                                    className={`tooth h-20 mx-1 ${selectedTeeth.includes(id) ? 'checked' : ''}`}*/}
                    {/*                                    src={`/teeth/${id}.png`}*/}
                    {/*                                    onClick={() => handleToothClick(id)}*/}
                    {/*                                    style={{margin}}*/}
                    {/*                                    alt={name}*/}
                    {/*                                />*/}
                    {/*                            </Tooltip>*/}
                    {/*                        ))}*/}
                    {/*                    </div>*/}
                    {/*                    <div className="teeth-bottom flex items-center justify-center">*/}
                    {/*                        {bottomTeethData.map(({id, margin, name}) => (*/}
                    {/*                            <Tooltip title={name} key={id}>*/}
                    {/*                                <img*/}
                    {/*                                    className={`tooth h-20 mx-1 ${selectedTeeth.includes(id) ? 'checked' : ''}`}*/}
                    {/*                                    src={`/teeth/${id}.png`}*/}
                    {/*                                    onClick={() => handleToothClick(id)}*/}
                    {/*                                    style={{margin}}*/}
                    {/*                                    alt={name}*/}
                    {/*                                />*/}
                    {/*                            </Tooltip>*/}
                    {/*                        ))}*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}

                    {/*                /!* Selection Controls *!/*/}
                    {/*                <div className="selection-controls mt-4">*/}
                    {/*                    <Radio.Group onChange={handleSelectionChange} value={selectedOption}>*/}
                    {/*                        <Radio.Button value="upper">Верхняя полость</Radio.Button>*/}
                    {/*                        <Radio.Button value="lower">Нижняя полость</Radio.Button>*/}
                    {/*                        <Radio.Button value="all">Вся полость</Radio.Button>*/}
                    {/*                        <Radio.Button value="none">Отмена</Radio.Button>*/}
                    {/*                    </Radio.Group>*/}
                    {/*                </div>*/}

                    {/*                /!* Remarks Input *!/*/}
                    {/*                {selectedTeeth.length > 0 && (*/}
                    {/*                    <div className="remarks-section mt-5 w-full">*/}
                    {/*                        <h3>Замечания по зубам</h3>*/}
                    {/*                        {selectedTeeth.map((toothId) => (*/}
                    {/*                            <div key={toothId} className="remark-item mb-2">*/}
                    {/*                                <p>Зуб {toothId}:</p>*/}
                    {/*                                <Input.TextArea*/}
                    {/*                                    rows={1}*/}
                    {/*                                    value={teethRemarks[toothId] || ""}*/}
                    {/*                                    onChange={(e) => handleRemarkChange(toothId, e.target.value)}*/}
                    {/*                                    placeholder="Введите замечание"*/}
                    {/*                                />*/}
                    {/*                            </div>*/}
                    {/*                        ))}*/}
                    {/*                    </div>*/}
                    {/*                )}*/}

                    {/*                /!* Submit Button *!/*/}
                    {/*                <Button type="primary" className="mt-4" onClick={handleTeethSubmit}>*/}
                    {/*                    Отправить*/}
                    {/*                </Button>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</TabPane>*/}
                    <TabPane tab="Қайта қабул" key="2">
                        <div className="flex gap-4 items-center">
                            <DatePicker onChange={(date) => setSelectedDate(date)}/>
                            <ButtonAnt type="primary" className="flex items-center"
                                       icon={<ArrowPathIcon className='w-4 h-4'/>} onClick={sendDateData}>
                                Қайта қабулга қўшиш
                            </ButtonAnt>
                        </div>
                    </TabPane>

                    <TabPane tab="Процедурага юбориш" key="3">
                        {/*<SendAnalysis open={open} visitId={visitId} />*/}
                        <div className="flex gap-5 mb-5">
                            {/* Sidebar */}
                            {/*<div className="sidebar w-1/4 p-1 bg-[#fafafa] rounded-lg shadow-sm">*/}
                            {/*    <Tabs type="card" className="w-full px-4" defaultActiveKey="1">*/}
                            {/*        <TabPane tab="Проблемы" key="1">*/}
                            {/*            <div className="problems-list">*/}
                            {/*                <Input.Search*/}
                            {/*                    placeholder="Поиск по челюсти и проблемам"*/}
                            {/*                    onChange={(e) => setSearchTerm(e.target.value)}*/}
                            {/*                    style={{marginBottom: '1rem'}}*/}
                            {/*                />*/}

                            {/*                <div className="jaw-areas mb-4">*/}
                            {/*                    <h3>Области челюсти</h3>*/}
                            {/*                    <Select*/}
                            {/*                        style={{width: '100%'}}*/}
                            {/*                        showSearch*/}
                            {/*                        placeholder="Выберите область челюсти"*/}
                            {/*                        optionFilterProp="children"*/}
                            {/*                        filterOption={(input, option) =>*/}
                            {/*                            option.children.toLowerCase().includes(input.toLowerCase())*/}
                            {/*                        }*/}
                            {/*                    >*/}
                            {/*                        {jawAreas.map((area) => (*/}
                            {/*                            <Option key={area.id} value={area.name}>*/}
                            {/*                                {area.name}*/}
                            {/*                            </Option>*/}
                            {/*                        ))}*/}
                            {/*                    </Select>*/}
                            {/*                </div>*/}

                            {/*                <div className="orthodontic-issues mt-4">*/}
                            {/*                    <h3>Проблемы</h3>*/}
                            {/*                    <Select*/}
                            {/*                        style={{width: '100%'}}*/}
                            {/*                        showSearch*/}
                            {/*                        placeholder="Выберите проблему"*/}
                            {/*                        optionFilterProp="children"*/}
                            {/*                        filterOption={(input, option) =>*/}
                            {/*                            option.children.toLowerCase().includes(input.toLowerCase())*/}
                            {/*                        }*/}
                            {/*                    >*/}
                            {/*                        {orthodonticIssues.map((issue) => (*/}
                            {/*                            <Option key={issue.id} value={issue.issue}>*/}
                            {/*                                {issue.issue}*/}
                            {/*                            </Option>*/}
                            {/*                        ))}*/}
                            {/*                    </Select>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </TabPane>*/}

                            {/*        <TabPane tab="Рентгены" key="2">*/}
                            {/*            <div className="x-rays">*/}
                            {/*                <p>Нет рентгеновских снимков</p>*/}
                            {/*            </div>*/}
                            {/*        </TabPane>*/}
                            {/*    </Tabs>*/}

                            {/*</div>*/}

                            {/* Main Content */}
                            <div className="content flex-1">
                                <div
                                    className="dental-formula flex flex-col items-center py-5 bg-[#fafafa] rounded-lg shadow-sm">
                                    <div className="selection-controls mb-4">
                                        <Radio.Group onChange={handleSelectionChange} value={selectedOption}>
                                            <Radio.Button value="upper">Верхняя полость</Radio.Button>
                                            <Radio.Button value="lower">Нижняя полость</Radio.Button>
                                            <Radio.Button value="all">Вся полость</Radio.Button>
                                            <Radio.Button value="none">Отмена</Radio.Button>
                                        </Radio.Group>
                                    </div>

                                    <div className="teeth-container">
                                        <div className="teeth-top flex items-center justify-center mb-4">
                                            {teethData.map(({id, margin, name}) => (
                                                <Tooltip title={name} key={id}>
                                                    <img
                                                        className={`tooth h-20 mx-1 ${selectedTeeth.includes(id) ? 'checked' : ''}`}
                                                        src={`/teeth/${id}.png`}
                                                        onClick={() => handleToothClick(id)}
                                                        style={{margin}}
                                                        alt={name}
                                                    />
                                                </Tooltip>
                                            ))}
                                        </div>
                                        <div className="teeth-bottom flex items-center justify-center">
                                            {bottomTeethData.map(({id, margin, name}) => (
                                                <Tooltip title={name} key={id}>
                                                    <img
                                                        className={`tooth h-20 mx-1 ${selectedTeeth.includes(id) ? 'checked' : ''}`}
                                                        src={`/teeth/${id}.png`}
                                                        onClick={() => handleToothClick(id)}
                                                        style={{margin}}
                                                        alt={name}
                                                    />
                                                </Tooltip>
                                            ))}
                                        </div>
                                    </div>

                                    {/* Selection Controls */}


                                    {selectedTeeth.length > 0 && (
                                        <div className="remarks-section mt-8 w-full px-6 py-4 bg-gray-50 rounded-lg">
                                            <h3 className="text-lg font-semibold text-gray-800 mb-4">Даволаш режаси ва диагностика</h3>
                                            {selectedTeeth.map((toothId) => (
                                                <div
                                                    key={toothId}
                                                    className="remark-item mb-4 p-4 border w-full border-gray-200 rounded-lg hover:bg-gray-100 transition duration-200 ease-in-out"
                                                >
                                                    <div className="flex w-full items-center space-x-4">
                                                        {/* Tooth Image */}
                                                        <img
                                                            src={`/teeth/${toothId}.png`} // Replace with correct image path or URL
                                                            alt={`Зуб ${toothId}`}
                                                            className="w-12 h-12 object-contain"
                                                        />
                                                        {/* Tooth Information */}
                                                        <div className="w-full">
                                                            <p className="text-sm font-medium text-gray-700 mb-2">
                                                                Зуб <span className="font-semibold">{getToothNameById(toothId)}</span> ({toothId}):
                                                            </p>
                                                            <Input.TextArea
                                                                rows={2}
                                                                value={teethRemarks[toothId] || ""}
                                                                onChange={(e) => handleRemarkChange(toothId, e.target.value)}
                                                                placeholder="Диагнозни киритинг"
                                                                className="w-full text-sm border border-gray-300 rounded-lg p-2"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}


                                    {/* Submit Button */}
                                    <Button  type="primary" size="large" className=" mt-5 w-[600px] text-sm shadow-none" onClick={handleTeethSubmit}>
                                        Отправить
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <TreatmentPlan discount={discount} open={open} visitId={visitId}/>
                    </TabPane>

                    <TabPane tab="Диспансер рўйхати" key="4">
                        <div className="flex gap-4 items-center">
                            <DatePicker onChange={(date) => setSelectedDate(date)}/>
                            <ButtonAnt type="primary" className='flex items-center gap-x-1'
                                       onClick={() => sendDateDespansery(patientId)}>
                                <ArrowPathIcon className='w-4 h-4'/>
                                Диспонсер рўйхатига қўшиш
                            </ButtonAnt>
                        </div>
                    </TabPane>

                    <TabPane tab="МКБ-10" key="5">
                        <div className="px-5">
                            <label
                                className="relative bg-white min-w-sm flex flex-col md:flex-row items-center justify-center border py-2 px-2 rounded-md gap-2 focus-within:border-gray-300"
                                htmlFor="search-bar">
                                <Mkb10List/>
                                <input
                                    id="search-bar"
                                    placeholder="Қидириш"
                                    className="px-8 py-1 w-full rounded-md flex-1 outline-none bg-white"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                                <ButtonAnt onClick={handleSearch} size="md">
                                    <MagnifyingGlassIcon className="h-5 w-5"/>
                                </ButtonAnt>
                                <ButtonAnt className='rounded-md' onClick={handleSaveMKB10}>Саклаш</ButtonAnt>
                            </label>
                            <Card className="h-[40vh] shadow-none border w-full rounded-none mt-5 overflow-scroll">
                                <Table
                                    columns={columns}
                                    rowKey={(record) => record.id}
                                    dataSource={filteredData}
                                    loading={loading}
                                    onChange={handleTableChange}
                                />
                            </Card>

                            <CardFooter>
                                <Pagination
                                    {...tableParams.pagination}
                                    onChange={(page, pageSize) => {
                                        setTableParams({
                                            pagination: {current: page, pageSize, total: tableParams.pagination.total},
                                        });
                                    }}
                                />
                            </CardFooter>
                        </div>
                    </TabPane>
                </Tabs>
            </div>


        </>
    );
}