import React, { useState, useEffect } from "react";
import {Card, Input, Button, Typography, Modal, List, InputNumber, Table, Pagination} from "antd";
import { CgMathMinus, CgMathPlus } from "react-icons/cg";
import axios from "axios";
import toast from "react-hot-toast";
import axiosInstance from "../axios/axiosInstance";
import {PaperAirplaneIcon} from "@heroicons/react/24/outline";

const TreatmentPlan = ({ visitId, open, discount }) => {
    const [quantities, setQuantities] = useState({});
    const [services, setServices] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0); // Total count of services
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalAmount, setModalAmount] = useState(0);
    const [showReceipt, setShowReceipt] = useState(false);
    const [receiptData, setReceiptData] = useState(null);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 15,
        total: 0,
    });

    useEffect(() => {
        fetchServices(pagination.current);
    }, []);

    const fetchServices = async (page) => {
        try {
            const response = await axiosInstance.get(`/admin/service?page=${page}&primary=0`);
            const { data, meta } = response.data;
            setServices(data);
            setQuantities((prevQuantities) =>
                data.reduce((acc, service) => {
                    acc[service.id] = prevQuantities[service.id] || 0;
                    return acc;
                }, { ...prevQuantities })
            );
            setPagination({
                current: meta.current_page,
                pageSize: meta.per_page,
                total: meta.total,
            });
            setCurrentPage(meta.current_page);
            setTotalItems(meta.total); // Set total item count
        } catch (error) {
            console.error('Ошибка при получении списка сервисов:', error);
        }
    };

    const handleTableChange = (page) => {
        fetchServices(page);
    };

    const handleSearchChange = (e) => setSearchQuery(e.target.value);

    const incrementQuantity = (serviceId) => {
        setQuantities((prev) => ({ ...prev, [serviceId]: (prev[serviceId] || 0) + 1 }));
        updateSelectedServices(serviceId, 1);
    };

    const decrementQuantity = (serviceId) => {
        setQuantities((prev) => {
            const newQuantity = Math.max((prev[serviceId] || 1) - 1, 0);
            return { ...prev, [serviceId]: newQuantity };
        });
        updateSelectedServices(serviceId, -1);
    };

    const updateSelectedServices = (serviceId, countChange) => {
        setSelectedServices((prevSelected) => {
            const service = services.find((s) => s.id === serviceId);
            const updatedSelected = [...prevSelected];
            const serviceIndex = updatedSelected.findIndex((item) => item.id === serviceId);

            if (serviceIndex >= 0) {
                updatedSelected[serviceIndex].count += countChange;
                if (updatedSelected[serviceIndex].count <= 0) {
                    updatedSelected.splice(serviceIndex, 1);
                }
            } else if (countChange > 0) {
                updatedSelected.push({ ...service, count: 1 });
            }
            return updatedSelected;
        });
    };

    const calculateSum = (price, quantity) => price * quantity;
    const calculateTotalServicesAmount = () =>
        selectedServices.reduce((total, { price, count }) => total + calculateDiscountedSum(price, count, discount), 0);


    const isDiscountActive = (discount) => {
        const currentDate = new Date();
        const startDate = new Date(discount?.start_at);
        const endDate = new Date(discount?.end_at);
        return currentDate >= startDate && currentDate <= endDate;
    };

// Функция для расчета суммы со скидкой
    const calculateDiscountedSum = (price, quantity, discount) => {
        const total = price * quantity;
        if (isDiscountActive(discount)) {
            const discountAmount = (total * discount.percent) / 100;
            return total - discountAmount;
        }
        return total;
    };

    const columns = [
        {
            title: 'Хизмат Номи',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <Typography.Text>{text}</Typography.Text>,
        },
        {
            title: 'Нархи',
            dataIndex: 'price',
            key: 'price',
            render: (price) => <Typography.Text>{price} сум</Typography.Text>,
        },
        {
            title: 'Миқдори',
            key: 'quantity',
            render: (_, record) => (
                <div className="flex items-center gap-3">
                    <Button
                        icon={<CgMathMinus size="16" />}
                        onClick={() => decrementQuantity(record.id)}
                        disabled={quantities[record.id] === 0}
                    />
                    <InputNumber
                        min={0}
                        value={quantities[record.id] || 0}
                        onChange={(value) => setQuantities((prev) => ({ ...prev, [record.id]: value }))} // Allow manual quantity input
                    />
                    <Button
                        icon={<CgMathPlus size="16" />}
                        onClick={() => incrementQuantity(record.id)}
                    />
                </div>
            ),
        },
        {
            title: 'Жами',
            key: 'total',
            render: (_, record) => (
                <Typography.Text>{calculateSum(record.price, quantities[record.id] || 0)} сум</Typography.Text>
            ),
        },
        {
            title: 'Сумма со скидкой',
            key: 'discounted_total',
            render: (_, record) => (
                <Typography.Text>{calculateDiscountedSum(record.price, quantities[record.id] || 0, discount)} сум</Typography.Text>
            ),
        },
    ];

    const handleModalOk = () => {
        const formData = new FormData();

        // Adjust for discount
        const totalCost = calculateTotalServicesAmount();
        const finalAmount = isDiscountActive(discount) ? totalCost * (1 - discount.percent / 100) : totalCost;

        formData.append('type', 'cash');
        formData.append('amount', finalAmount.toString());

        // Add selected services
        selectedServices.forEach((service, index) => {
            formData.append(`service[${index}]`, service.id);
            formData.append(`service_count[${index}]`, service.count);
        });

        axiosInstance.post(`visit/service_mass/${visitId}`, formData)
            .then((response) => {
                setReceiptData({
                    services: selectedServices,
                    amountPaid: finalAmount,
                    totalCost: totalCost,
                    remainingAmount: finalAmount - totalCost,
                    paymentMethod: "Нақд",
                });

                // Reset states after successful submission
                setQuantities({});
                setSelectedServices([]);
                toast.success('Хизмат чеки кассага юборилди!');
            })
            .catch((error) => {
                toast.error('Ошибка при обработке запроса!');
                console.error('There was an error!', error);
            });
    };


    const handlePOSSend = () => {
        const formData = new FormData();

        // Добавляем выбранные услуги и их количество
        selectedServices.forEach((service, index) => {
            formData.append(`service[${index}]`, service.id);
            formData.append(`service_count[${index}]`, service.count);
        });

        formData.append('type', 'cash');
        formData.append('amount', 0);
        formData.append('cash', 0);

        axiosInstance.post(`visit/service_mass/${visitId}`, formData)
            .then((response) => {
                handleModalCancel();
                setReceiptData({});
                setQuantities({});
                setSelectedServices([]);

                toast.success('Хизмат чеки кассага юборилди!'); // Уведомление об успешной отправке
            })
            .catch((error) => {
                toast.error('Хизмат ни танланг ва Навбатга кушилганини хам текширинг !');
                console.error('There was an error!', error);
            });
    };


    const handleModalCancel = () => {
        setIsModalVisible(false);
        setShowReceipt(false);
        setModalAmount(0);
    };

    const handleAmountChange = (e) => {
        const value = parseFloat(e.target.value) || 0;
        setModalAmount(value);
    };

    const filteredServices = services.filter((service) =>
        service.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const printReceipt = () => {
        const printContent = document.getElementById("receipt-content");
        const printWindow = window.open('', '', 'width=600,height=400');
        printWindow.document.write('<html><head><title>Чек</title>');
        printWindow.document.write('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/antd/4.16.13/antd.min.css" />'); // Add Ant Design CSS
        printWindow.document.write('<link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">'); // Add Tailwind CSS
        printWindow.document.write('</head><body>');
        printWindow.document.write(printContent.innerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    };

    return (
        <div>
            <Input
                className="mb-5"
                placeholder="Излаш хизмат номини киритинг"
                value={searchQuery}
                onChange={handleSearchChange}
            />
            <Table
                size="small"
                dataSource={filteredServices}
                columns={columns}
                pagination={false}
                rowKey="id"
            />
            <Pagination
                className="my-5"
                current={pagination.current}
                pageSize={pagination.pageSize}
                total={pagination.total}
                onChange={handleTableChange}
                showSizeChanger={false}
            />
           <div className="w-full flex gap-4">
               <Button size="large" className="text-sm rounded-md" type="primary" onClick={() => setIsModalVisible(true)}>Жойда тўлаш ва чекни чикариш</Button>
               <Button type="default" onClick={handlePOSSend} size="large"
                       className="flex text-sm items-center rounded-md gap-x-1">
                   <PaperAirplaneIcon className="w-4 h-4"/> Кассага юбориш
               </Button>
           </div>
            <Modal
                title="Тўлов миқдори"
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
            >
                <Input
                    placeholder="Миқдорни киритинг"
                    value={modalAmount}
                    onChange={handleAmountChange}
                />
                {/* Display selected services in the modal */}
                <List
                    header={<Typography.Title level={5}>Танланган хизматлар</Typography.Title>}
                    dataSource={selectedServices}
                    renderItem={service => (
                        <List.Item>
                            <Typography.Text>{service.name} - {service.count} x {service.price} сум = {calculateSum(service.price, service.count)} сум</Typography.Text>
                        </List.Item>
                    )}
                />
                {/* Display total amount */}
                <Typography.Title level={5}>Жами: {calculateTotalServicesAmount()} сум</Typography.Title>
            </Modal>
            {showReceipt && (
                <Modal
                    title="Чек маълумотлари"
                    visible={showReceipt}
                    onCancel={handleModalCancel}
                    footer={[<div className="flex gap-4">
                        <Button block key="ok" onClick={handleModalCancel}>
                            Орқага
                        </Button>
                        <Button block type="primary" key="print" onClick={printReceipt}>
                            Чекни чиқариш
                        </Button>
                    </div>
                        ,
                    ]}
                    width="430px"
                >
                    <div id="receipt-content" className="text-left w-full text-sm p-6 overflow-auto">
                        <div className="text-center">
                            {/*<img src="/oxu.png" alt="Tailwind POS" className="mb-3 w-8 h-8 inline-block"/>*/}
                            <h2 className="text-xl font-semibold">OXU MED DENTAL</h2>
                            <span>Стоматология клиникаси</span> <br/>
                            <small>+998 91 831 99 99</small>
                        </div>
                        <div className="flex mt-4 text-xs">
                            <div className="flex-grow">No: <span>{receiptData.receiptNo}</span></div>
                            <div>{new Date().toLocaleString()}</div>
                        </div>
                        <hr className="my-2"/>
                        <div>
                            <table className="w-full text-xs">
                                <thead>
                                <tr>
                                    <th className="py-1 w-1/12 text-center">#</th>
                                    <th className="py-1 text-left">Хизмат</th>
                                    <th className="py-1 w-2/12 text-center">Миқдори</th>
                                    <th className="py-1 w-3/12 text-right">Жами</th>
                                </tr>
                                </thead>
                                <tbody>
                                {receiptData.services.map((service, index) => (
                                    <tr key={service.id}>
                                        <td className="py-2 text-center">{index + 1}</td>
                                        <td className="py-2 text-left">
                                            <span>{service.name}</span>
                                            <br/>
                                            <small>{service.price.toLocaleString()} сум</small>
                                        </td>
                                        <td className="py-2 text-center">{service.count}</td>
                                        <td className="py-2 text-right">{calculateSum(service.price, service.count).toLocaleString()} сум</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <hr className="my-2"/>
                        <div>
                            <div className="flex font-semibold">
                                <div className="flex-grow ">Жами</div>
                                <div>
                                    {receiptData.totalCost.toLocaleString()} сум</div>
                            </div>
                            <div className="flex text-xs ">
                                <div className="flex-grow ">Тўланган</div>
                                <div>{receiptData.amountPaid.toLocaleString()} сум</div>
                            </div>
                            <div className="flex text-xs ">
                                <div className="flex-grow ">Қолган</div>
                                <div>{receiptData.remainingAmount.toLocaleString()} сум</div>
                            </div>
                            <div className="flex text-xs ">
                                <div className="flex-grow ">Тўлов усули</div>
                                <div>{receiptData.paymentMethod}</div>
                            </div>
                        </div>
                        <hr className="my-2"/>
                        <div id="legalcopy text-center">
                            <p className="legal text-center">
                                <strong>Эътибор беринг!</strong>&nbsp;Биз сизга хизмат кўрсатганингиз учун раҳмат! Биз
                                бизнинг хизматимиздан фойдаланганингиз учун миннатдормиз.
                            </p>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default TreatmentPlan;
