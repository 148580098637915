import React, {useState, useEffect} from 'react';
import axiosInstance from '../axios/axiosInstance';
import {useNavigate, useParams} from 'react-router-dom';
import {
    Avatar,
    Tabs,
    Modal,
    Button,
    Select,
    Tag,
    Typography,
    Divider,
    Switch,
    Spin,
    Collapse,
    Checkbox,
    Alert,
    Descriptions, Image,
    Space, Timeline, Radio, Tooltip, List, Drawer, Input, Table
} from 'antd';
import {endVisit, fetchVisits, startVisit} from '../services/visitService';
import {PaymentHistoryTable} from '../components/PaymentHistoryTable';
import AccordionCustomIcon from '../components/AccordionCustomIcon';
import {PiCashRegisterFill, PiEyeClosedBold, PiMedalFill} from "react-icons/pi";
import {ImEye} from "react-icons/im";
import {MdHealthAndSafety, MdOutlineAdsClick, MdPlayLesson} from "react-icons/md";
import {BsFileEarmarkMedicalFill, BsFillStopwatchFill, BsSignStopFill} from "react-icons/bs";
import {ChevronUpDownIcon} from "@heroicons/react/24/outline";
import {getDispensaryDataPatient} from "../services/dispansery";
import {GiReceiveMoney, GiStopwatch} from "react-icons/gi";
import {
    FaArrowsTurnToDots, FaBriefcaseMedical,
    FaCashRegister, FaCreditCard, FaFileInvoice, FaFileSignature,
    FaHandPointLeft, FaHeartCircleCheck,
    FaHospitalUser,
    FaMoneyBillTransfer, FaStethoscope
} from "react-icons/fa6";
import {AiOutlineExclamationCircle} from "react-icons/ai";
import {TbInfoSquareRoundedFilled, TbMessageReport} from "react-icons/tb";
import {BriefcaseIcon, BuildingOfficeIcon, CalendarIcon, PhoneIcon, UserGroupIcon} from "@heroicons/react/24/solid";
import {HiLocationMarker} from "react-icons/hi";
import {FaCalendarAlt, FaUserMd} from "react-icons/fa";
import dayjs from "dayjs";
import {RiDiscountPercentFill} from "react-icons/ri";
import TabPane from "antd/es/tabs/TabPane";
const { Option } = Select;

const {Panel} = Collapse;

function PatientBioCard() {
    const token = localStorage.getItem('token');
    const {index} = useParams();
    const [patientData, setPatientData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [mostRecentVisit, setMostRecentVisit] = useState(null);
    const [visits, setVisits] = useState({});
    const [visitId, setVisitId] = useState(null);
    const [dataCache, setDataCache] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedDoctor, setSelectedDoctor] = useState('');
    const [epidemData, setEpidemData] = useState([]);
    const [selectedService, setSelectedService] = useState(null); // Состояние для выбранного сервиса
    const [selectedPartner, setSelectedPartner] = useState(null); // Состояние для выбранного партнера
    const [services, setServices] = useState([]);
    const [patientVisits, setPatientVisits] = useState([]);
    const [partners, setPartners] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const [discounts, setDiscounts] = useState([]);
    const [selectedDiscount, setSelectedDiscount] = useState(null);
    const userRole = localStorage.getItem("userRole");

    const fetchPatientData = async () => {
        try {
            const patientResponse = await axiosInstance.get(`/patients/${index}`);

            const {data} = patientResponse.data;

            const partnerId = data.partner_id;
            const districtId = data.district_id;

            let partnerResponse, districtResponse;

            if (partnerId) {
                partnerResponse = await axiosInstance.get(`/partners/${partnerId}`);
            }

            if (districtId) {
                districtResponse = await axiosInstance.get(`/global/district/${districtId}`);
            }

            const patientData = {
                patient: data,
                partnerName: partnerResponse ? partnerResponse.data.data.name : null,
                provinceName: districtResponse ? districtResponse.data.data.province.name : null,
                districtName: districtResponse ? districtResponse.data.data.name : null,
                remarks: data.remarks,
            };

            setPatientData(patientData);
            setLoading(false);
        } catch (error) {
            console.error("Ошибка при получении информации о пациенте:", error);
        }
    };
    useEffect(() => {


        fetchPatientData();
    }, [index, token]);

    const handleClick = () => {
        navigate(`/patient/admission/${index}`);
    };

    const fetchDoctors = async () => {
        try {
            const response = await axiosInstance.get("/admin/users");
            const doctorUsers = response.data.data.filter(user => user.roles.includes('doctor'));
            setDoctors(doctorUsers);
        } catch (error) {
            console.error("Ошибка при получении списка врачей:", error);
        }
    };

    const fetchDiscounts = async () => {
        try {
            const response = await axiosInstance.get("/discount");
            setDiscounts(response.data.data);
        } catch (error) {
            console.error("Ошибка при получении списка скидок:", error);
        }
    };

    const handleDiscountSelect = (value) => {
        setSelectedDiscount(value);
    };

    useEffect(() => {
        fetchDiscounts();
        fetchDoctors();
    }, []);

    const toggleEpidemActiveStatus = (id) => {
        setEpidemData(epidemData.map(item =>
            item.id === id ? {...item, active: !item.active} : item
        ));
    };


    const handleOk = () => {
        if (patient && patient.epidem) {
            const updatedEpidemData = epidemData.map(epidem => ({
                ...epidem,
                active: patient.epidem.some(patEpidem => patEpidem.id === epidem.id)
            }));
            setEpidemData(updatedEpidemData);
        }
        setIsModalOpen(true);
    };

    const handleNotOk = () => {
        setIsModalOpen(false);
    };

    const fetchRecords = async () => {
        try {
            const response = await axiosInstance.get("/epidemiological");
            const epidemData = response.data.data;
            setEpidemData(epidemData);
            if (patientData) {
                const initialSelectedEpidemIds = patientData.epidem.map(item => item.id);
                const updatedEpidemData = epidemData.map(item => ({
                    ...item,
                    active: initialSelectedEpidemIds.includes(item.id)
                }));
                setEpidemData(updatedEpidemData);
            }
        } catch (error) {
            console.error("Ошибка при получении списка эпидемии:", error);
        }
    };


    const handleSwitchChange = (checked) => {
        setIsVisible(checked);
    };

    const handleServiceSelect = (value) => {
        setSelectedService(value);
    };

    const handlePartnerSelect = (value) => {
        setSelectedPartner(value);
    };

    const handleCreateVisit = async () => {
        try {
            let url = `/visit?patient_id=${index}&doctor_id=${selectedPartner}&service_id=${selectedService}`;
            if (selectedDiscount) {
                url += `&discount_id=${selectedDiscount}`;
            }

            // Make the POST request
            const response = await axiosInstance.post(url);
            console.log("New visit created:", response.data);

            setVisitId(response.data.id);
            setMostRecentVisit(response.data);

            fetchPatientVisits();

            setIsModalVisible(false);
        } catch (error) {
            console.error("Error creating new visit:", error);
        }
    };


    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await axiosInstance.get("/admin/service?primary=1");
                setServices(response.data.data);
            } catch (error) {
                console.error("Ошибка при получении списка услуг:", error);
            }
        };

        const fetchPartners = async () => {
            try {
                const response = await axiosInstance.get("/partners");
                const partners = response.data.data;
                setPartners(partners);
            } catch (error) {
                console.error("Ошибка при получении списка партнеров:", error);
            }
        };

        fetchServices();
        fetchPartners();
    }, []);


    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const [dispensaryData, setDispensaryData] = useState(null);

    useEffect(() => {
        const fetchDispensaryData = async () => {
            if (mostRecentVisit && mostRecentVisit.id) {
                try {
                    const data = await getDispensaryDataPatient(mostRecentVisit.id);
                    setDispensaryData(data);
                } catch (error) {
                    console.error('Ошибка при получении данных о диспансере:', error);
                }
            }
        };
        fetchDispensaryData();
    }, [mostRecentVisit]);

    const renderDispensaryDates = () => {
        if (dispensaryData && dispensaryData.data) {
            const currentVisitDate = dispensaryData.data[0]?.visit?.date;
            const mouthDays = dispensaryData.data.map(item => item.mouth_days).flat();
            return (
                <ul>
                    {mouthDays.length > 0
                        ? mouthDays.map(date => <li key={date}>{date}</li>)
                        : <li>Диспансер рўхатлари йуқ</li>}
                </ul>
            );
        }
        return <li>Диспансер рўхатлари йуқ</li>;
    };

    const [isModalOpenRemark, setIsModalOpenRemark] = useState(false); // Изменено имя переменной
    const [currentRemarkHtml, setCurrentRemarkHtml] = useState(''); // Изменено имя переменной

    // Функция для открытия модала и установки выбранного HTML текста
    const handleOpenModal = (htmlContent) => { // Изменено имя функции
        setCurrentRemarkHtml(htmlContent); // Изменено имя переменной
        setIsModalOpenRemark(true); // Изменено имя переменной
    };

    // Функция для закрытия модала
    const handleCloseModal = () => { // Изменено имя функции
        setIsModalOpenRemark(false); // Изменено имя переменной
        setCurrentRemarkHtml(''); // Изменено имя переменной
    };


    const statusNames = {
        new: 'Янги',
        queue: 'Навбатда',
        pending: 'Ожидание',
        examined: 'Қабулда',
        completed: 'Завершён',
        canceled: 'Отменён',
    };
    const files = mostRecentVisit?.files ?? [];

    const teethData = [
        {id: 18, margin: '0px 3px', name: 'Клык (Canine)'},
        {id: 17, margin: '0px -1px', name: 'Клык (Canine)'},
        {id: 16, margin: '0px', name: 'Первый премоляр (First premolar)'},
        {id: 15, margin: '0px -9px', name: 'Второй премоляр (Second premolar)'},
        {id: 14, margin: '0px -5px', name: 'Первый моляр (First molar)'},
        {id: 13, margin: '0px -11px', name: 'Второй моляр (Second molar)'},
        {id: 12, margin: '0px -5px', name: 'Третий моляр (Third molar)'},
        {id: 11, margin: '0px -10px', name: 'Центральный резец (Central incisor)'},
        {id: 21, margin: '0px -5px', name: 'Центральный резец (Central incisor)'},
        {id: 22, margin: '0px -10px', name: 'Боковой резец (Lateral incisor)'},
        {id: 23, margin: '0px -7px', name: 'Боковой резец (Lateral incisor)'},
        {id: 24, margin: '0px -10px', name: 'Клык (Canine)'},
        {id: 25, margin: '0px -8px 0px -5px', name: 'Первый моляр (First molar)'},
        {id: 26, margin: '0px', name: 'Второй моляр (Second molar)'},
        {id: 27, margin: '0px', name: 'Третий моляр (Third molar)'},
        {id: 28, margin: '0px 3px', name: 'Клык (Canine)'},
    ];

    const bottomTeethData = [
        {id: 48, margin: '0px', name: 'Первый моляр (First molar)'},
        {id: 47, margin: '0px 0px 0px -2px', name: 'Второй моляр (Second molar)'},
        {id: 46, margin: '0px', name: 'Третий моляр (Third molar)'},
        {id: 45, margin: '0px -6px', name: 'Центральный резец (Central incisor)'},
        {id: 44, margin: '0px -9px', name: 'Боковой резец (Lateral incisor)'},
        {id: 43, margin: '0px -7px', name: 'Клык (Canine)'},
        {id: 42, margin: '0px -12px', name: 'Первый премоляр (First premolar)'},
        {id: 41, margin: '0px -8px', name: 'Второй премоляр (Second premolar)'},
        {id: 31, margin: '0px -12px', name: 'Первый моляр (First molar)'},
        {id: 32, margin: '0px -8px', name: 'Второй моляр (Second molar)'},
        {id: 33, margin: '0px -6px', name: 'Третий моляр (Third molar)'},
        {id: 34, margin: '0px -6px', name: 'Клык (Canine)'},
        {id: 35, margin: '0px -6px', name: 'Первый премоляр (First premolar)'},
        {id: 36, margin: '0px -7px', name: 'Второй премоляр (Second premolar)'},
        {id: 37, margin: '0px -5px', name: 'Центральный резец (Central incisor)'},
        {id: 38, margin: '0px 0px', name: 'Боковой резец (Lateral incisor)'},
    ];

    const vistTable = [
        {
            key: '1',
            label: 'Врач хулосаси:',
            children: (
                <List
                    dataSource={patientData?.remarks}
                    renderItem={(remark) => (
                        <List.Item
                            className="cursor-pointer hover:bg-gray-100 transition-colors"
                            onClick={() => handleOpenModal(remark.text)}
                        >
                            <div className="flex items-center px-4 py-2 rounded-lg border bg-gray-50">
                                <BsFileEarmarkMedicalFill size={18} color="#28c76f" className="mr-2" />
                                <span className="text-sm font-medium text-gray-700">Ташриф № {remark.visit_id}</span>
                            </div>
                        </List.Item>
                    )}
                />
            ),
        },
        {
            key: '2',
            label: 'Зубы пациента:',
            children: (
                <Table
                    dataSource={patientData?.patient.teeths}
                    columns={[
                        {
                            title: 'Зуб',
                            dataIndex: 'number',
                            key: 'number',
                            render: (number) => <span>{number}</span>,
                        },
                        {
                            title: 'Тип зуба',
                            dataIndex: 'type',
                            key: 'type',
                            render: (type) => <span>{type === 'adult' ? 'Взрослый' : 'Детский'}</span>,
                        },
                        {
                            title: 'Челюсть',
                            key: 'jaw',
                            render: (tooth) => {
                                const isUpperJaw = teethData.some(teeth => teeth.id === tooth.id);
                                const isLowerJaw = bottomTeethData.some(teeth => teeth.id === tooth.id);
                                if (isUpperJaw) {
                                    return <span>Верхняя челюсть</span>;
                                }
                                if (isLowerJaw) {
                                    return <span>Нижняя челюсть</span>;
                                }
                                return <span>Неизвестно</span>;
                            },
                        },
                        {
                            title: 'Комментарий',
                            dataIndex: 'remark',
                            key: 'remark',
                            render: (remark) => <span>{remark || 'Комментарий отсутствует'}</span>,
                        },
                        {
                            title: 'Дата визита',
                            dataIndex: 'visit',
                            key: 'visit',
                            render: (visit) => <span>{visit ? new Date(visit.date_at).toLocaleDateString() : 'Не указана'}</span>,
                        },
                        {
                            title: 'Дата создания',
                            dataIndex: 'created_at',
                            key: 'created_at',
                            render: (created_at) => <span>{new Date(created_at).toLocaleDateString()}</span>,
                        },
                        {
                            title: '',
                            key: 'image',
                            render: (tooth) => (
                                <Image
                                    src={`/teeth/${tooth.id}.png`}
                                    alt={`Зуб ${tooth.number}`}
                                    width={40}
                                    className="mx-auto"
                                    height={40}
                                    preview={false}
                                />
                            ),
                        },
                    ]}
                    rowKey="id"
                    pagination={false}
                    bordered
                    size="small"
                />
            ),
        },
        {
            key: '3',
            label: 'Қайта қабул санаси:',
            children: <>{mostRecentVisit ? mostRecentVisit.date_at : 'Нет информации'}</>,
        },
        {
            key: '4',
            label: 'Диспансер хисобига олинганми:',
            children: <>{renderDispensaryDates()}</>,
        },
        {
            key: '5',
            label: 'Юкланган файллар:',
            children: (
                <div>
                    <Typography variant="small" color="blue-gray" className="font-normal">
                        <Image.PreviewGroup
                            preview={{
                                onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                            }}
                        >
                            {Array.isArray(files) && files.length > 0 ? (
                                files.map((file) => (
                                    <Image key={file.id} width={50} src={file.url} alt={file.name} className="mr-2 mb-2" />
                                ))
                            ) : (
                                <p>Файл юкланмаган</p>
                            )}
                        </Image.PreviewGroup>
                    </Typography>
                </div>
            ),
        },
        {
            key: '6',
            label: 'Қўриш:',
            children: (
                <Button
                    onClick={handleClick}
                    className="flex items-center"
                    icon={<MdOutlineAdsClick className="text-lg" />}
                    type="default"
                >
                    Барча малумотларини кўриш
                </Button>
            ),
        },
    ];

    const sendEpidemData = async () => {
        const activeEpidems = epidemData.filter(item => item.active).map(item => item.id);
        const data = {epidem: activeEpidems};

        const config = {
            method: 'post',
            url: `https://apidental.oxu.uz/api/v1/patient/epidem/${index}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        };

        try {
            const response = await axiosInstance.request(config);
            console.log("Epidem data sent successfully:", response.data);
            setIsModalOpen(false);
            await fetchPatientData();
            await fetchRecords();
        } catch (error) {
            console.error("Error sending epidem data:", error);
        }
    };


    const fetchPatientVisits = async () => {
        try {
            let visitData = [];
            const fetchAllPages = async (patientId) => {
                let allData = [];
                let page = 1;
                let lastPage = 1;

                while (page <= lastPage) {
                    try {
                        const response = await fetchVisits(patientId, page);
                        const pageData = response.data.data;
                        const meta = response.data.meta;

                        allData = [...allData, ...pageData];
                        lastPage = meta.last_page;
                        page += 1;
                    } catch (error) {
                        console.error(`Ошибка при получении данных со страницы ${page}:`, error);
                        break;
                    }
                }

                return allData;
            };

            visitData = await fetchAllPages(index);

            const recentVisit = visitData.find(visit =>
                visit.parent_id === null &&
                (visit.bill === "payed" || visit.bill === "pending") && (visit.status === "new" || visit.status === "examined" || visit.status === "queue")
            );

            setVisitId(recentVisit ? recentVisit.id : null);
            setVisits(prevVisits => ({
                ...prevVisits,
                [index]: visitData,
            }));

            setPatientVisits(visitData);
            setMostRecentVisit(recentVisit);
        } catch (error) {
            console.error('Error fetching patient visits:', error);
        }
    };

    useEffect(() => {
        fetchRecords();
    }, []);

    useEffect(() => {
        fetchPatientVisits();
    }, [index]);


    const handleStartVisit = async () => {
        setIsButtonLoading(true);
        try {
            await startVisit(visitId);
            setMostRecentVisit(prevVisit => ({...prevVisit, status: "examined"}));

        } catch (error) {
            console.error('Error starting visit:', error);

        } finally {
            setIsButtonLoading(false);
        }
    };

    const handleEndVisit = async () => {
        setIsButtonLoading(true);
        try {
            await endVisit(visitId);
            setMostRecentVisit(prevVisit => ({...prevVisit, status: "", total_payed: 0}));

        } catch (error) {
            console.error('Error ending visit:', error);

        } finally {
            setIsButtonLoading(false);
        }
    };


    const shouldRenderAccordionIcon = mostRecentVisit && mostRecentVisit.status === "examined";
    const isBillPending = mostRecentVisit &&
        mostRecentVisit.bill === "payed1";

    const statusColors = {
        queue: 'gold',
        examined: 'green',
        new: 'blue',
        pending: 'orange',
        payed: 'purple',
        revisit: 'red'
    };



    const color = mostRecentVisit && mostRecentVisit.status
        ? statusColors[mostRecentVisit.status] || statusColors.default
        : statusColors.default;




    const statusColors2 = {
        queue: '#F8D347',  // Muted yellow
        examined: '#73C76A',  // Flat green
        new: '#58AFFF',  // Flat sky blue
        pending: '#FFA866',  // Soft coral orange
        closed: '#FF5C5C',  // Flat red
        payed: '#9A77FF',  // Soft violet
        revisit: '#F8D347',  // Muted yellow
        null: '#bdbdbd',  // Soft grey
    };

    const iconBackgrounds = {
        queue: 'bg-yellow-50',     // Very light yellow
        examined: 'bg-green-50',   // Very light green
        new: 'bg-blue-50',         // Very light blue
        pending: 'bg-orange-50',   // Very light coral
        closed: 'bg-red-50',       // Very light red
        payed: 'bg-purple-50',     // Very light violet
        revisit: 'bg-yellow-50',   // Very light yellow
        null: 'bg-gray-200',       // Very light grey
    };

    const iconTextColors = {
        queue: 'text-yellow-600',   // Muted yellow text
        examined: 'text-green-600', // Flat green text
        new: 'text-blue-600',       // Sky blue text
        pending: 'text-orange-600', // Coral orange text
        closed: 'text-red-600',     // Flat red text
        payed: 'text-purple-600',   // Soft violet text
        revisit: 'text-yellow-600', // Same muted yellow
        null: 'text-gray-500',      // Neutral grey text
    };

    const iconStrokeColors = {
        queue: '#F5C201',       // Flat gold for queue
        examined: '#47A047',    // Flat green for examined
        new: '#268BE4',         // Flat blue for new
        pending: '#E89442',     // Flat coral orange for pending
        closed: '#E05353',      // Flat red for closed
        payed: '#7A5AEF',       // Flat violet for payed
        revisit: '#F5C201',     // Same flat gold for revisit
        null: '#A0A0A0',        // Flat grey for default
    };

    const statusLabels = {
        queue: 'Навбатда...',
        examined: 'Қабулда...',
        new: 'Янги кабул',
        pending: 'Тўлов кутилмоқда',
        payed: 'Тўланган',
        revisit: 'Навбатда...',
        closed: 'Йопилган',
        null: 'Фаол емас',
    };

    const transactionTypes = {
        cash: "Нақд",
        card: "Карта",
    };



    const items = [

        {
            key: '1',
            label: 'Янги Қабул',
            children: <div>


                {patientData && shouldRenderAccordionIcon && mostRecentVisit ? (
                    <>


                        <AccordionCustomIcon
                            status={mostRecentVisit.status}
                            discount={mostRecentVisit.discount}
                            visits={visits[index]}
                            patientId={index}
                            visitId={visitId}
                            mkb10={patientData.patient.mkb10}
                            remarks={patientData?.remarks}
                        />
                    </>
                ) : (
                    <>
                        {mostRecentVisit && mostRecentVisit.total_payed > 0 ? (
                            <Alert
                                className="border-none"
                                message="Бемор қабул бошланишга таййор"
                                description="Бемор хизмат учун пул тўлади учрашув бошланиши мумкин"
                                type="success"
                                showIcon
                                icon={<FaHeartCircleCheck size={30}/>}
                            />
                        ) : mostRecentVisit && mostRecentVisit.status === "new" ? (
                            <Alert
                                className="border-none"
                                message="Янги қабул қушилди"
                                description="Қабул қушилган, аммо бемор хали пул тўланмаган"
                                type="warning"
                                showIcon
                                icon={<FaMoneyBillTransfer size={30}/>}
                            />
                        ) : (
                            <Alert
                                className="border-none"
                                message="Бемор қабулга қўшилмаган"
                                description="Даволашни бошлаш учун илтимос беморни қабулга қўшинг"
                                type="info"
                                showIcon
                                icon={<TbInfoSquareRoundedFilled className="text-blue-500" size={30}/>}
                            />
                        )}
                    </>
                )}


            </div>,
        },

        {
            key: '2',
            label: 'Тўловлар Тарихи',
            children: <PaymentHistoryTable patientId={index}/>,
        },

        {
            key: '3',
            label: 'Қабулларни Кўриш',
            children:
                <>
                    <Descriptions column={1} title="Қабулар" bordered items={vistTable}/>

                    <Collapse className="mt-5" accordion>
                        {patientVisits.length > 0 ? (
                            patientVisits.map((visit) => (
                                <Panel header={`Визит: ${visit.date_at}`} key={visit.id}>
                                    <Descriptions bordered>
                                        <Descriptions.Item label={
                                            <div className="flex flex-col items-center text-center">
                                                <div className="p-1 rounded bg-white border">
                                                    <FaHospitalUser size="20" style={{color: '#28c76f'}}/>
                                                </div>
                                                Пациент
                                            </div>
                                        }>
                                            {visit.patient_id.name}
                                        </Descriptions.Item>
                                        <Descriptions.Item label={
                                            <div className="flex flex-col items-center text-center">
                                                <div className="p-1 rounded bg-white border">
                                                    <FaStethoscope size="20" style={{color: '#28c76f'}}/>
                                                </div>
                                                Врач
                                            </div>
                                        }>
                                            {visit.doctor.name}
                                        </Descriptions.Item>
                                        <Descriptions.Item label={
                                            <div className="flex flex-col items-center text-center">
                                                <div className="p-1 rounded bg-white border">
                                                    <FaCalendarAlt size="20" style={{color: '#28c76f'}}/>
                                                </div>
                                                Дата визита
                                            </div>
                                        }>
                                            {visit.date_at}
                                        </Descriptions.Item>
                                        <Descriptions.Item label={
                                            <div className="flex flex-col items-center text-center">
                                                <div className="p-1 rounded bg-white border">
                                                    <FaFileInvoice size="20" style={{color: '#28c76f'}}/>
                                                </div>
                                                Статус
                                            </div>
                                        }>

                                            <Tag
                                                bordered={false}
                                                className="px-3 py-1 border-opacity-10 font-medium flex max-w-max items-center"
                                                color={visit ? statusColors[visit.status] : 'default'}
                                            >
                                                {visit ? statusLabels[visit.status] : 'Фаол эмас'}
                                            </Tag>
                                        </Descriptions.Item>
                                        <Descriptions.Item label={
                                            <div className="flex flex-col items-center text-center">
                                                <div className="p-1 rounded bg-white border">
                                                    <FaFileInvoice size="20" style={{color: '#28c76f'}}/>
                                                </div>
                                                Сумма
                                            </div>
                                        }>
                                            {new Intl.NumberFormat('ru-UZ').format(visit.total_amount)} сўм
                                        </Descriptions.Item>

                                        <Descriptions.Item label={
                                            <div className="flex flex-col items-center text-center">
                                                <div className="p-1 rounded bg-white border">
                                                    <FaCreditCard className="mx-auto" size="20"
                                                                  style={{color: '#28c76f'}}/>
                                                </div>
                                                Тип оплаты
                                            </div>
                                        }>
                                            {visit.transaction.map((transaction, index) => (
                                                <div key={index}>
                                                    <p>{transactionTypes[transaction.type] || transaction.type} - {new Intl.NumberFormat('ru-UZ').format(transaction.amount)} сўм</p>
                                                </div>
                                            ))}
                                        </Descriptions.Item>

                                        <Descriptions.Item
                                            label={
                                                <div className="flex flex-col items-center text-center">
                                                    <div className="p-1 rounded bg-white border">
                                                        <FaStethoscope className="mx-auto" size="20"
                                                                       style={{color: '#28c76f'}}/>
                                                    </div>
                                                    Услуга
                                                </div>
                                            }
                                        >
                                            <List
                                                bordered
                                                dataSource={visit.orders}
                                                renderItem={(order, index) => (
                                                    <List.Item className="even:bg-gray-50" key={index}>
                                                        <div className="flex justify-between  items-center"
                                                             style={{width: '100%'}}>
                                                            <span style={{
                                                                fontSize: '13px',
                                                                fontWeight: '500',
                                                                color: '#495057'
                                                            }}>{order.service.name}</span>
                                                            <span style={{
                                                                fontSize: '13px',
                                                                fontWeight: '500',
                                                                color: '#495057'
                                                            }}>
                                                        {new Intl.NumberFormat('ru-UZ').format(order.amount)} сўм
                                                            </span>

                                                        </div>
                                                    </List.Item>
                                                )}
                                            />
                                        </Descriptions.Item>

                                    </Descriptions>
                                </Panel>
                            ))
                        ) : (
                            <p>Загрузка данных...</p>
                        )}
                    </Collapse>

                </>
            ,
        },

    ];

    if (loading) {
        return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
            <Spin tip="Loading" size="large"/>
        </div>;
    }

    const {patient, partnerName, provinceName, districtName} = patientData;
    const isButtonDisabled = mostRecentVisit ? ["examined", "new", "queue", "closed"].includes(mostRecentVisit.status) : false;


    return (
        <>
            <div className='flex  items-center gap-x-3 px-10 mb-4'>
                <Switch checked={isVisible} onChange={handleSwitchChange}/>
                <Typography className="flex items-center gap-2">
                    {isVisible ? (
                        <>
                            <ImEye className="text-lg"/>
                            <span>Бемор картасини яшириш</span>
                        </>
                    ) : (
                        <>
                            <PiEyeClosedBold className="text-lg"/>
                            <span>Бемор картасини куриш</span>
                        </>
                    )}
                </Typography>
            </div>
            <div className="flex w-full relative   space-x-10">
                {isVisible && (
                    <div className='pl-10 sticky top-0  w-5/12'>
                        <div className="px-0 sm:px-0 ">
                            <h3 className="text-xl font-semibold leading-7 text-gray-900">Бемор картаси</h3>
                        </div>
                        <div className="mt-3 border-t border-gray-100">
                            <dl className="divide-y divide-gray-100">
                                <div className="px-4 py-3 sm:grid sm:grid-cols-1 sm:gap-1 sm:px-0">
                                    <div className="flex items-center gap-2">
                                        <div
                                            className="flex items-center text-5xl justify-center w-[104px] h-[104px] text-white bg-[#28c76f] rounded-lg">
                                            {patient.name.charAt(0).toUpperCase()}
                                        </div>
                                        <div className="flex flex-col gap-y-1">
                                            <span className="text-sm font-medium text-gray-800">{patient.name}</span>
                                            <span className="text-sm text-gray-500">Код: {patient.code}</span>
                                            <div className="flex items-center gap-1">
                                                <Tag
                                                    bordered={false}
                                                    className="px-3 py-1 border-opacity-10 font-medium flex max-w-max items-center"
                                                    color={mostRecentVisit ? statusColors[mostRecentVisit.status] : 'default'}
                                                >
                <span
                    className={`inline-flex items-center text-xs font-medium mr-2 justify-center rounded-full p-1 ${
                        mostRecentVisit ? iconBackgrounds[mostRecentVisit.status] : 'bg-gray-200'
                    } ${mostRecentVisit ? iconTextColors[mostRecentVisit.status] : 'text-gray-500'}`}
                >
                    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 3.99998L3.31008 6.31278C3.50527 6.5082 3.82193 6.50834 4.01729 6.31309L9 1.33331"
                            stroke={mostRecentVisit ? iconStrokeColors[mostRecentVisit.status] : '#A3A3A3'}
                            strokeWidth="1.2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </span>
                                                    {mostRecentVisit ? statusLabels[mostRecentVisit.status] : 'Фаол эмас'}
                                                </Tag>
                                            </div>
                                            <span
                                                className="text-sm text-gray-500">Хозирги қабул рақами: {visitId}</span>
                                        </div>
                                    </div>

                                    <div className="w-full my-2">
                                        {mostRecentVisit && (mostRecentVisit.status === "new" || mostRecentVisit.status === "queue") ? (
                                            // Show "Start Visit" button only if the user role is not "receptionist"
                                            userRole !== "reception" && (
                                                <Button
                                                    block
                                                    icon={<FaHospitalUser strokeWidth="1.5" size="20" />}
                                                    size="middle"
                                                    className="bg-blue-600 h-10 w-full text-sm border-none flex items-center justify-center rounded-md hover:bg-blue-700 shadow-sm"
                                                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#1ca459'} // Darker green on hover
                                                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#28c76f'}
                                                    onClick={handleStartVisit}
                                                    loading={isButtonLoading || (mostRecentVisit && mostRecentVisit.bill === "pending")}
                                                    style={{
                                                        backgroundColor: '#28c76f', // Green
                                                        borderColor: '#28c76f',
                                                        borderRadius: '4px',
                                                        color: '#FFFFFF'
                                                    }}
                                                >
                                                    {isButtonLoading ? 'Қабул бошлаяпти...' : 'Қабулни Бошлаш'}
                                                </Button>
                                            )
                                        ) : mostRecentVisit && mostRecentVisit.status === "examined" ? (
                                            // Show "End Visit" button only if the user role is not "receptionist"
                                            userRole !== "reception" && (
                                                <Button
                                                    block
                                                    icon={<FaFileSignature size="25" />}
                                                    size="middle"
                                                    danger
                                                    className="bg-blue-600 h-10 w-full text-sm border-none flex items-center justify-center rounded-md hover:bg-blue-700 shadow-sm"
                                                    onClick={handleEndVisit}
                                                    disabled={isButtonLoading}
                                                    loading={isButtonLoading}
                                                    style={{
                                                        backgroundColor: '#FFAB00', // Orange
                                                        borderColor: '#FFAB00',
                                                        borderRadius: '4px',
                                                        color: '#FFFFFF',
                                                    }}
                                                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#FF9F00'} // Darker orange on hover
                                                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#FFAB00'}
                                                >
                                                    {isButtonLoading ? 'Қабул Тугатяпти...' : 'Қабулни тугатиш'}
                                                </Button>
                                            )
                                        ) : (
                                            // Show "Add Patient" button for all roles, including "receptionist"
                                            <Button
                                                style={{
                                                    backgroundColor: '#0052CC', // Jira Blue
                                                    borderColor: '#0052CC',
                                                    borderRadius: '4px',
                                                    color: '#FFFFFF',
                                                }}
                                                onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#0041A8'} // Darker blue on hover
                                                onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#0052CC'}
                                                loading={isButtonDisabled}
                                                onClick={showModal}
                                                className="w-full h-10"
                                            >
                                                Беморни қабулга қушиш
                                            </Button>
                                        )}
                                    </div>


                                    <Drawer centered title="Создать визит" visible={isModalVisible}
                                           onClose={handleCancel} footer={[
                                <div className="flex items-center gap-4">
                                    <Button block key="back" onClick={handleCancel}>
                                        Отмена
                                    </Button>
                                    <Button block className="shadow-none" key="submit" type="primary" onClick={handleCreateVisit}>
                                        Создать
                                    </Button>
                                </div>
                                    ]}>
                                        <div className='flex flex-col gap-4'>
                                            {/* Service Selection */}
                                            <div className='w-full'>
                                                <label htmlFor="service" className="flex items-center p-1 gap-2"><FaBriefcaseMedical size="20" className="text-gray-500" /> Хизмат:</label>
                                                <Select
                                                    className='w-full'
                                                    id="service"
                                                    value={selectedService}
                                                    onChange={handleServiceSelect}
                                                    placeholder="Хизматни танланг"
                                                >
                                                    {services.map(service => (
                                                        <Select.Option key={service.id} value={service.id}>
                                                            {service.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </div>

                                            {/* Doctor Selection */}
                                            <div className='w-full'>
                                                <label htmlFor="partner" className="flex items-center p-1 gap-2"><FaUserMd size="20" className="text-gray-500" /> Врач:</label>
                                                <Select
                                                    className='w-full'
                                                    id="partner"
                                                    value={selectedPartner}
                                                    onChange={handlePartnerSelect}
                                                    placeholder="Врачни танланг"
                                                >
                                                    {doctors.map(partner => (
                                                        <Select.Option key={partner.id} value={partner.id}>
                                                            {partner.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </div>

                                            {/* Discount Selection */}
                                            <div className='w-full'>
                                                <label htmlFor="discount" className="flex items-center p-1 gap-2"><RiDiscountPercentFill size="20" className="text-gray-500" /> Чегирма:</label>
                                                <Select
                                                    className='w-full'
                                                    id="discount"
                                                    value={selectedDiscount}
                                                    onChange={handleDiscountSelect}
                                                    placeholder="Чегирмани танланг"
                                                >
                                                    {discounts.map(discount => {
                                                        const isExpired = dayjs(discount.end_at).isBefore(dayjs());
                                                        return (
                                                            <Select.Option
                                                                key={discount.id}
                                                                value={discount.id}
                                                                disabled={isExpired}
                                                            >
                                                                {`${discount.title} - ${discount.percent}%`}
                                                            </Select.Option>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </Drawer>
                                </div>
                                <div className="space-y-1 divide-y divide-gray-300/80">
                                    <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 flex items-center">
                                            <BriefcaseIcon className="h-5 w-5 mr-2 text-gray-500"/>
                                            Иш жойи:
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{patient.work_address}</dd>
                                    </div>

                                    <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 flex items-center">
                                        <CalendarIcon className="h-5 w-5 mr-2 text-gray-500"/>
                                            Туғилган сана:
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{patient.birth_at}</dd>
                                    </div>

                                    <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 flex items-center">
                                            <HiLocationMarker className="h-5 w-5 mr-2 text-gray-500"/>
                                            Манзил:
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{patient.home_address}</dd>
                                    </div>

                                    <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 flex items-center">
                                            <PhoneIcon className="h-5 w-5 mr-2 text-gray-500"/>
                                            Телефон:
                                        </dt>
                                        <dd className="mt-1 text-sm  flex gap-2 items-center leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                            <img src="/icons8-узбекистан-48.png" className="w-6 h-6"/> +{patient.phone}
                                        </dd>
                                    </div>

                                    <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 flex items-center">
                                            <BuildingOfficeIcon className="h-5 w-5 mr-2 text-gray-500"/>
                                            Вилоят:
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                            {provinceName ? provinceName : 'Нет данных о районе'}
                                        </dd>
                                    </div>

                                    <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 flex items-center">
                                            <HiLocationMarker className="h-5 w-5 mr-2 text-gray-500"/>
                                            Шаҳар/туман:
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                            {districtName ? districtName : 'Нет данных о районе'}
                                        </dd>
                                    </div>

                                    <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 flex items-center">
                                            <UserGroupIcon className="h-5 w-5 mr-2 text-gray-500"/>
                                            Касби:
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{patient.profession}</dd>
                                    </div>

                                    <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900 flex items-center">
                                            <UserGroupIcon className="h-5 w-5 mr-2 text-gray-500"/>
                                            Ким юборди:
                                        </dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                            {partnerName ? partnerName : 'Нет данных о партнере'}
                                        </dd>
                                    </div>
                                </div>


                            </dl>
                        </div>
                        <Collapse accordion className="w-full relative overflow-hidden">
                            <Panel header="Эпиденамнез" key="1">
                                <Button className="w-full mb-5" onClick={handleOk}>
                                    Қушиш
                                </Button>

                                <Modal
                                    title="Эпиданамнез Қушиш"
                                    visible={isModalOpen}
                                    onOk={sendEpidemData}
                                    onCancel={handleNotOk}
                                    okText="Қушиш"
                                    cancelText="Бекор қилиш"
                                >
                                    <table className="mt-4 w-full min-w-max table-auto text-left">
                                        <thead>
                                        <tr>
                                            <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                                                <Typography variant="small" color="blue-gray"
                                                            className="flex items-center justify-between gap-2 font-normal leading-none opacity-70">
                                                    ID{" "}
                                                </Typography>
                                            </th>
                                            <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                                                <Typography variant="small" color="blue-gray"
                                                            className="flex items-center justify-between gap-2 font-normal leading-none opacity-70">
                                                    Бемор ФИО{" "}
                                                    <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4"/>
                                                </Typography>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {epidemData.map((item) => (
                                            <tr key={item.id}>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{item.name}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                    <Checkbox
                                                        checked={item.active}
                                                        onChange={() => toggleEpidemActiveStatus(item.id)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </Modal>
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                                        >
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                                            >
                                                Номланиши
                                                <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4"/>

                                            </Typography>
                                        </th>


                                        <th
                                            className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                                        >
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                                            >
                                                Харакат
                                            </Typography>
                                        </th>

                                    </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                    {patient.epidem.map(epidem => (
                                        <tr key={epidem.id}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{epidem.name}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                {epidem.description ? epidem.description : 'Малумот йоқ'}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </Panel>
                        </Collapse>
                    </div>
                )}

                <div className='w-full h-[90vh] overflow-y-scroll overflow-hidden'>
                    <Tabs
                        className='px-12'
                        animated={true}
                        defaultActiveKey="1"
                        type="card"
                        size="large"
                        items={items}
                    />
                </div>
            </div>

            <Modal
                title="Текст заметки"
                visible={isModalOpenRemark} // Изменено имя переменной
                onCancel={handleCloseModal} // Изменено имя функции
                footer={null} // Если не хотите добавлять кнопки, можно передать null
            >
                <div dangerouslySetInnerHTML={{__html: currentRemarkHtml}}/>
                {/* Отображаем HTML-код */}
            </Modal>
        </>
    );
}

export default PatientBioCard;
