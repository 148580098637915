import React, { Fragment, useState, useEffect } from 'react';
import { Button, Input, Table, Modal, Tooltip, Typography, Card } from 'antd';
import { SearchOutlined, EditOutlined, DeleteOutlined, UserAddOutlined } from '@ant-design/icons';
import axiosInstance from '../../axios/axiosInstance';
import EpidemiologicalList from './components/EpidemiologicalList';

const { Title } = Typography;

const TABLE_HEAD = ["ID", "Номланиши", "Харакат"];

export default function EpidemiologicalHistory() {
    const [newName, setNewName] = useState('');
    const [records, setRecords] = useState([]);
    const [editingRecordId, setEditingRecordId] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchRecords();
    }, []);

    const fetchRecords = async () => {
        try {
            const response = await axiosInstance.get("/epidemiological");
            setRecords(response.data.data);
        } catch (error) {
            console.error("Ошибка при получении списка эпидемии:", error);
        }
    };

    const createEpidemiologicalRecord = async () => {
        setIsLoading(true);
        try {
            await axiosInstance.post('/epidemiological', { name: newName });
            fetchRecords();
            closeModal();
        } catch (error) {
            console.error('Ошибка при создании записи эпидемиологической истории:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const updateEpidemiologicalRecord = async (recordId) => {
        setIsLoading(true);
        try {
            await axiosInstance.put(`/epidemiological/${recordId}`, { name: newName });
            fetchRecords();
            closeModal();
        } catch (error) {
            console.error('Ошибка при обновлении записи эпидемиологической истории:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteEpidemiologicalRecord = async (recordId) => {
        setIsLoading(true);
        try {
            await axiosInstance.delete(`/epidemiological/${recordId}`);
            fetchRecords();
        } catch (error) {
            console.error('Ошибка при удалении записи эпидемиологической истории:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const closeModal = () => {
        setIsModalVisible(false);
        setNewName('');
        setEditingRecordId(null);
    };

    const openModal = (record) => {
        setIsModalVisible(true);
        setNewName(record ? record.name : '');
        setEditingRecordId(record ? record.id : null);
    };

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Номланиши",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Харакат",
            key: "action",
            render: (text, record) => (
                <span>
                    <Tooltip title="Ўзгартириш">
                        <Button type="dashed" icon={<EditOutlined />} onClick={() => openModal(record)} />
                    </Tooltip>
                    <Tooltip title="Ўчириш">
                        <Button type="dashed" icon={<DeleteOutlined />} onClick={() => deleteEpidemiologicalRecord(record.id)} />
                    </Tooltip>
                </span>
            ),
        },
    ];

    return (
        <Card title={<Title level={4}>Эпидемиологик тарих</Title>} bordered={false}>
            <div className="flex justify-between mb-4">
                <Input
                    placeholder="Қидириш"
                    prefix={<SearchOutlined />}
                    style={{ width: '60%' }}
                />
                <Button type="primary" icon={<UserAddOutlined />} onClick={() => openModal(null)}>
                    Янги  қўшиш
                </Button>
            </div>

            <Table
                columns={columns}
                dataSource={records}
                rowKey="id"
                loading={isLoading}
                pagination={{ pageSize: 10 }}
            />

            <Modal
                title={editingRecordId ? 'Эпидемиологик маълумотни ўзгартириш' : 'Эпидемиологик маълумот кўшиш'}
                visible={isModalVisible}
                onCancel={closeModal}
                footer={[
                    <Button key="cancel" onClick={closeModal}>
                        Бекор қилиш
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        loading={isLoading}
                        onClick={editingRecordId ? () => updateEpidemiologicalRecord(editingRecordId) : createEpidemiologicalRecord}
                    >
                        Сақлаш
                    </Button>,
                ]}
            >
                <Input
                    placeholder="Хизмат номи: *"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                />
            </Modal>
        </Card>
    );
}
