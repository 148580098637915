import React, { useState, useEffect, useCallback } from "react";
import {Button, Popconfirm, Spin, Table, Tag, Tooltip,  message, Input} from "antd";
import {  QuestionCircleOutlined } from "@ant-design/icons";
import axiosInstance from "../../axios/axiosInstance";
import PatientFormModal from "./components/PatientFormModal";
import {FaAddressBook, FaUserEdit} from "react-icons/fa";
import {BiDialpadAlt} from "react-icons/bi";
import {Link} from "react-router-dom";
import {TrashIcon, UserGroupIcon} from "@heroicons/react/24/solid";
import {IoIdCard} from "react-icons/io5";
import {debounce} from "lodash";
import {LuPhoneForwarded} from "react-icons/lu";
import {CgNametag} from "react-icons/cg";
import {ImUserPlus} from "react-icons/im";

const { Column } = Table;

const statusLabels = {
  queue: 'Навбатда...',
  examined: 'Қабулда...',
  new: 'Янги кабул',
  pending: 'Тўлов кутилмоқда',
  payed: 'Тўланган',
  revisit: 'Навбатда...',
  closed: 'Ёпилган',
  null: 'Фаол эмас',
};


const statusColors = {
  queue: '#F8D347',  // Muted yellow
  examined: '#73C76A',  // Flat green
  new: '#58AFFF',  // Flat sky blue
  pending: '#FFA866',  // Soft coral orange
  closed: '#FF5C5C',  // Flat red
  payed: '#9A77FF',  // Soft violet
  revisit: '#F8D347',  // Muted yellow
  null: '#bdbdbd',  // Soft grey
};

const iconBackgrounds = {
  queue: 'bg-yellow-50',     // Very light yellow
  examined: 'bg-green-50',   // Very light green
  new: 'bg-blue-50',         // Very light blue
  pending: 'bg-orange-50',   // Very light coral
  closed: 'bg-red-50',       // Very light red
  payed: 'bg-purple-50',     // Very light violet
  revisit: 'bg-yellow-50',   // Very light yellow
  null: 'bg-gray-200',       // Very light grey
};

const iconTextColors = {
  queue: 'text-yellow-600',   // Muted yellow text
  examined: 'text-green-600', // Flat green text
  new: 'text-blue-600',       // Sky blue text
  pending: 'text-orange-600', // Coral orange text
  closed: 'text-red-600',     // Flat red text
  payed: 'text-purple-600',   // Soft violet text
  revisit: 'text-yellow-600', // Same muted yellow
  null: 'text-gray-500',      // Neutral grey text
};

const iconStrokeColors = {
  queue: '#F5C201',       // Flat gold for queue
  examined: '#47A047',    // Flat green for examined
  new: '#268BE4',         // Flat blue for new
  pending: '#E89442',     // Flat coral orange for pending
  closed: '#E05353',      // Flat red for closed
  payed: '#7A5AEF',       // Flat violet for payed
  revisit: '#F5C201',     // Same flat gold for revisit
  null: '#A0A0A0',        // Flat grey for default
};


const Patients = () => {
  const [patients, setPatients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPatient, setCurrentPatient] = useState(null);
  const [phoneSearchQuery, setPhoneSearchQuery] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 15,
    total: 0
  });

  const fetchPatients = useCallback(
      async () => {
        setIsLoading(true);
        try {
          const token = localStorage.getItem('token');
          const params = {
            page: pagination.current,
            pageSize: pagination.pageSize,
          };

          if (searchQuery.trim()) {
            params.name = searchQuery;
          }


          const response = await axiosInstance.get(`/patients`, {
            params,
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json; charset=utf-8'
            }
          });

          setPatients(response.data.data);
          setPagination((prev) => ({
            ...prev,
            current: response.data.meta.current_page,
            total: response.data.meta.total
          }));
        } catch (error) {
          console.error("Ошибка при получении пациентов:", error);
        } finally {
          setIsLoading(false);
        }
      },
      [pagination.current, pagination.pageSize, searchQuery, phoneSearchQuery] // Watch pagination and search query
  );

  const debouncedPhoneSearch = useCallback(
      debounce((value) => {
        setPhoneSearchQuery(value); // Update phone search query
        fetchPatients(1); // Reset to first page on new search
      }, 500),
      []
  );

  const debouncedSearch = useCallback(
      debounce((value) => {
        setSearchQuery(value); // Update searchQuery
        setPagination((prev) => ({
          ...prev,
          current: 1,
        }));
      }, 500),
      []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };


  const handlePhoneSearchChange = (e) => {
    debouncedPhoneSearch(e.target.value);
  };

  useEffect(() => {
    fetchPatients();
  }, [fetchPatients]);


  const handleTableChange = (newPagination) => {
    setPagination((prev) => ({
      ...prev,
      current: newPagination.current,
    }));
  };

  const highlightText = (text, search) => {
    if (!search) return text;

    const regex = new RegExp(`(${search})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) =>
        regex.test(part) ? <span key={index} style={{ backgroundColor: 'yellow', fontWeight: 'bold' }}>{part}</span> : part
    );
  };

  const handleRemovePatient = async (patientId) => {
    try {
      const token = localStorage.getItem('token');
      await axiosInstance.delete(`/patients/${patientId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const updatedPatients = patients.filter(patient => patient.id !== patientId);
      setPatients(updatedPatients);
      message.success('Бемор учрилди!');
    } catch (error) {
      console.error("Ошибка при удалении пациента:", error);
    }
  };


  const handleOpenUpdateDialog = (patient) => {
    setCurrentPatient(patient);
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
    setCurrentPatient(null);
  };


  const handleSubmit = async (values) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('district_id', values.location.district_id);
    formData.append('partner_id', values.partner_id);
    if (values.pinfl) {
      formData.append('pinfl', values.pinfl);
    }
    formData.append('phone', values.phone.replace(/\D/g, ""));
    formData.append('profession', values.profession);
    formData.append('gender', values.gender);
    formData.append('home_address', values.home_address);
    formData.append('work_address', values.work_address);
    formData.append('remark', values.remark);
    if (values.file) {
      formData.append('file', values.file.file);
    }
    formData.append('birth_at', values.birth_at.format('YYYY-MM-DD'));

    try {
      if (currentPatient) {
        await axiosInstance.put(`/patients/${currentPatient.id}`, formData, {
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          }
        });
        fetchPatients(pagination.current);  // Re-fetch current page after updating
        message.success('Patient updated successfully!');
      } else {
        await axiosInstance.post('/patients', formData);
        fetchPatients(pagination.current);  // Re-fetch current page after creating
        message.success('Patient created successfully!');
      }
      handleClose();
    } catch (error) {
      message.error('Error saving patient');
      console.error('Error saving patient:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: "№",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id.localeCompare(b.id),
    },
        {
          title: "ФИО",
          dataIndex: "name",
          key: "name",
          sorter: (a, b) => a.name.localeCompare(b.name),
          render: (text, record) => (
              <div className="flex items-center gap-3">
                {/*<img*/}
                {/*    src={record.gender === 'men' ? '/man.svg' : 'woman.svg'}*/}
                {/*    alt="Avatar"*/}
                {/*    className="relative inline-block h-12 w-12 !rounded-full border border-blue-gray-50 bg-blue-gray-50/50 object-contain object-center"*/}
                {/*/>*/}
                <div>
                  <div>
                    <Link to={`/patient/${record.id}`} className="block font-sans text-sm antialiased font-medium text-[#10b981]">
                      {highlightText(text, searchQuery)}
                    </Link>
                    <span className="block mt-1 font-sans text-sm antialiased text-gray-600">
                     Код: {record.code}
                    </span>
                  </div>
                </div>
              </div>
          )
        },

        {
          title: "Туғилган санаси",
          dataIndex: "birth_at",
          key: "birth_at",
          sorter: (a, b) => new Date(a.birth_at) - new Date(b.birth_at),
        },
        {
          title: "Холати",
          dataIndex: "visit_status",
          key: "visit_status",
          render: (visitStatus) => (
              <Tag
                  bordered={false}
                  className="px-5 py-1 flex max-w-max items-center"
                  color={visitStatus ? statusColors[visitStatus.status] : 'default'}
              >
      <span
          className={`inline-flex items-center text-xs font-medium mr-2 justify-center rounded-full p-1 
        ${visitStatus ? iconBackgrounds[visitStatus.status] : 'bg-gray-200'} 
        ${visitStatus ? iconTextColors[visitStatus.status] : 'text-gray-500'}`}
      >
        <svg width='10' height='8' viewBox='0 0 10 8' fill='none'
             xmlns='http://www.w3.org/2000/svg'>
          <path
              d='M1 3.99998L3.31008 6.31278C3.50527 6.5082 3.82193 6.50834 4.01729 6.31309L9 1.33331'
              stroke={visitStatus ? iconStrokeColors[visitStatus.status] : '#A3A3A3'}
              strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round'
          />
        </svg>
      </span>
                {visitStatus ? statusLabels[visitStatus.status] : 'Статус неизвестен'}
              </Tag>
          ),
          // filters: Object.keys(statusLabels).map(key => ({ text: statusLabels[key], value: key })),
          // onFilter: (value, record) => record.visit_status.status === value,
          // filterMultiple: false, // Убираем кнопку "Очистить"
        },
        {
          title: "Манзил",
          dataIndex: "home_address",
          key: "home_address",
        },
        {
          title: "Телефон",
          dataIndex: "phone",
          key: "phone",
        },
        {
          title: "Харакат",
          key: "action",
          render: (text, record) => (

//           <span className="flex items-center gap-3">
//   <Tooltip title="Ўзгартириш">
//      <button onClick={() => handleOpenUpdateDialog(record)} className="uk-button uk-button-default"><FaUserEdit
//          size="25"/></button>
//
//
//   </Tooltip>
//
//   <Tooltip title="Бемор картаси">
//     <Link to={`/patient/${record.id}`}>
//       <Button
//           size="large"
//           type="primary"
//           icon={<FaStreetView size="25"/>}
//           onClick={() => console.log(record.id)}
//           className="bg-gradient-to-r from-green-500 to-teal-500 text-white border-none hover:bg-gradient-to-r hover:from-green-600 hover:to-teal-600 shadow-lg transition duration-300 ease-in-out"
//       />
//     </Link>
//   </Tooltip>
//
//   <Popconfirm
//       title="Ёзувни ўчириш"
//       onConfirm={() => handleRemovePatient(record.id)}
//       icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
//       okText="Ўчириш"
//       cancelText="Бекор қилиш"
//   >
//     <Button
//         size="large"
//         type="primary"
//         icon={<TrashIcon className="h-4 w-4"/>}
//         className="bg-gradient-to-r from-red-500 to-pink-500 text-white hover:bg-gradient-to-r border-none hover:from-red-600 hover:to-pink-600 shadow-lg transition duration-300 ease-in-out"
//     />
//   </Popconfirm>
// </span>

    <span className="flex items-center gap-1">

          <Tooltip color="#00AA81" title="Ўзгартириш" placement="top">
                                   <Button
                                       size="middle"
                                       style={{
                                         backgroundColor: '#00AA81', // Orange
                                         borderColor: '#00AA81',
                                         borderRadius: '4px',
                                         color: '#FFFFFF',
                                         boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                       }}
                                       icon={<FaUserEdit size="20" style={{color: '#fff'}}/>} // White icon
                                       onClick={() => handleOpenUpdateDialog(record)}
                                       onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#00AA81'} // Darker orange on hover
                                       onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#00AA81'}
                                   />
                                </Tooltip>


          {/*<Tooltip title="Бемор картаси">*/}
          {/*  <Link to={`/patient/${record.id}`}>*/}
          {/*  <Button*/}
          {/*      type="primary"*/}
          {/*      icon={<IoIdCard  size="20"/>}*/}
          {/*      onClick={() => console.log(record.id)}*/}
          {/*  />*/}
          {/*    </Link>*/}
          {/*</Tooltip>*/}
          {/*<Popconfirm*/}
          {/*    title="Ёзувни ўчириш"*/}
          {/*    onConfirm={() => handleRemovePatient(record.id)}*/}
          {/*    icon={<QuestionCircleOutlined style={{color: 'red'}}/>}*/}
          {/*    okText="Ўчириш"*/}
          {/*    cancelText="Бекор қилиш"*/}
          {/*>*/}
          {/*  <Button  icon={<TrashIcon className="h-5 w-5"/>}/>*/}
          {/*</Popconfirm>*/}
        </span>
),
},
]
  ;

  return (
      <>
        <div className="px-10">
          {/*<Typography.Title level={3}>Барча беморлар</Typography.Title>*/}
          <div className="gap-5 flex items-center justify-between">
            <div  className="gap-5 flex items-center ">
              <div className="p-3 bg-gray-100 rounded-md">
                <UserGroupIcon color="#00AA81" className="w-7 h-7"/>
              </div>
              <div>
                <h1 className="text-xl font-semibold mb-2">Барча беморлар</h1>
                <div className="w-full flex items-center justify-between" style={{marginBottom: 16}}>
                  <Input
                      prefix={<CgNametag size="20"/>}
                      size="middle"
                      placeholder="Беморни Қидириш"
                      onChange={handleSearchChange}
                      className="ant-input rounded-md"
                      style={{width: 300}}
                  />
                </div>
              </div>
            </div>
            <Button icon={<ImUserPlus size="20"/>} className="text-sm flex items-center shadow-none rounded h-9 gap-1" size="middle"
                    type="primary" onClick={() => setIsModalVisible(true)}>
              Янги Бемор Қўшиш
            </Button>
          </div>
        </div>


        <Spin spinning={isLoading}>
          <Table
              rowClassName="even:bg-blue-gray-50/35"
              size="small"
              dataSource={patients}
              columns={columns}
              rowHoverable
              rowKey="id"
              pagination={{
                ...pagination
              }}
              onChange={handleTableChange}
          />
        </Spin>

        <PatientFormModal
            visible={isModalVisible}
            onClose={handleClose}
            onSubmit={handleSubmit}
            loading={isLoading}
            initialValues={currentPatient}
        />
      </>
  );
};

export default Patients;
