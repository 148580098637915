import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Select, DatePicker, Spin, message, Cascader } from 'antd';
import axiosInstance from "../axios/axiosInstance";
import dayjs from "dayjs";

const { Option } = Select;

const StationaryFormModal = ({ visible, onCancel, onSubmit, initialData }) => {
    const [form] = Form.useForm();
    const [doctors, setDoctors] = useState([]);
    const [patients, setPatients] = useState([]);
    const [apartments, setApartments] = useState([]);
    const [beds, setBeds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedApartmentId, setSelectedApartmentId] = useState(null);
    const [primaryServices, setPrimaryServices] = useState([]);
    const [selectedService, setSelectedService] = useState(null);

    useEffect(() => {
        const fetchDoctors = async () => {
            setIsLoading(true);
            try {
                const response = await axiosInstance.get('/admin/users');
                const doctorUsers = response.data.data.filter(user => user.roles.includes('doctor'));
                setDoctors(doctorUsers);
            } catch (error) {
                console.error("Докторларни юклашда хатолик:", error);
                message.error('Докторларни юклашда хатолик');
            } finally {
                setIsLoading(false);
            }
        };

        const fetchPatients = async () => {
            setIsLoading(true);
            try {
                const response = await axiosInstance.get('/patients');
                setPatients(response.data.data);
            } catch (error) {
                console.error("Пациентларни юклашда хатолик:", error);
                message.error('Пациентларни юклашда хатолик');
            } finally {
                setIsLoading(false);
            }
        };

        const fetchApartments = async () => {
            setIsLoading(true);
            try {
                const response = await axiosInstance.get('/admin/apartment');
                setApartments(response.data.data);
            } catch (error) {
                console.error("Апартаментларни юклашда хатолик:", error);
                message.error('Апартаментларни юклашда хатолик');
            } finally {
                setIsLoading(false);
            }
        };

        const fetchServices = async () => {
            try {
                const response = await axiosInstance.get("/admin/service?primary=1&type=stationary");
                const services = response.data.data; // Service data from API
                setPrimaryServices(services); // Set services to state
            } catch (error) {
                console.error("Ошибка при получении списка услуг:", error);
                message.error('Ошибка при получении списка услуг');
            }
        };

        fetchServices();
        fetchDoctors();
        fetchPatients();
        fetchApartments();
    }, []);

    const handleServiceSelect = (selectedOption) => {
        setSelectedService(selectedOption);
    };

    useEffect(() => {
        if (initialData) {
            form.setFieldsValue({
                patient_id: initialData.patient_id,
                doctor_id: initialData.doctor.id,
                service_id: initialData.global_service.id,
                bed_id: initialData.bed_id ? [initialData.bed_id.apartment, initialData.bed_id.id] : undefined,
                type: initialData.type,
                start_at: initialData.start_at ? dayjs(initialData.start_at * 1000) : null,
                end_at: initialData.end_at ? dayjs(initialData.end_at * 1000) : null,
            });
        }
    }, [initialData, form]);

    const handleSubmit = async (values) => {
        setIsLoading(true);
        try {
            const payload = {
                ...values,
                bed_id: values.bed_id[1],
                apartment_id: values.bed_id[0],
                service_id: selectedService,
                start_at: dayjs(values.start_at).format('YYYY-MM-DD HH:mm:ss'),
                end_at: values.end_at ? dayjs(values.end_at).format('YYYY-MM-DD HH:mm:ss') : null,
            };

            if (initialData) {
                await axiosInstance.put(`/stationary/${initialData.id}`, payload);
            } else {
                await axiosInstance.post('/stationary', payload);
            }
            message.success('Данные успешно сохранены!');
            onSubmit();
        } catch (error) {
            console.error("Ошибка при сохранении данных:", error);
            message.error('Ошибка при сохранении данных');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        onCancel();
    };

    return (
        <Modal
            visible={visible}
            title={initialData ? "Таҳрирлаш" : "Янги стационар"}
            onCancel={handleCancel}
            footer={null}
        >
            {isLoading ? (
                <Spin />
            ) : (
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    initialValues={{
                        start_at: null,
                        doctor_id: null,
                        patient_id: null,
                        bed_id: null,
                    }}
                >
                    <Form.Item
                        name="patient_id"
                        label="Бемор"
                        rules={[{ required: true, message: 'Илтимос, беморни танланг' }]}
                    >
                        <Select placeholder="Беморни танланг">
                            {patients.map(patient => (
                                <Option key={patient.id} value={patient.id}>
                                    {patient.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="doctor_id"
                        label="Доктор"
                        rules={[{ required: true, message: 'Илтимос, докторани танланг' }]}
                    >
                        <Select placeholder="Докторни танланг">
                            {doctors.map(doctor => (
                                <Option key={doctor.id} value={doctor.id}>
                                    {doctor.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="service_id"
                        label="Хизмат тури"
                        rules={[{ required: true, message: 'Илтимос, хизмат турини танланг' }]}
                    >
                        <Select
                            placeholder="Хизмат турини танланг"
                            onChange={handleServiceSelect}
                        >
                            {primaryServices.map(service => (
                                <Option key={service.id} value={service.id}>
                                    {service.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="bed_id"
                        label="Палата / Ётоқ"
                        rules={[{ required: true, message: 'Илтимос, креватни танланг' }]}
                    >
                        <Cascader
                            options={apartments.map(apartment => ({
                                value: apartment.id,
                                label: `${apartment.floor} Палата - ${apartment.type}`,
                                children: apartment.beds.map(bed => ({
                                    value: bed.id,
                                    label: `Ётоқ ${bed.number} - ${bed.price} сўм`,
                                    disabled: bed.status === 'busy',
                                })),
                            }))}
                            value={initialData && initialData.bed_id ? [initialData.bed_id.apartment, initialData.bed_id.id] : undefined} // Убедитесь, что это правильно
                            onChange={(value) => {
                                console.log(value); // Для отладки
                                form.setFieldsValue({ bed_id: value });
                            }}
                            placeholder="Ётоқни танланг"
                        />
                    </Form.Item>


                    <Form.Item
                        className="w-full"
                        name="start_at"
                        label="Бошланиш санаси"
                        rules={[{ required: true, message: 'Илтимос, бошланиш санасини киритинг' }]}
                    >
                        <DatePicker className="w-full" showTime format="YYYY-MM-DD HH:mm:ss" />
                    </Form.Item>

                    <Form.Item
                        name="end_at"
                        className="w-full"
                        label="Тугатган санаси"
                        rules={[{ required: false, message: 'Илтимос, туган санасини киритинг' }]}
                    >
                        <DatePicker className="w-full" showTime format="YYYY-MM-DD HH:mm:ss" />
                    </Form.Item>


                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            {initialData ? 'Таҳрирлаш' : 'Янги'}
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </Modal>
    );
};

export default StationaryFormModal;
