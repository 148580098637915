import React, { useEffect, useState } from 'react';
import { Dialog, IconButton, Typography } from "@material-tailwind/react";
import { ClipboardDocumentCheckIcon } from "@heroicons/react/24/solid";
import Select from "react-select";
import axiosInstance from "../axios/axiosInstance";
import toast from "react-hot-toast";
import { Button } from 'antd';

const CreateVisit = ({ patientId, mostRecentVisit, onUpdateVisits }) => {
    const [openVisit, setOpenVisit] = useState(false);
    const [primaryServices, setPrimaryServices] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [doctorId, setDoctorId] = useState('');
    const [doctors, setDoctors] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState('');
    const [discounts, setDiscounts] = useState([]);
    const [selectedDiscount, setSelectedDiscount] = useState(null);

    const openDrawer = () => setOpenVisit((cur) => !cur);
    const closeDrawer = () => setOpenVisit(false);

    useEffect(() => {
        fetchDoctors();
        fetchServices();
        fetchDiscounts();
    }, []);

    // Fetch primary services
    const fetchServices = async () => {
        try {
            const response = await axiosInstance.get("/admin/service?primary=1");
            setPrimaryServices(response.data.data);
        } catch (error) {
            console.error("Ошибка при получении списка услуг:", error);
        }
    };

    // Fetch doctors
    const fetchDoctors = async () => {
        try {
            const response = await axiosInstance.get("/admin/users");
            const doctorUsers = response.data.data.filter(user => user.roles.includes('doctor'));
            setDoctors(doctorUsers);
        } catch (error) {
            console.error("Ошибка при получении списка врачей:", error);
        }
    };

    // Fetch discounts
    const fetchDiscounts = async () => {
        try {
            const response = await axiosInstance.get("/discount");
            setDiscounts(response.data.data);
        } catch (error) {
            console.error("Ошибка при получении списка скидок:", error);
        }
    };

    // Handle form submission for a new visit
    const handleNewVisitSubmit = async () => {
        const queryParams = [
            `patient_id=${patientId}`,
            `doctor_id=${selectedDoctor.value}`,
            `service_id=${selectedService.value}`
        ];
        if (selectedDiscount) {
            queryParams.push(`discount_id=${selectedDiscount.value}`);
        }

        try {
            const response = await axiosInstance.post(`/visit?${queryParams.join('&')}`);
            setOpenVisit(false);
            toast.success('Қабул қушилди!');
            onUpdateVisits(response.data);
        } catch (error) {
            console.error("Error creating new visit:", error);
        }
    };

    const isButtonDisabled = mostRecentVisit ? ["examined", "new"].includes(mostRecentVisit.status) : false;

    return (
        <div>
            <Button disabled={isButtonDisabled} icon={<ClipboardDocumentCheckIcon className='w-4 h-4' />} fullWidth onClick={openDrawer} type="dashed" size="middle" className='capitalize w-full my-2'>
                қабулга қўшиш
            </Button>
            <Dialog open={openVisit} handler={openDrawer} className="p-4 z-[999999]">
                <div className="mb-6 flex items-center justify-between">
                    <Typography variant="h5" color="blue-gray" className="capitalize">
                        қабулга қўшиш
                    </Typography>
                    <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="h-5 w-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </IconButton>
                </div>

                {/* Service selection */}
                <Select
                    options={primaryServices.map(service => ({ value: service.id, label: service.name }))}
                    value={selectedService}
                    onChange={setSelectedService}
                    placeholder="Выберите услугу..."
                    className="mb-3"
                />

                {/* Doctor selection */}
                {doctors.length > 0 ? (
                    <Select
                        options={doctors.map(doctor => ({ value: doctor.id, label: doctor.name }))}
                        value={selectedDoctor}
                        onChange={setSelectedDoctor}
                        placeholder="Доктор"
                        className="mb-3"
                    />
                ) : (
                    <p>Нет доступных докторов</p>
                )}

                {/* Discount selection */}
                <Select
                    options={discounts.map(discount => ({ value: discount.id, label: `${discount.title} - ${discount.percent}%` }))}
                    value={selectedDiscount}
                    onChange={setSelectedDiscount}
                    placeholder="Выберите скидку..."
                    className="mb-3"
                />

                <div className="px-4 py-3 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-0">
                    <Button onClick={handleNewVisitSubmit}>Янги қабул қушиш</Button>
                </div>
            </Dialog>
        </div>
    );
};

export default CreateVisit;
