import React, { useEffect, useState } from 'react';
import { Table, List, Radio, Button, Select, message, Tag } from 'antd';
import axiosInstance from "../../axios/axiosInstance";

const VisitTable = () => {
    const [visitData, setVisitData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [patients, setPatients] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [selectedDoctor, setSelectedDoctor] = useState(null);

    // Fetch visit data
    const fetchVisitData = async () => {
        try {
            const response = await axiosInstance.get('visit', {
                params: {
                    'status[0]': 'new',
                    'status[1]': 'queue',
                    'status[2]': 'examined',
                },
            });
            setVisitData(response.data.data);
        } catch (error) {
            console.error('Error fetching visit data:', error);
            message.error('Failed to load visit data. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    // Fetch patients with visit status
    const fetchPatients = async () => {
        try {
            const response = await axiosInstance.get('/patients');
            const patientsWithVisitStatus = response.data.data.map(patient => ({
                ...patient,
                isExamined: patient.visit_status?.status === 'examined',
            }));
            setPatients(patientsWithVisitStatus);
        } catch (error) {
            console.error("Error fetching patients:", error);
            message.error('Failed to load patients. Please try again.');
        }
    };

    // Fetch doctors from users with doctor role
    const fetchDoctors = async () => {
        try {
            const response = await axiosInstance.get('/admin/users');
            const doctorUsers = response.data.data.filter(user => user.roles.includes('doctor'));
            setDoctors(doctorUsers);
        } catch (error) {
            console.error("Error fetching doctors:", error);
            message.error('Failed to load doctors. Please try again.');
        }
    };

    useEffect(() => {
        fetchVisitData();
        fetchPatients();
        fetchDoctors();
    }, []);

    // Handle patient selection
    const handleSelectPatient = (patientId) => {
        setSelectedPatient(patientId);
    };

    // Handle doctor selection
    const handleSelectDoctor = (doctorId) => {
        setSelectedDoctor(doctorId);
    };

    // Handle visit creation
    const handleCreateVisit = () => {
        if (!selectedPatient || !selectedDoctor) {
            message.warning('Please select both a patient and a doctor.');
            return;
        }
        message.success(`Visit created for patient ID: ${selectedPatient} with doctor ID: ${selectedDoctor}`);
        setSelectedPatient(null);
        setSelectedDoctor(null);
    };

    const statusTranslations = {
        new: 'Янги',
        queue: 'Навбатда',
        examined: 'Қабулда',
    };

    // Define table columns
    const columns = [
        { title: 'ID', dataIndex: 'id', key: 'id' },
        { title: 'Patient Name', dataIndex: ['patient_id', 'name'], key: 'patient_id.name' },
        { title: 'Doctor Name', dataIndex: ['doctor', 'name'], key: 'doctor.name' },
        {
            title: 'Date',
            dataIndex: 'date_at',
            key: 'date_at',
            render: (text) => {
                const date = new Date(text);
                return !isNaN(date)
                    ? new Intl.DateTimeFormat('ru-RU', { dateStyle: 'medium' }).format(date)
                    : 'Sana yo\'q';
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => statusTranslations[status] || status,
        },
        {
            title: 'Visit Time',
            dataIndex: 'visit_at',
            key: 'visit_at',
            render: (text) => {
                const time = new Date(text);
                return !isNaN(time)
                    ? new Intl.DateTimeFormat('uz-UZ', { timeStyle: 'short' }).format(time)
                    : 'Қабул бошланмаган';
            },
        },
    ];

    return (
        <div className="flex">
            {/* Sidebar for patient and doctor selection */}
            <div className="w-1/4 p-4 border-r border-gray-200">
                <h3 className="text-lg font-semibold mb-4">Select Patient & Doctor</h3>

                {/* Patient selection */}
                <Radio.Group
                    onChange={(e) => handleSelectPatient(e.target.value)}
                    value={selectedPatient}
                    className="w-full mb-4"
                >
                    <List
                        dataSource={patients}
                        renderItem={(patient) => (
                            <List.Item className="flex items-center">
                                <Radio value={patient.id} disabled={patient.isExamined}>
                                    {patient.name || 'Unknown Name'}
                                </Radio>
                            </List.Item>
                        )}
                        bordered
                        className="max-h-96 overflow-y-auto"
                    />
                </Radio.Group>

                {/* Doctor selection with "buy" tag */}
                <Select
                    value={selectedDoctor}
                    onChange={handleSelectDoctor}
                    placeholder="Select Doctor"
                    className="w-full mb-4"
                >
                    {doctors.map((doctor) => {
                        const isDoctorInVisit = visitData.some(visit => visit.doctor && visit.doctor.id === doctor.id);
                        return (
                            <Select.Option key={doctor.id} value={doctor.id}>
                                {doctor.name || 'Unknown Doctor'}
                                {isDoctorInVisit && <Tag color="green" className="ml-2">buy</Tag>}
                            </Select.Option>
                        );
                    })}
                </Select>

                {/* Confirm visit button */}
                <Button
                    type="primary"
                    onClick={handleCreateVisit}
                    block
                    className="bg-blue-500 hover:bg-blue-600 mt-4"
                >
                    Confirm Visit
                </Button>
            </div>

            {/* Main table content */}
            <div className="w-3/4 p-4">
                <Table
                    columns={columns}
                    dataSource={visitData}
                    loading={loading}
                    rowKey="id"
                    pagination={{ pageSize: 10 }}
                />
            </div>
        </div>
    );
};

export default VisitTable;
