import React, { useState, useEffect } from 'react';
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Card, CardHeader, Typography, CardBody, CardFooter } from "@material-tailwind/react";
import { Button, Pagination, Input, Table, Spin } from "antd";
import Mkb10List from './components/Mkb10List';
import axiosInstance from "../../axios/axiosInstance";

const TABLE_HEAD = ["Код", "Номланиши", "Кушимча малумот"];

export default function Mkb10() {
    const [searchQuery, setSearchQuery] = useState('');
    const [records, setRecords] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        fetchRecords();
    }, []);

    const fetchRecords = async (page = 1) => {
        try {
            const response = await axiosInstance.get(`/mkb10?page=${page}`);
            setRecords(response.data.data);
            setCurrentPage(response.data.meta.current_page);
            setTotalPages(response.data.meta.last_page);
        } catch (error) {
            console.error("Ошибка при получении списка МКБ10:", error);
        }
    };

    const handleSearch = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get(`/mkb10?search=${searchQuery}`);
            setRecords(response.data.data);
            setCurrentPage(1); // Reset to first page after search
            setTotalPages(response.data.meta.last_page);
        } catch (error) {
            console.error("МКБ10 номларини қидиришда хатолик:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageChange = (page) => {
        fetchRecords(page);
    };

    const columns = [
        {
            title: TABLE_HEAD[0], // Код
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: TABLE_HEAD[1], // Номланиши
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: TABLE_HEAD[2], // Кушимча малумот
            dataIndex: 'additional_info',
            key: 'additional_info',
            render: (text) => text ? `${text.split(' ').slice(0, 7).join(' ')} ...` : '',
        },
    ];

    return (
        <Card className="h-full w-full rounded-none pt-5">
            <Typography className="mx-8 mb-2" variant="h5" color="black">МКБ10</Typography>
            <div className="flex mx-8 justify-between gap-8">
                {/*<Mkb10List />*/}
                <Input
                    placeholder="Қидириш"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="flex-1"
                />
                <Button type="primary" onClick={handleSearch} loading={isLoading}>
                    <MagnifyingGlassIcon className="h-5 w-5" />
                </Button>
            </div>
            <CardHeader floated={false} shadow={false} className="rounded-none" />
            <CardBody className="overflow-scroll px-0">
                {isLoading ? (
                    <Spin />
                ) : (
                    <Table
                        dataSource={records}
                        columns={columns}
                        pagination={false}
                        rowKey="id" // Ensure your records have a unique key
                    />
                )}
            </CardBody>
            <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                <Typography variant="small" color="blue-gray" className="font-normal">
                    Сахифа {currentPage}/{totalPages}
                </Typography>
                <Pagination
                    current={currentPage}
                    total={totalPages * 10} // Assuming 10 items per page
                    onChange={handlePageChange}
                    pageSize={10} // Assuming 10 items per page
                />
            </CardFooter>
        </Card>
    );
}
