import React, { useState } from "react";
import { DatePicker as AntdDatePicker } from "antd";
import moment from "moment"; // Import moment
import "antd/dist/reset.css"; // Import Ant Design styles

export default function DatePicker({ value, onChange }) {
    const [date, setDate] = useState(value ? moment(value) : null); // Initialize with moment

    const handleDateChange = (dateMoment) => {
        setDate(dateMoment);
        onChange(dateMoment ? dateMoment.format("YYYY-MM-DD") : ""); // Format date to string
    };

    return (
        <AntdDatePicker
            value={date}
            onChange={handleDateChange}
            placeholder="Сана"
            style={{ width: "100%" }}
        />
    );
}
