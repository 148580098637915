import React, {useEffect, useState} from 'react';
import {Button, Input, message, Modal, Select, Table, Tag, Tooltip} from "antd";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import axiosInstance from "../../axios/axiosInstance";

function StoreRequest(props) {

   const [requests, setRequests] = useState([])
   const [selectedRequest, setSelectedRequest] = useState(null);
   const [isModalVisible, setIsModalVisible] = useState(false);
   const [actionType, setActionType] = useState(null); // To distinguish between accept and cancel
   const [description, setDescription] = useState(''); // For storing the description input
   const [isDescriptionModalVisibleForAddComment, setIsDescriptionModalVisibleForAddComment] = useState(false);
   const [isDescriptionModalVisible, setIsDescriptionModalVisible] = useState(false);
   const [fullDescription, setFullDescription] = useState('');

   const openDescriptionModal = (desc) => {
      setFullDescription(desc);
      setIsDescriptionModalVisible(true);
   };
   const openModal = (request) => {
      setSelectedRequest(request);
      setIsModalVisible(true);
   };

   const closeModal = () => {
      setIsModalVisible(false);
      setSelectedRequest(null);
   };

   const getRequests = async () => {
      try {
         const response = await axiosInstance.get('/financiers/application/paginate');
         setRequests(response.data.data);
      } catch (error) {
         console.error('Error fetching requests:', error);
         message.error('Error fetching data');
      }
   }

   useEffect(() => {
      getRequests();
   }, []);

   const renderStatusTag = (status) => {
      if (!status) {
         return <Tag color="default">Ноаниқ</Tag>;
      }
      switch (status) {
         case 'process':
            return <Tag color="blue">Жараёнда</Tag>;
         case 'accept':
            return <Tag color="#00AA81">Қабул қилинди</Tag>;
         case 'cancel':
            return <Tag color="red">Рад этилди</Tag>;
         default:
            return <Tag color="default">Ноаниқ</Tag>;
      }
   };

   // Handle action button clicks (accept, cancel)
   const handleActionClick = (applicationId, warehouseId, type) => {
      setSelectedRequest({applicationId, warehouseId});
      setActionType(type);
      setIsDescriptionModalVisibleForAddComment(true); // Open the description modal
   };

   // Handle the final action with description validation
   const handleFinalAction = async () => {
      if (description.length < 10) {
         message.error("Изох камида 10 та белгидан иборат бўлиши керак!");
         return;
      }

      const payload = {
         warehouse_id: selectedRequest.warehouseId,
         application_id: selectedRequest.applicationId,
         status: actionType,
         comment: description
      };

      try {
         const response = await axiosInstance.post('/financiers/check/application', payload);
         if (response.data.status) {
            message.success(response.data.message);
            setIsDescriptionModalVisibleForAddComment(false);
            setDescription(''); // Clear the description input
         } else {
            message.error(response.data.message);
         }
      } catch (error) {
         console.error(`Error setting request to ${actionType}:`, error);
         message.error(`Error setting request to ${actionType}`);
      }

      getRequests(); // Refresh the table after action
   };

   const columns = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id',
      },
      {
         title: 'Миқдор',
         dataIndex: 'qty',
         key: 'qty',
      },
      {
         title: 'Сана',
         dataIndex: 'created_at',
         key: 'created_at',
      },
      {
         title: 'Юборувчи',
         dataIndex: 'sender',
         key: 'sender',
         render: (sender) => (
             <Button style={{color:'#00AA81'}} type="link" onClick={() => openModal(sender)}>
                {sender.name}
             </Button>
         )
      },
      {
         title: 'Склат Маҳсулот',
         dataIndex: 'warehouse',
         key: 'warehouse',
         render: (warehouse) => (
             <Button style={{color:'#00AA81'}} type="link" onClick={() => openModal(warehouse)}>
                {warehouse.name}
             </Button>
         )
      },
      {
         title: 'Тасдиқловчи',
         dataIndex: 'receive',
         key: 'receive',
         render: (receive) => (
             receive ? (
                 <Button style={{color:'#00AA81'}} type="link" onClick={() => openModal(receive)}>
                    {receive.name}
                 </Button>
             ) : (
                 <span style={{color: 'red'}}>Тасдиқланмаган</span>
             )
         )
      },
      {
         title: 'Холати',
         dataIndex: 'status',
         key: 'status',
         render: (status) => renderStatusTag(status)
      },
      {
         title: 'Изоҳлар', // New column for comments
         dataIndex: 'warehouse',
         key: 'comments',
         render: (warehouse, record) => {
            // Assuming that the comment is within `warehouse.histories`
            const history = warehouse.histories.find((hist) => hist.id === record.id);
            const comment = history?.history?.comment;

            return comment ? (
                comment.length > 10 ? (
                    <Button style={{color:'#00AA81'}} type="link" onClick={() => openDescriptionModal(comment)}>
                       {comment.substring(0, 10)}...
                    </Button>
                ) : (
                    <span>{comment}</span>
                )
            ) : (
                <span>Изох йўқ</span>
            );
         },
      },
      {
         title: 'Ҳаракатлар',
         key: 'actions',
         render: (_, record) => (
             <div>
                <Button

                    type="primary"
                    style={{marginRight: 8}}
                    onClick={() => handleActionClick(record.id, record.warehouse.id, 'accept')}
                    disabled={record.status === 'accept' || record.status === 'cancel'}
                >
                   Қабул қилиш
                </Button>
                <Button
                    type='default'
                    onClick={() => handleActionClick(record.id, record.warehouse.id, 'cancel')}
                    disabled={record.status === 'accept' || record.status === 'cancel'}
                >
                   Рад этиш
                </Button>
             </div>
         )
      },
   ];

   return (
       <div>
          <Table columns={columns} dataSource={requests} rowKey="id" pagination={{pageSize: 10}}/>

          {/* Modal for showing details */}
          <Modal
              title="Details"
              open={isModalVisible}
              onCancel={closeModal}
              footer={null}
          >
             {selectedRequest && (
                 <div>
                    <p><strong>Номи:</strong> {selectedRequest?.name}</p>
                    {
                        selectedRequest?.phone && (<p><strong>Рақами:</strong> {selectedRequest?.phone}</p>)
                    }
                    {selectedRequest?.category && (
                        <>
                           <p><strong>Категория:</strong> {selectedRequest?.category.name}</p>
                           {/*<p><strong>Миқдор:</strong> {selectedRequest?.qty}</p>*/}
                           {/*<p><strong>Нархи:</strong> {selectedRequest?.price}</p>*/}
                        </>
                    )}
                 </div>
             )}
          </Modal>

          {/* Modal for entering description */}
          <Modal
              title={`Изох ${actionType === 'accept' ? 'қабул қилиш' : 'рад этиш'} учун`}
              open={isDescriptionModalVisibleForAddComment}
              onCancel={() => setIsDescriptionModalVisibleForAddComment(false)}
              onOk={handleFinalAction}
              okText={actionType === 'accept' ? 'Қабул қилиш' : 'Рад этиш'}
          >
             <Input.TextArea
                 placeholder="Изох киритинг..."
                 value={description}
                 onChange={(e) => setDescription(e.target.value)}
                 rows={4}
             />
          </Modal>

          <Modal
              title="Тўлиқ Изох"
              open={isDescriptionModalVisible}
              onCancel={() => setIsDescriptionModalVisible(false)}
              footer={null}
          >
             <p>{fullDescription}</p>
          </Modal>
       </div>
   );
}

export default StoreRequest;