import React, { useEffect, useState } from 'react';
import {Table, Tag} from 'antd';
import axiosInstance from "../axios/axiosInstance";
import {Link, useNavigate} from "react-router-dom";

const DoctorVisits = () => {
    const [visits, setVisits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [doctors, setDoctors] = useState([]);
    const [patients, setPatients] = useState([]);
    const navigate = useNavigate();

    const statusLabels = {
        queue: 'Навбатда...',
        examined: 'Қабулда...',
        new: 'Янги кабул',
        pending: 'Тўлов кутилмоқда',
        payed: 'Тўланган',
        revisit: 'Навбатда...',
        closed: 'Йопилган',
        null: 'Фаол емас',
    };

    const onRowClick = (record) => {
        navigate(`/patient/${record.patient_id}`);
    };


    const statusColors = {
        queue: '#F8D347',  // Muted yellow
        examined: '#73C76A',  // Flat green
        new: '#58AFFF',  // Flat sky blue
        pending: '#FFA866',  // Soft coral orange
        closed: '#FF5C5C',  // Flat red
        payed: '#9A77FF',  // Soft violet
        revisit: '#F8D347',  // Muted yellow
        null: '#bdbdbd',  // Soft grey
    };

    const iconBackgrounds = {
        queue: 'bg-yellow-50',     // Very light yellow
        examined: 'bg-green-50',   // Very light green
        new: 'bg-blue-50',         // Very light blue
        pending: 'bg-orange-50',   // Very light coral
        closed: 'bg-red-50',       // Very light red
        payed: 'bg-purple-50',     // Very light violet
        revisit: 'bg-yellow-50',   // Very light yellow
        null: 'bg-gray-200',       // Very light grey
    };

    const iconTextColors = {
        queue: 'text-yellow-600',   // Muted yellow text
        examined: 'text-green-600', // Flat green text
        new: 'text-blue-600',       // Sky blue text
        pending: 'text-orange-600', // Coral orange text
        closed: 'text-red-600',     // Flat red text
        payed: 'text-purple-600',   // Soft violet text
        revisit: 'text-yellow-600', // Same muted yellow
        null: 'text-gray-500',      // Neutral grey text
    };

    const iconStrokeColors = {
        queue: '#F5C201',       // Flat gold for queue
        examined: '#47A047',    // Flat green for examined
        new: '#268BE4',         // Flat blue for new
        pending: '#E89442',     // Flat coral orange for pending
        closed: '#E05353',      // Flat red for closed
        payed: '#7A5AEF',       // Flat violet for payed
        revisit: '#F5C201',     // Same flat gold for revisit
        null: '#A0A0A0',        // Flat grey for default
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch the visits data
                const visitResponse = await axiosInstance.get('/doctor/visit');
                let  visitsData = visitResponse.data.data;

                // Filter visits to only include those with status 'queue' or 'examined'
                visitsData = visitsData.filter(visit => visit.status === 'queue' || visit.status === 'examined');


                // Fetch the doctors data
                const doctorResponse = await axiosInstance.get("/admin/users");
                const doctorUsers = doctorResponse.data.data.filter(user => user.roles.includes('doctor'));
                setDoctors(doctorUsers);

                // Fetch the patients data
                const patientResponse = await axiosInstance.get("/patients");
                const patientsData = patientResponse.data.data;
                setPatients(patientsData);

                // Map visits data to include doctor and patient names
                const mappedVisits = visitsData.map(visit => {
                    const doctor = doctorUsers.find(doc => doc.id === visit.doctor_id);
                    const patient = patientsData.find(pat => pat.id === visit.patient_id);
                    return {
                        ...visit,
                        doctor_name: doctor ? doctor.name : 'Unknown Doctor',
                        patient_name: patient ? patient.name : 'Unknown Patient', // Map patient name
                    };
                });

                setVisits(mappedVisits);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    const columns = [
        {
            title: '№',
            key: 'id',
            render: (text, record, index) => index + 1, // Render index + 1 as the ID
        },
        {
            title: 'Бемор Номи',
            dataIndex: 'patient_name',
            key: 'patient_name',
            render: (text, record) => (
                <Link  className="block font-sans text-sm antialiased font-medium text-[#10b981]" to={`/patient/${record.patient_id}`}>{text}</Link>
            ),
        },
        {
            title: 'Врач',
            dataIndex: 'doctor_name',
            key: 'doctor_name',
        },
        {
            title: 'Сана',
            dataIndex: 'date_at',
            key: 'date_at',
        },
        {
            title: 'Холати',
            dataIndex: 'status',
            key: 'status',
            render: (status) => (
                <Tag bordered={false} className="px-5 py-1 flex max-w-max items-center" color={statusColors[status]}>
                    {statusLabels[status]}
                </Tag>
            )
        },
    ];

    return (
        <div className="p-5">
            <h1 className="text-xl">Врач ташрифлари</h1>
            <Table
                rowClassName="cursor-pointer"
                onRow={(record) => ({
                    onClick: () => onRowClick(record), // Navigate on row click
                })}
                columns={columns}
                dataSource={visits}
                bordered
                loading={loading}
                rowKey="id" // Assuming each visit has a unique 'id'
                pagination={{pageSize: 10}} // Optional: Adjust pagination as needed
            />
        </div>
    );
};

export default DoctorVisits;
