import React, {useEffect, useState} from 'react';
import {Button, Menu} from 'antd';
import {BsCalendar2WeekFill} from "react-icons/bs";
import {Link} from 'react-router-dom';
import {BanknotesIcon, ClipboardDocumentCheckIcon, RectangleStackIcon, UserGroupIcon} from "@heroicons/react/24/solid";
import {BiSolidReport} from "react-icons/bi";
import {FiLogOut} from "react-icons/fi";
import {IoIosBed} from "react-icons/io";
import {FaClinicMedical, FaWarehouse} from "react-icons/fa";
import {logout} from "../services/authServices";
import {MdOutlineAddToQueue} from "react-icons/md";
import {TbDiscount} from "react-icons/tb";

export const Sidebar = ({collapsed}) => {
   const [data, setData] = useState([]);

   const items = [
      {
         key: '1',
         icon: <div className="sidebar-icon-bg p-2 rounded-lg"><BsCalendar2WeekFill className="h-5 w-5 sidebar-icon"/>
         </div>,
         label: <Link to="/">Календарь</Link>,
      },
      {
         key: 'sub1',
         label: <Link to="/patients">Барча Беморлар</Link>,
         icon: <div className="sidebar-icon-bg p-2 rounded-lg"><UserGroupIcon
             className="h-6 w-6 sidebar-icon"/></div>,
      },
      // {
      //    key: '2',
      //    label: <Link to="/queues">Навбатлар</Link>,
      //    icon: <div className="sidebar-icon-bg p-2 rounded-lg"><MdOutlineAddToQueue
      //        className="h-6 w-6 sidebar-icon"/></div>,
      // },
      {
         key: 'sub2',
         label: 'Каталог',
         icon: <div className="sidebar-icon-bg p-2 rounded-lg"><RectangleStackIcon
             className="h-6 w-6 sidebar-icon"/></div>,
         children: [
            {
               key: '9',
               label: <Link to="/catalog/doctors">Докторлар</Link>,
            },
            {
               key: '22',
               label: <Link to="/catalog/partners">Хамкорлар</Link>,
            },
            {
               key: '22',
               label: <Link className="flex items-center gap-2" to="/catalog/discount">Чегирмалар <TbDiscount size="20" /></Link>,
            },
            {
               key: '10',
               label: <Link to="/catalog/services">Хизматлар</Link>,
            },
            {
               key: '11',
               label: <Link to="/catalog/epidemiological_history">Эпидемиологик тарих</Link>,
            },
            {
               key: '12',
               label: <Link to="/catalog/international-classification-of-diseases">МКБ-10</Link>,
            },
         ],
      },
      {
         key: 'sub3',
         label: 'Тўловлар',
         icon: <div className="sidebar-icon-bg p-2 rounded-lg"><BanknotesIcon
             className="h-6 w-6 sidebar-icon"/></div>,
         children: [
            {
               key: '13',
               label: <Link to="/payments/debtors">Қарздорлар рўйхати</Link>,
            },
            {
               key: '14',
               label: <Link to="/payments/history">Тўловлар тарихи</Link>,
            },
         ],
      },
      {
         key: 'sub4',
         label: 'Қабуллар',
         icon: <div className="sidebar-icon-bg p-2 rounded-lg"><ClipboardDocumentCheckIcon
             className="h-6 w-6 sidebar-icon"/></div>,
         children: [
            {
               key: '15',
               label: <Link to="/appointments/queue">Навбатда</Link>,
            },
            {
               key: '16',
               label: <Link to="/appointments/reappointments">Қайта Навбатлар</Link>,
            },
            {
               key: '17',
               label: <Link to="/appointments/followups">Қайта Қабуллар</Link>,
            },
            {
               key: '18',
               label: <Link to="/appointments/dispensary">Диспансер Хисоби</Link>,
            },
         ],
      },
      {
         key: 'sub5',
         label: 'Хисоботлар',
         icon: <div className="sidebar-icon-bg p-2 rounded-lg"><BiSolidReport
             className="h-6 w-6 sidebar-icon"/></div>,
         children: [
            {
               key: '19',
               label: <Link to="/reports/patients">Беморлар Бўйича</Link>,
            },
            {
               key: '20',
               label: <Link to="/reports/doctors">Врачлар Бўйича</Link>,
            },
            {
               key: '21',
               label: <Link to="/reports/finance">Маблағлар бўйича хисобот</Link>,
            },
         ],
      },
      {
         key: 'sub6',
         label: 'Склат',
         icon: <div className="sidebar-icon-bg p-2 rounded-lg"><FaWarehouse
             className="h-6 w-6 sidebar-icon"/></div>,
         children: [
            {
               key: '22',
               label: <Link to="/store/category">Категория</Link>,
            },
            {
               key: '23',
               label: <Link to="/store/warehouse">Склат</Link>,
            },
            {
               key: '24',
               label: <Link to="/store/request">Заявка</Link>,
            },
         ]
      }
   ];
   const doctorItems = [
      {
         key: '1',
         icon: <div className="sidebar-icon-bg p-2 rounded-lg"><BsCalendar2WeekFill className="h-5 w-5 sidebar-icon"/>
         </div>,
         label: <Link to="/">Календарь</Link>,
      },
      {
         key: '2',
         label: <Link to="/doctor/visits">Врач Қабуллар</Link>,
         icon: <div className="sidebar-icon-bg p-2 rounded-lg"><UserGroupIcon
             className="h-6 w-6 sidebar-icon"/></div>,
      },
      // {
      //    key: 'sub1',
      //    label: 'Қабуллар',
      //    icon: <div className="sidebar-icon-bg p-2 rounded-lg"><ClipboardDocumentCheckIcon
      //        className="h-6 w-6 sidebar-icon"/></div>,
      //    children: [
      //       {
      //          key: '15',
      //          label: <Link to="/appointments/queue">Навбатда</Link>,
      //       },
      //       {
      //          key: '16',
      //          label: <Link to="/appointments/reappointments">Қайта Навбатлар</Link>,
      //       },
      //       {
      //          key: '17',
      //          label: <Link to="/appointments/followups">Қайта Қабуллар</Link>,
      //       },
      //       {
      //          key: '18',
      //          label: <Link to="/appointments/dispensary">Диспансер Хисоби</Link>,
      //       },
      //    ],
      // },
      {
         key: 'sub2',
         label: <Link to="/doctor/request">Заявка</Link>,
         icon: <div className="sidebar-icon-bg p-2 rounded-lg"><FaWarehouse
             className="h-6 w-6 sidebar-icon"/></div>,
      }
   ];
   const registerItems = [
      {
         key: '1',
         icon: <div className="sidebar-icon-bg p-2 rounded-lg"><BsCalendar2WeekFill className="h-5 w-5 sidebar-icon"/>
         </div>,
         label: <Link to="/">Календарь</Link>,
      },
      {
         key: 'sub1',
         label: <Link to="/patients">Барча Беморлар</Link>,
         icon: <div className="sidebar-icon-bg p-2 rounded-lg"><UserGroupIcon
             className="h-6 w-6 sidebar-icon"/></div>,
      },
      {
         key: 'sub3',
         label: 'Тўловлар',
         icon: <div className="sidebar-icon-bg p-2 rounded-lg"><BanknotesIcon
             className="h-6 w-6 sidebar-icon"/></div>,
         children: [
            {
               key: '13',
               label: <Link to="/payments/debtors">Қарздорлар рўйхати</Link>,
            },
            {
               key: '14',
               label: <Link to="/payments/history">Тўловлар тарихи</Link>,
            },
         ],
      },
      {
         key: 'sub4',
         label: 'Қабуллар',
         icon: <div className="sidebar-icon-bg p-2 rounded-lg"><ClipboardDocumentCheckIcon
             className="h-6 w-6 sidebar-icon"/></div>,
         children: [
            {
               key: '15',
               label: <Link to="/appointments/queue">Навбатда</Link>,
            },
            {
               key: '16',
               label: <Link to="/appointments/reappointments">Қайта Навбатлар</Link>,
            },
            {
               key: '17',
               label: <Link to="/appointments/followups">Қайта Қабуллар</Link>,
            },
            {
               key: '18',
               label: <Link to="/appointments/dispensary">Диспансер Хисоби</Link>,
            },
         ],
      },
   ];
   const financierItems = [
      {
         key: '1',
         icon: <div className="sidebar-icon-bg p-2 rounded-lg"><BsCalendar2WeekFill className="h-5 w-5 sidebar-icon"/>
         </div>,
         label: <Link to="/">Календарь</Link>,
      },
      {
         key: 'sub1',
         label: <Link to="/patients">Барча Беморлар</Link>,
         icon: <div className="sidebar-icon-bg p-2 rounded-lg"><UserGroupIcon
             className="h-6 w-6 sidebar-icon"/></div>,
      },
      {
         key: 'sub2',
         label: 'Тўловлар',
         icon: <div className="sidebar-icon-bg p-2 rounded-lg"><BanknotesIcon
             className="h-6 w-6 sidebar-icon"/></div>,
         children: [
            {
               key: '13',
               label: <Link to="/payments/debtors">Қарздорлар рўйхати</Link>,
            },
            {
               key: '14',
               label: <Link to="/payments/history">Тўловлар тарихи</Link>,
            },
         ],
      },
      {
         key: 'sub3',
         label: 'Қабуллар',
         icon: <div className="sidebar-icon-bg p-2 rounded-lg"><ClipboardDocumentCheckIcon
             className="h-6 w-6 sidebar-icon"/></div>,
         children: [
            {
               key: '15',
               label: <Link to="/appointments/queue">Навбатда</Link>,
            },
            {
               key: '16',
               label: <Link to="/appointments/reappointments">Қайта Навбатлар</Link>,
            },
            {
               key: '17',
               label: <Link to="/appointments/followups">Қайта Қабуллар</Link>,
            },
            {
               key: '18',
               label: <Link to="/appointments/dispensary">Диспансер Хисоби</Link>,
            },
         ],
      },
      {
         key: 'sub4',
         label: 'Хисоботлар',
         icon: <div className="sidebar-icon-bg p-2 rounded-lg"><BiSolidReport
             className="h-6 w-6 sidebar-icon"/></div>,
         children: [
            {
               key: '19',
               label: <Link to="/reports/patients">Беморлар Бўйича</Link>,
            },
            {
               key: '20',
               label: <Link to="/reports/doctors">Врачлар Бўйича</Link>,
            },
            {
               key: '21',
               label: <Link to="/reports/finance">Маблағлар бўйича хисобот</Link>,
            },
         ],
      },
      {
         key: 'sub5',
         label: 'Склат',
         icon: <div className="sidebar-icon-bg p-2 rounded-lg"><FaWarehouse
             className="h-6 w-6 sidebar-icon"/></div>,
         children: [
            {
               key: '22',
               label: <Link to="/store/category">Категория</Link>,
            },
            {
               key: '23',
               label: <Link to="/store/warehouse">Склат</Link>,
            },
            {
               key: '24',
               label: <Link to="/store/request">Заявка</Link>,
            },
         ]
      }
   ];

   useEffect(() => {
      const userRole = localStorage.getItem("userRole");

      // Determine which items to show based on the user's role
      if (userRole?.includes("admin")) {
         setData(items);
      } else if (userRole?.includes("financier")) {
         setData(financierItems);
      } else if (userRole?.includes("doctor")) {
         setData(doctorItems);
      } else if (userRole?.includes("reception")) {
         setData(registerItems); // Set data for the "register" role
      } else {
         setData([]); // Default empty menu for unknown roles
      }
   }, []);


   return (
       <div
           className={`sidebar sticky top-0 h-screen border-r ${
               collapsed ? "collapsed" : ""
           }`}>
          <div className="py-10 logo-container">
             <img
                 className={`logo transition-opacity duration-500 ease-in-out ${collapsed ? 'opacity-0 hidden' : 'opacity-100'}`}
                 src="/logomain.svg"
                 alt="Logo"
             />
             {/* Logo for collapsed state */}
             <img
                 className={`logo transition-opacity duration-500 ease-in-out ${collapsed ? 'opacity-100' : 'hidden  opacity-0'}`}
                 src="/logo1.svg"
                 alt="Logo Collapsed"
             />
          </div>
          <Menu

              className="h-[100vh]"
              defaultSelectedKeys={['1']}
              mode="inline"
              inlineCollapsed={collapsed}
              items={data}
          />

          <div className="p-3 w-full">
             <Button onClick={logout} className="flex justify-between  flex-row-reverse items-center"
                     icon={<FiLogOut size="20"/>}
                     type="default" block><span
                 className={`transition-opacity duration-500 ease-in-out ${collapsed ? 'opacity-0' : 'opacity-100'}`}>{collapsed ? '' : 'Тизимдан чиқиш'}</span></Button>
          </div>
       </div>
   );
};

export default Sidebar;
