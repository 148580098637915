// ProtectedRoute.jsx
import React, {useContext} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {Notfoundpage} from "../pages/Notfoundpage";

const ProtectedRoute = ({children, allowedRoles}) => {

   const userRole = localStorage.getItem('userRole');
   const userData = JSON.parse(localStorage.getItem('user'));

   const location = useLocation();

   const getRedirectPath = (roles) => {
      if (roles?.includes('admin')) {
         return '/';
      }
      if (roles?.includes('doctor')) {
         return '/doctor';
      }
      if (roles?.includes('financier')) {
         return '/financier';
      }
      if (roles?.includes('reception')) {
         return '/reception';
      }
      return '/';
   };

   // Check if user data is available
   if (userData === null) {
      return <div>Loading...</div>;
   }

   // Determine if user has access
   const hasAccess = allowedRoles.some(role => userRole.includes(role));

   // Redirect to appropriate route based on user role if accessing root
   if (location.pathname === '/') {
      const redirectPath = getRedirectPath(userRole);
      if (redirectPath !== '/') {
         return <Navigate to={redirectPath} replace/>;
      }
   }


   // Log role information for debugging
   console.log('User Role:', userRole);
   console.log('Allowed Roles:', allowedRoles);
   console.log('Has Access:', hasAccess);

   // Render children or redirect to no access page
   return hasAccess ? children : <Notfoundpage/>;
};

export default ProtectedRoute;
