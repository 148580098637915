import React, {useEffect, useState} from 'react';
import axiosInstance from "../../axios/axiosInstance";
import {Button, Form, Input, message, Modal, Select, Table, Tag} from "antd";

function DoctorRequest(props) {
   const [requests, setRequests] = useState([])
   const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
   const [warehouses, setWarehouses] = useState([]);
   const [form] = Form.useForm();

   const [selectedRequest, setSelectedRequest] = useState(null);
   const [isModalVisible, setIsModalVisible] = useState(false);
   const [isDescriptionModalVisible, setIsDescriptionModalVisible] = useState(false);
   const [fullDescription, setFullDescription] = useState('');

   const openModal = (request) => {
      setSelectedRequest(request);
      setIsModalVisible(true);
   };

   const closeModal = () => {
      setIsModalVisible(false);
      setSelectedRequest(null);
   };

   const getRequests = async () => {
      try {
         const response = await axiosInstance.get('/doctor/application/paginate');
         setRequests(response.data.data);
      } catch (error) {
         console.error('Error fetching requests:', error);
         message.error('Error fetching data');
      }
   }

   // Fetch available warehouses for the create modal
   const fetchWarehouses = async () => {
      try {
         const response = await axiosInstance.get('/warehouse/all');
         setWarehouses(response.data.data);
      } catch (error) {
         console.error('Error fetching warehouses:', error);
         message.error('Error fetching warehouses');
      }
   };

   useEffect(() => {
      getRequests();
      fetchWarehouses();
   }, []);

   const renderStatusTag = (status) => {
      console.log(status)
      if (!status) {
         return <Tag color="default">Ноаниқ</Tag>;
      }
      switch (status) {
         case 'process':
            return <Tag color="blue">Жараёнда</Tag>;
         case 'accept':
            return <Tag color="#00AA81">Қабул қилинди</Tag>;
         case 'cancel':
            return <Tag color="red">Рад этилди</Tag>;
         default:
            return <Tag color="default">Ноаниқ</Tag>;
      }
   };

   const openCreateModal = () => {
      setIsCreateModalVisible(true);
   };

   const closeCreateModal = () => {
      setIsCreateModalVisible(false);
      form.resetFields();
   };

   const openDescriptionModal = (desc) => {
      setFullDescription(desc);
      setIsDescriptionModalVisible(true);
   };

   const closeDescriptionModal = () => {
      setIsDescriptionModalVisible(false);
      setFullDescription('');
   };

   // Function to handle new request creation
   const handleCreateRequest = async (values) => {
      try {
         await axiosInstance.post('/doctor/applications', values);
         message.success('Request created successfully');
         setIsCreateModalVisible(false);
         form.resetFields();
         getRequests(); // Refresh the request list
      } catch (error) {
         console.error('Error creating request:', error);
         message.error('Error creating request');
      }
   };

   const columns = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id',
      },
      {
         title: 'Миқдор',
         dataIndex: 'qty',
         key: 'qty',
      },
      {
         title: 'Сана',
         dataIndex: 'created_at',
         key: 'created_at',
      },
      // {
      //    title: 'Юборувчи',
      //    dataIndex: 'sender',
      //    key: 'sender',
      //    render: (sender) => (
      //        <Button type="link" onClick={() => openModal(sender)}>
      //           {sender.name}
      //        </Button>
      //    )
      // },
      {
         title: 'Склат Маҳсулот',
         dataIndex: 'warehouse',
         key: 'warehouse',
         render: (warehouse) => (
             <Button style={{color:'#00AA81'}} type="link" onClick={() => openModal(warehouse)}>
                {warehouse.name}
             </Button>
         )
      },
      {
         title: 'Тасдиқловчи',
         dataIndex: 'receive',
         key: 'receive',
         render: (receive) => (
             receive ? (
                 <Button style={{color:'#00AA81'}} type="link" onClick={() => openModal(receive)}>
                    {receive.name}
                 </Button>
             ) : (
                 <span style={{color: 'red'}}>Not Received</span>
             )
         )
      },
      {
         title: 'Холати',
         dataIndex: 'status',
         key: 'status',
         render: (status) => renderStatusTag(status)
      },
      {
         title: 'Изох',
         dataIndex: 'warehouse',
         key: 'comment',
         render: (warehouse, record) => {
            const history = warehouse.histories.find((hist) => hist.id === record.id);
            const comment = history?.history?.comment;
            return comment && comment.length > 10 ? (
                <Button style={{color:'#00AA81'}} type="link" onClick={() => openDescriptionModal(comment)}>
                   {comment.substring(0, 10)}...
                </Button>
            ) : (
                <span>{comment || 'Изох йўқ'}</span>
            );
         }
      },
   ];

   return (
       <div className={'m-2'}>
          <div className="flex justify-between mb-4">
             <h1 className="text-xl font-semibold">Заявкалар</h1>
             <Button type="primary" onClick={openCreateModal}>
                Янгини яратиш
             </Button>
          </div>

          <Table columns={columns} dataSource={requests} rowKey="id" pagination={{pageSize: 10}}/>

          {/* Modal for showing details */}
          <Modal
              title="Details"
              open={isModalVisible}
              onCancel={closeModal}
              footer={null}
          >
             {selectedRequest && (
                 <div>
                    <p><strong>Номи:</strong> {selectedRequest?.name}</p>
                    {
                        selectedRequest?.phone && (<p><strong>Рақами:</strong> {selectedRequest?.phone}</p>)
                    }
                    {selectedRequest?.category && (
                        <>
                           <p><strong>Категория:</strong> {selectedRequest?.category.name}</p>
                           {/*<p><strong>Миқдор:</strong> {selectedRequest?.qty}</p>*/}
                           {/*<p><strong>Нархи:</strong> {selectedRequest?.price}</p>*/}
                        </>
                    )}
                 </div>
             )}
          </Modal>

          {/* Modal for creating a new request */}
          <Modal
              title="Янги заявка яратиш"
              open={isCreateModalVisible}
              onCancel={closeCreateModal}
              onOk={() => form.submit()} // Submit the form when the OK button is clicked
              okText="Яратиш"
          >
             <Form form={form} onFinish={handleCreateRequest}>
                <Form.Item
                    label="Склат"
                    name="warehouse_id"
                    rules={[{required: true, message: 'Илтимос, Маҳсулот танланг!'}]}
                >
                   <Select placeholder="Маҳсулот танланг">
                      {warehouses?.map(warehouse => (
                          <Select.Option key={warehouse?.id} value={warehouse?.id}>
                             {warehouse?.name}
                          </Select.Option>
                      ))}
                   </Select>
                </Form.Item>

                <Form.Item
                    label="Миқдор"
                    name="qty"
                    rules={[{required: true, message: 'Илтимос, миқдорни киритинг!'}]}
                >
                   <Input type="number" placeholder="Миқдорни киритинг"/>
                </Form.Item>
             </Form>
          </Modal>

          {/* Modal for full description */}
          <Modal
              title="Тўлиқ Изох"
              open={isDescriptionModalVisible}
              onCancel={closeDescriptionModal}
              footer={null}
          >
             <p>{fullDescription}</p>
          </Modal>
       </div>
   );
}

export default DoctorRequest;