import React, { useEffect, useState } from 'react';
import { Table, message, Button, Form, Input, DatePicker, InputNumber } from 'antd';
import axiosInstance from "../../axios/axiosInstance";
import dayjs from 'dayjs';
import {RiDiscountPercentFill} from "react-icons/ri";

const AllDiscounts = () => {
    const [discounts, setDiscounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [form] = Form.useForm();

    // Fetch discounts data
    const fetchDiscounts = async () => {
        try {
            const response = await axiosInstance.get('/discount');
            if (response.data.success) {
                setDiscounts(response.data.data);
            } else {
                message.error("Маълумотларни юклаб бўлмади.");
            }
        } catch (error) {
            console.error("Error fetching discounts:", error);
            message.error("Маълумотларни юклаб бўлмади. Илтимос, қайта уриниб кўринг.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDiscounts();
    }, []);

    // Handle form submission to create a new discount
    const handleCreateDiscount = async (values) => {
        const payload = {
            title: values.title,
            percent: values.percent,
            start_at: values.start_at ? dayjs(values.start_at).format("YYYY-MM-DD") : null,
            end_at: values.end_at ? dayjs(values.end_at).format("YYYY-MM-DD") : null,
        };

        try {
            const response = await axiosInstance.post('/admin/discount', payload);
            if (response.data.success) {
                message.success("Янги акция муваффақиятли яратилди!");
                form.resetFields(); // Clear form fields
                fetchDiscounts(); // Refresh discount list
            } else {
                message.error("Акция яратишда хатолик юз берди.");
            }
        } catch (error) {
            console.error("Error creating discount:", error);
            message.error("Акция яратишда хатолик юз берди. Илтимос, қайта текшириб қўшинг.");
        }
    };

    // Define table columns
    const columns = [
        { title: 'ID', dataIndex: 'id', key: 'id' },
        { title: 'Чегирма Номи', dataIndex: 'title', key: 'title' },
        { title: 'Чегирма (%)', dataIndex: 'percent', key: 'percent', render: (percent) => `${percent}%` },
        { title: 'Бошлаш санаси', dataIndex: 'start_at', key: 'start_at', render: (date) => date ? new Date(date).toLocaleDateString() : '-' },
        { title: 'Тугатган санаси', dataIndex: 'end_at', key: 'end_at', render: (date) => date ? new Date(date).toLocaleDateString() : '-' },
    ];

    return (
        <div className="flex">
            {/* Main content area */}
            <div className="w-3/4 p-4">
                <div className="h-10 bg-gray-50 flex font-medium gap-2 items-center p-5 mb-5">
                    <div className="p-1 rounded bg-white">
                        <RiDiscountPercentFill className="text-[#00AA81]" size="20"/>
                    </div>
                    Мавжуд Чегирмалар
                </div>
                <Table
                    size="small"
                    columns={columns}
                    dataSource={discounts}
                    loading={loading}
                    rowKey="id"
                    pagination={{ pageSize: 10 }}
                />
            </div>

            {/* Permanently visible sidebar for creating a new discount */}
            <div className="w-1/4 bg-white p-4  border">
                <h2>Янги Чегирма Қўшиш</h2>
                <Form form={form} layout="vertical" onFinish={handleCreateDiscount}>
                    <Form.Item
                        name="title"
                        label="Номи"
                        rules={[{ required: true, message: 'Илтимос, акция номини киритинг.' }]}
                    >
                        <Input placeholder="Акция номини киритинг" />
                    </Form.Item>

                    <Form.Item
                        name="percent"
                        label="Чегирма (%)"
                        rules={[{ required: true, message: 'Илтимос, чегирмани киритинг.' }]}
                    >
                        <InputNumber min={0} max={100} placeholder="%" className="w-full" />
                    </Form.Item>

                    <Form.Item name="start_at" label="Бошлаш санаси">
                        <DatePicker format="YYYY-MM-DD" className="w-full" />
                    </Form.Item>

                    <Form.Item name="end_at" label="Тугатган санаси">
                        <DatePicker format="YYYY-MM-DD" className="w-full" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="w-full">
                           Яратиш
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default AllDiscounts;
