import React, { useState, useEffect } from 'react';
import {Table, Button, Input, Radio, Spin, Divider, Drawer, Steps} from 'antd';
import axiosInstance from "../../axios/axiosInstance";
import {Step} from "@material-tailwind/react";

const NewAdmissions = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [paymentAmount, setPaymentAmount] = useState('');
    const [paymentType, setPaymentType] = useState('cash');
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sortField, setSortField] = useState('id');
    const [sortOrder, setSortOrder] = useState('descend');
    const [totalOrders, setTotalOrders] = useState(0);
    const [orders, setOrders] = useState([]);
    const [selectedOrders, setSelectedOrders] = useState({});
    const [selectedVisitId, setSelectedVisitId] = useState(null);
    const [selectedVisitType, setSelectedVisitType] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paidServices, setPaidServices] = useState([]);
    const [paymentResponse, setPaymentResponse] = useState(null);

    const discountPercent = selectedOrders.discount ? selectedOrders.discount.percent : 0;


    const fetchOrdersForVisit = async (visitId, visitType) => {
        try {
            setLoading(true);
            const response = await axiosInstance.get(`/orders/${visitId}?type=${visitType}`);
            setSelectedOrders(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching orders for visit:', error);
            setLoading(false);
        }
    };

    const fetchOrders = async (page = 1, pageSize = 10) => {
        try {
            const response = await axiosInstance.get('/orders', {
                params: {
                    page,
                    pageSize
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching orders:', error);
            return [];
        }
    };


    useEffect(() => {
        fetchAndSetOrders();
    }, [currentPage, pageSize]);


    const payForServices = async (visitId, amount, paymentType) => {
        try {
            const response = await axiosInstance.post(`/visit/pay/${visitId}`, {
                amount: parseInt(amount),
                type: paymentType
            });
            const services = selectedOrders.orders.map(order => ({
                service: order.service.name,
                count: order.count,
                amount: order.amount
            }));
            fetchAndSetOrders();
            setPaidServices(services);
            setPaymentResponse(response.data.data);
            setPaymentSuccess(true);
            setCurrentStep(1);
        } catch (error) {
            console.error("Error processing payment:", error);
        }
    };

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const columns = [
        {
            title: 'Ордер ID', // Order ID
            dataIndex: 'id',
            key: 'id',
            sorter: true,
        },
        {
            title: 'Беморнинг исми',
            dataIndex: ['patient', 'name'],
            key: 'patient_name',
        },
        {
            title: 'Бемор коди',
            dataIndex: ['patient', 'code'],
            key: 'patient_code',
        },
        // {
        //     title: 'Ордер тури',
        //     dataIndex: 'orderable_type',
        //     key: 'order_type',
        // },
        {
            title: 'Сумма',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount) => `${amount.toLocaleString()} UZS`,
        },
        {
            title: 'Транзакция',
            dataIndex: 'transaction',
            key: 'transaction',
            render: (transaction) => `${transaction.toLocaleString()} UZS`,
        },
        {
            title: 'Қарз',
            dataIndex: 'credit',
            key: 'credit',
            render: (credit) => `${credit.toLocaleString()} UZS`,
        },
        {
            title: 'Тўлаш',
            key: 'pay',
            render: (text, record) => (
                <Button
                    type="primary"
                    onClick={() => {
                        setSelectedVisitId(record.id);
                        setSelectedVisitType(record.orderable_type);
                        fetchOrdersForVisit(record.orderable_id, record.orderable_type.toLowerCase());
                        setIsModalVisible(true);
                    }}
                >
                    Тўлаш
                </Button>
            ),
        }
    ];

    const handleDrawerClose = () => {
        setIsModalVisible(false);
        setCurrentStep(0);  // Сброс шага при закрытии модального окна
        setPaymentAmount('');  // Очистить данные
        setPaymentSuccess(false);
    };

    const fetchAndSetOrders = async () => {
        setLoading(true);
        const data = await fetchOrders(currentPage, pageSize, sortField, sortOrder);
        setOrders(data);
        setLoading(false);
    };


    const printReceipt = () => {
        const printContent = document.getElementById("receipt-content");
        const printWindow = window.open('', '', 'width=600,height=400');
        printWindow.document.write('<html><head><title>Чек</title>');
        printWindow.document.write('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/antd/4.16.13/antd.min.css" />'); // Add Ant Design CSS
        printWindow.document.write('<link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">'); // Add Tailwind CSS
        printWindow.document.write('</head><body>');
        printWindow.document.write(printContent.innerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    };



    return (
        <div>
            <div className="px-10">
                <h1 className="text-xl font-semibold mb-3">Қарздорлар рўхати</h1>
                <Input.Search
                    placeholder="ФИОни киритинг"
                    allowClear
                    enterButton="Излаш"
                    onSearch={handleSearch}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ width: 300, marginBottom: 16 }}
                />
            </div>
            <Spin spinning={loading}>
                <Table
                    columns={columns}
                    dataSource={orders}
                    rowKey="id"
                    pagination={{
                        current: currentPage,
                        pageSize: pageSize,
                        total: totalOrders,
                        onChange: (page, pageSize) => {
                            setCurrentPage(page);
                            setPageSize(pageSize);
                            fetchAndSetOrders();
                        },
                    }}
                    loading={loading}
                    onChange={(pagination, filters, sorter) => {
                        setSortField(sorter.field);
                        setSortOrder(sorter.order);
                        fetchAndSetOrders();
                    }}
                />
            </Spin>

            <Drawer
                title={`Тўлаш - ${selectedVisitId}`}
                visible={isModalVisible}
                onClose={handleDrawerClose}
                width="100%"
                footer={null}
            >
                <div className="flex justify-around relative max-h-screen overflow-y-auto">
                    <div className="flex-1 pr-4">
                        <Table
                            size="small"
                            rowClassName="even:bg-gray-50"
                            columns={[
                                {title: "Ордер ID", dataIndex: "id", key: "id"},
                                {title: "Хизмат", dataIndex: ["service", "name"], key: "service"},
                                {title: "Бемор", dataIndex: ["patient", "name"], key: "patient"},
                                {
                                    title: "Сумма",
                                    dataIndex: "amount",
                                    key: "amount",
                                    render: (amount) => {
                                        const originalAmount = amount / (1 - discountPercent / 100);
                                        return discountPercent ? (
                                            <span>
                        <span style={{ textDecoration: 'line-through', color: 'red', marginRight: '8px' }}>
                            {`${Number(originalAmount).toLocaleString()} UZS`}
                        </span>
                                                {`${Number(amount).toLocaleString()} UZS`}
                    </span>
                                        ) : (
                                            `${Number(amount).toLocaleString()} UZS`
                                        );
                                    }
                                },
                                {title: "Сон", dataIndex: "count", key: "count"},
                                {
                                    title: "Яратилган сана",
                                    dataIndex: "created_at",
                                    key: "created_at",
                                    render: (date) => new Date(date).toLocaleString(),
                                },
                            ]}
                            dataSource={selectedOrders.orders}
                            rowKey="id"
                            pagination={false}
                        />
                    </div>

                    <aside className="sticky top-0 flex-shrink-0 w-1/3 h-full bg-white shadow-lg p-4">
                        <h3>Ташриф рақами: {selectedVisitId}</h3>
                        <Divider/>

                        <Steps current={currentStep}>
                            <Step title="Ввод оплаты"/>
                            <Step title="Чек"/>
                        </Steps>

                        {currentStep === 0 ? (
                            <div style={{ marginTop: 24 }}>
                                <div style={{ marginBottom: 16 }}>
                                    <h4>Счет фактура:</h4>
                                    <p><strong>Қолган:</strong> {Number(selectedOrders.debit).toLocaleString()} UZS</p>
                                    <p>
                                        <strong>Миқдори:</strong> {Number(selectedOrders.total_amount).toLocaleString()} UZS
                                    </p>
                                    <p>
                                        <strong>Тўланган:</strong> {Number(selectedOrders.total_payed).toLocaleString()} UZS
                                    </p>
                                </div>
                                <Input
                                    placeholder="Введите сумму оплаты"
                                    value={paymentAmount}
                                    onChange={(e) => setPaymentAmount(e.target.value)}
                                    style={{ marginBottom: 16 }}
                                />
                                <Radio.Group
                                    onChange={(e) => setPaymentType(e.target.value)}
                                    value={paymentType}
                                >
                                    <Radio value="card">Карта</Radio>
                                    <Radio value="cash">Наличные</Radio>
                                </Radio.Group>
                                <div style={{ marginTop: 16 }}>
                                    <Button
                                        block
                                        type="primary"
                                        onClick={() =>
                                            payForServices(selectedVisitId, paymentAmount, paymentType)
                                        }
                                    >
                                        Оплатить
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div id="receipt-content" className="text-left w-full text-sm p-6 overflow-auto">
                                    <div className="text-center">
                                        <h2 className="text-xl font-semibold">OXU MED DENTAL</h2>
                                        <span>Медицинская Клиника</span> <br />
                                        <small>+998 91 831 99 99</small>
                                    </div>
                                    <div className="flex mt-4 text-xs">
                                        <div className="flex-grow">Чек №: <span>{selectedVisitId}</span></div>
                                        <div>{new Date().toLocaleString()}</div>
                                    </div>
                                    <hr className="my-2" />
                                    <div>
                                        <table className="w-full text-xs">
                                            <thead>
                                            <tr>
                                                <th className="py-1 w-1/12 text-center">#</th>
                                                <th className="py-1 text-left">Услуга</th>
                                                <th className="py-1 w-2/12 text-center">Кол-во</th>
                                                <th className="py-1 w-3/12 text-right">Сумма</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {paidServices.map((service, index) => (
                                                <tr key={index}>
                                                    <td className="py-2 text-center">{index + 1}</td>
                                                    <td className="py-2 text-left">
                                                        <span>{service.service}</span>
                                                        <br />
                                                        <small>{service.amount.toLocaleString()} сум</small>
                                                    </td>
                                                    <td className="py-2 text-center">{service.count}</td>
                                                    <td className="py-2 text-right">{(service.amount * service.count).toLocaleString()} сум</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <hr className="my-2" />
                                    <div>
                                        <div className="flex font-semibold">
                                            <div className="flex-grow">Общая сумма</div>
                                            {selectedOrders.discount ? (
                                                <span>
                            <span style={{ textDecoration: 'line-through', color: 'gray', marginRight: '8px' }}>
                                {`${Number(paymentResponse?.total_amount / (1 - selectedOrders?.discount?.percent / 100) || 0).toLocaleString()} сум`}
                            </span>
                                                    {`${Number(paymentResponse?.total_amount || 0).toLocaleString()} сум`}
                        </span>
                                            ) : (
                                                `${Number(paymentResponse?.total_amount || 0).toLocaleString()} сум`
                                            )}
                                        </div>
                                        <div className="flex text-xs">
                                            <div className="flex-grow">Оплачено</div>
                                            <div>{paymentAmount?.toLocaleString()} сум</div>
                                        </div>
                                        <div className="flex text-xs">
                                            <div className="flex-grow">Дебет</div>
                                            <div>{Number(paymentResponse?.debit || 0).toLocaleString()} сум</div>
                                        </div>
                                        <div className="flex text-xs">
                                            <div className="flex-grow">В общем оплачено</div>
                                            <div>{Number(paymentResponse?.total_payed || 0).toLocaleString()} сум</div>
                                        </div>
                                        <div className="flex text-xs">
                                            <div className="flex-grow">Тип оплаты</div>
                                            <div>{paymentType === 'cash' ? 'Наличные' : 'Карта'}</div>
                                        </div>
                                        <div className="flex text-xs mt-2">
                                            <div className="flex-grow">Скидка (%)</div>
                                            <div>{selectedOrders ? `${selectedOrders?.discount?.percent}%` : 'Нет скидки'}</div>
                                        </div>
                                    </div>
                                    <hr className="my-2" />
                                    <div id="legalcopy" className="text-center">
                                        <p className="legal text-center">
                                            <strong>Соғ булинг!</strong> Клиникамизга ташриф буюрганингиз учун ташаккур.
                                        </p>
                                    </div>
                                </div>

                                <Button
                                    className="mb-5"
                                    block
                                    type="primary"
                                    onClick={printReceipt}
                                >
                                    Чекни чиқариш
                                </Button>
                                <Button
                                    block
                                    type="default"
                                    onClick={handleDrawerClose}
                                >
                                    Завершить
                                </Button>
                            </>
                        )}

                    </aside>
                </div>
            </Drawer>

        </div>
    );
};

export default NewAdmissions;
