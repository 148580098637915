import React, {useEffect, useState} from 'react';
import {Button, Input, message, Modal, Table, Tooltip, Select, Drawer, Tag} from "antd";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import axiosInstance from "../../axios/axiosInstance";
import {FaMinus} from "react-icons/fa6";

function Warehouse(props) {
   const [requests, setRequests] = useState([]);
   const [categories, setCategories] = useState([]); // State to hold categories
   const [isOpen, setIsOpen] = useState(false);
   const [modalMode, setModalMode] = useState('add'); // 'add' or 'edit'
   const [selectedRequest, setSelectedRequest] = useState(null);
   const [data, setData] = useState({
      category_id: null,
      name: '',
      qty: '',
      price: ''
   });
   const [isHistoryDrawerVisible, setIsHistoryDrawerVisible] = useState(false); // For drawer visibility
   const [selectedHistory, setSelectedHistory] = useState([]); // Store selected item's history

   const openHistoryDrawer = (histories) => {
      setSelectedHistory(histories); // Set the selected histories
      setIsHistoryDrawerVisible(true); // Show the drawer
   };

   const closeHistoryDrawer = () => {
      setIsHistoryDrawerVisible(false); // Close the drawer
   };

   // Fetch the list of requests
   const fetchRequests = async () => {
      try {
         const response = await axiosInstance.get('/financiers/warehouses');
         setRequests(response.data.data);
      } catch (error) {
         console.error('Error fetching requests:', error);
         message.error('Error fetching data');
      }
   };

   // Fetch the list of categories
   const fetchCategories = async () => {
      try {
         const response = await axiosInstance.get('/financiers/categories');
         setCategories(response.data.data);
      } catch (error) {
         console.error('Error fetching categories:', error);
         message.error('Error fetching categories');
      }
   };

   useEffect(() => {
      fetchRequests();
      fetchCategories(); // Fetch categories when component loads
   }, []);

   const openModal = (mode, request = null) => {
      setModalMode(mode);
      if (mode === 'edit' && request) {
         setSelectedRequest(request);
         setData({
            category_id: request.category.id,
            name: request.name,
            qty: request.qty,
            price: request.price
         });
      } else {
         setData({category_id: null, name: '', qty: '', price: ''});
      }
      setIsOpen(true);
   };

   const closeModal = () => {
      setIsOpen(false);
      setSelectedRequest(null);
      setData({category_id: null, name: '', qty: '', price: ''});
   };

   // POST request to add a new store request
   const addRequest = async () => {
      try {
         const response = await axiosInstance.post('/financiers/warehouses', data);
         setRequests([...requests, response.data.data]);
         message.success('Request added successfully');
         closeModal();
      } catch (error) {
         console.error('Error adding request:', error);
         message.error('Error adding request');
      }
   };

   // PUT request to update an existing store request
   const updateRequest = async () => {
      try {
         const response = await axiosInstance.put(`/financiers/warehouses/${selectedRequest.id}`, data);
         setRequests(requests.map((req) => (req.id === selectedRequest.id ? response.data.data : req)));
         message.success('Request updated successfully');
         closeModal();
      } catch (error) {
         console.error('Error updating request:', error);
         message.error('Error updating request');
      }
   };

   // DELETE request to delete an existing store request
   const deleteRequest = async (requestId) => {
      try {
         await axiosInstance.delete(`/financiers/warehouses/${requestId}`);
         setRequests(requests.filter((req) => req.id !== requestId));
         message.success('Request deleted successfully');
      } catch (error) {
         console.error('Error deleting request:', error);
         message.error('Error deleting request');
      }
   };

   // Logic to either add or update the request based on modal mode
   const handleOk = async () => {
      if (!data.category_id || !data.name || !data.qty || !data.price) {
         message.error('Please fill all fields');
         return;
      }
      if (modalMode === 'add') {
         await addRequest();
      } else {
         await updateRequest();
      }
   };

   const columns = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id',
      },
      {
         title: 'категория',
         dataIndex: 'category',
         key: 'category_id',
         render: (category) => {
            return category.name;
         }
      },
      {
         title: 'номи',
         dataIndex: 'name',
         key: 'name',
      },
      {
         title: 'миқдор',
         dataIndex: 'qty',
         key: 'qty',
      },
      {
         title: 'нархи',
         dataIndex: 'price',
         key: 'price',
         render: (text, record) => <span>{new Intl.NumberFormat('ru-UZ').format(record.price)} сўм</span>
      },
      {
         title: 'Умуммий',
         render: (text, record) => <span>{new Intl.NumberFormat('ru-UZ').format(record.qty * record.price)} сўм</span>
      },

      {
         title: 'Тарихи',
         key: 'histories',
         render: (_, record) => (
             <Button style={{color: '#00AA81'}} type="link" onClick={() => openHistoryDrawer(record.histories)}>
                Тарихини кўриш
             </Button>
         )
      },
      {
         title: 'ҳаракат',
         key: 'action',
         render: (text, record) => (
             <div>
                <Tooltip title="Edit">
                   <Button
                       type="dashed"
                       icon={<EditOutlined/>}
                       onClick={() => openModal('edit', record)}
                       style={{marginRight: 8}}
                   />
                </Tooltip>
                <Tooltip title="Delete">
                   <Button
                       type="dashed"
                       danger
                       icon={<DeleteOutlined/>}
                       onClick={() => deleteRequest(record.id)}
                   />
                </Tooltip>
             </div>
         ),
      },
   ];

   const renderStatusTag = (status) => {
      if (!status) {
         return <Tag color="default">Ноаниқ</Tag>;
      }
      switch (status) {
         case 'process':
            return <Tag color="blue">Жараёнда</Tag>;
         case 'accept':
            return <Tag color="#00AA81">Қабул қилинди</Tag>;
         case 'cancel':
            return <Tag color="red">Рад этилди</Tag>;
         default:
            return <Tag color="default">Ноаниқ</Tag>;
      }
   };

   // Drawer columns for histories
   const historyColumns = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id',
      },
      {
         title: 'Миқдор',
         dataIndex: 'qty',
         key: 'qty',
         render: (qty) => <div className={'flex items-center'}><FaMinus/>{qty}</div>
      },
      {
         title: 'Холати',
         dataIndex: 'status',
         key: 'status',
         render: (status) => renderStatusTag(status)
      },
      {
         title: 'Сана',
         dataIndex: 'created_at',
         key: 'created_at',
      },
      {
         title: 'Юборувчи',
         dataIndex: 'sender',
         key: 'sender',
         render: (sender) => sender.name,
      },
      {
         title: 'Қабул қилувчи',
         dataIndex: 'receive',
         key: 'receive',
         render: (receive) => receive ? receive.name : 'Қабул қилинмаган',
      },
      {
         title: 'Изоҳ',
         dataIndex: 'history',
         key: 'comment',
         render: (history) => history?.comment ? <p style={{color: '#00AA81'}}>{history?.comment}</p> : 'Изоҳ йўқ',
      },
   ];

   return (
       <div>
          <div className="flex px-10 justify-between items-center mb-4">
             <h1 className="text-xl font-semibold mb-3">Склат Маҳсулот</h1>
             <Button type="primary" icon={<PlusOutlined/>} onClick={() => openModal('add')}>
                Янги қўшиш
             </Button>
          </div>
          <Table columns={columns} dataSource={requests} rowKey="id" pagination={{pageSize: 10}}/>

          {/* Drawer for showing history */}
          <Drawer
              title="Тарихи"
              placement="bottom"
              onClose={closeHistoryDrawer}
              open={isHistoryDrawerVisible}
              // width={500}
          >
             <Table columns={historyColumns} dataSource={selectedHistory} rowKey="id" pagination={false}/>
          </Drawer>

          <Modal
              title={modalMode === 'add' ? 'Янги қўшиш' : 'ўзгартириш'}
              open={isOpen}
              onCancel={closeModal}
              onOk={handleOk}
              okText={modalMode === 'add' ? 'Янги қўшиш' : 'ўзгартириш'}
          >
             <Select
                 placeholder="категория"
                 value={data.category_id}
                 onChange={(value) => setData({...data, category_id: value})}
                 style={{width: '100%', marginBottom: 16}}
             >
                {categories.map((category) => (
                    <Select.Option key={category.id} value={category.id}>
                       {category.name}
                    </Select.Option>
                ))}
             </Select>
             <Input
                 placeholder="номи"
                 value={data.name}
                 onChange={(e) => setData({...data, name: e.target.value})}
                 style={{marginBottom: 16}}
             />
             <Input
                 placeholder="миқдор"
                 value={data.qty}
                 onChange={(e) => setData({...data, qty: e.target.value})}
                 style={{marginBottom: 16}}
             />
             <Input
                 placeholder="нархи"
                 value={data.price}
                 onChange={(e) => setData({...data, price: e.target.value})}
                 style={{marginBottom: 16}}
             />
          </Modal>
       </div>
   );
}

export default Warehouse;
