import React, { useState, useEffect } from 'react';
import { Button, Spin, message, Select } from 'antd';
import axiosInstance from "../axios/axiosInstance";
import dayjs from "dayjs";

const { Option } = Select;

const StationaryDischarge = ({ onSubmit, initialData }) => {
    const [doctors, setDoctors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchDoctors = async () => {
            setIsLoading(true);
            try {
                const response = await axiosInstance.get('/admin/users');
                const doctorUsers = response.data.data.filter(user => user.roles.includes('doctor'));
                setDoctors(doctorUsers);
            } catch (error) {
                console.error("Error loading doctors:", error);
                message.error('Error loading doctors');
            } finally {
                setIsLoading(false);
            }
        };

        fetchDoctors();
    }, []);

    const handleSubmit = async (status) => {
        setIsLoading(true);
        try {
            const payload = {
                ...initialData,
                status,
                start_at: dayjs(initialData.start_at).format('YYYY-MM-DD HH:mm:ss'),
                end_at: initialData.end_at ? dayjs(initialData.end_at).format('YYYY-MM-DD HH:mm:ss') : null,
            };

            await axiosInstance.put(`/stationary/${initialData.id}`, payload);
            message.success('Data successfully updated!');
            onSubmit();
        } catch (error) {
            console.error("Error saving data:", error);
            message.error('Error saving data');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            {isLoading ? (
                <Spin />
            ) : (
                <>
                    <div style={{ marginTop: '20px' }}>
                        <Button type="danger" onClick={() => handleSubmit('discharge')} style={{ marginLeft: '10px' }}>Discharge</Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default StationaryDischarge;
