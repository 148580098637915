import React, {useState, useEffect, Fragment} from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
    Tooltip as Tool,
    Typography
} from '@material-tailwind/react';
import { Listbox, Transition } from "@headlessui/react";
import {TrashIcon} from '@heroicons/react/24/solid';
import {
    Button,
    Modal,
    TimePicker as TimePickerInput,
    Input,
    Tooltip,
    Calendar as AntCalendar,
    Badge,
    Select, DatePicker, Tag
} from "antd";
import moment from 'moment';
import 'moment/locale/uz';
import allLocales from '@fullcalendar/core/locales-all'
import axiosInstance from "../axios/axiosInstance";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/high-res.css'
import {IoCall} from "react-icons/io5";
import {TbGridDots} from "react-icons/tb";
import toast, {Toaster} from "react-hot-toast";
import {StarIcon} from "@heroicons/react/24/solid";
import {LuFileHeart, LuFolderHeart} from "react-icons/lu";
import {FaSmile} from "react-icons/fa";
import dayjs from "dayjs";


const DropDowns = ({ list }) => {
    const [selected, setSelected] = useState(list[0]);
    return (
        <Listbox value={selected} onChange={setSelected}>
            <div className="relative mt-1">
                <Listbox.Button className="py-2.5 px-2 border border-[#E7E7E7] flex justify-center items-center gap-1 rounded text-sm text-[#637381] font-normal">
                    <span className="block truncate">{selected.name}</span>{" "}
                    <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="14" height="14" fill="white" />
                        <path
                            d="M11 5L7.5 8.5L4 5"
                            stroke="#637381"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className="absolute mt-1 z-50 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm min-w-[100px]">
                        {list?.map((person, personIdx) => (
                            <Listbox.Option
                                key={personIdx}
                                className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-2 pr-4 ${
                                        active ? "bg-[#F6F8FA] text-gray-900" : "text-gray-900"
                                    }`
                                }
                                value={person}
                            >
                                {({ selected }) => (
                                    <span
                                        className={`block truncate ${
                                            selected
                                                ? "font-medium text-[#212B36]"
                                                : "font-normal text-[#637381]"
                                        }`}
                                    >
                    {person.name}
                  </span>
                                )}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    );
};


const people = [
    { name: "Сегодня" },
    { name: "Неделя" },
    { name: "Месяц" },
];


export default function Calendar() {
    const [services, setServices] = useState([]);
    const [country, setCountry] = useState(177);
    const [selectedTime, setSelectedTime] = useState(null);
    const [eventTitle, setEventTitle] = useState('');
    const [eventNumber, setEventNumber] = useState('+998');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedService, setSelectedService] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [events, setEvents] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [eventsToday, setEventsToday] = useState(0);
    const [totalPatients, setTotalPatients] = useState(0);
    const [dateRange, setDateRange] = useState({ start: null, end: null });


    useEffect(() => {
        fetchEvents();
        fetchServices();
    }, []);


    const fetchServices = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get("/admin/service");
            setServices(response.data.data);
        } catch (error) {
            console.error("Ошибка при получении списка сервисов:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const onMiniCalendarSelect = (date) => {
        setSelectedDate(moment(date).format("YYYY-MM-DD")); // Update the selected date
    };

    const fetchEvents = async () => {
        try {
            const response = await axiosInstance.get("/calendar");
            const fetchedEvents = response.data.data; // Сохраняем данные из API
            setEvents(fetchedEvents);
            setFilteredEvents(fetchedEvents);

            // Получаем текущую дату
            const today = dayjs().format('YYYY-MM-DD');

            // Фильтруем события на сегодня
            const eventsForToday = fetchedEvents.filter(event => event.start_at === today);
            setEventsToday(eventsForToday.length);

            // Фильтруем события типа "booking"
            const bookingEvents = fetchedEvents.filter(event => event.type === "booking");
            setTotalPatients(bookingEvents.length);

            // Проверка на наличие событий
            if (fetchedEvents.length > 0) {
                // Преобразуем даты в объекты dayjs
                const startAtDates = fetchedEvents.map(event => dayjs(event.start_at));

                // Находим минимальную и максимальную дату вручную
                const minDate = startAtDates.reduce((min, date) => date.isBefore(min) ? date : min, startAtDates[0]);
                const maxDate = startAtDates.reduce((max, date) => date.isAfter(max) ? date : max, startAtDates[0]);

                // Устанавливаем диапазон дат
                setDateRange({
                    start: minDate.format('DD.MM.YYYY'),
                    end: maxDate.format('DD.MM.YYYY')
                });
            }
        } catch (error) {
            console.error("Ошибка при получении списка событий календаря:", error);
        }
    };



    const dateCellRender = (value) => {
        const formattedDate = moment(value).format("YYYY-MM-DD");

        const eventCount = events.filter(event => event.start_at.startsWith(formattedDate)).length;

        return eventCount > 0 ? (
            <div className="event-count">
                <span>{eventCount} визитов</span> {/* Отображение числа визитов */}
            </div>
        ) : null;
    };

    const handleDateSelect = (selectInfo) => {
        const selectedDate = selectInfo.startStr;
        setSelectedTime(null);
        setOpenDialog(true);
        setSelectedDate(selectedDate);
    };


    const handleDialogClose = () => {
        resetEventData();
        setOpenDialog(false);
    };

    const resetEventData = () => {
        setEventTitle('');
        setEventNumber('+998');
        setSelectedType('');
        setSelectedService('');
        setSelectedEvent(null);
        setSelectedTime('');
        setErrors({});
    };

    const handleTimeChange = (time) => {
        setSelectedTime(time ? time.format('HH:mm') : null);
    };


    const handleTitleChange = (event) => {
        setEventTitle(event.target.value);
    };

    const handleNumberChange = (phone) => {
        setEventNumber(phone); // Устанавливаем значение телефона
    };

    const handleEventClick = (clickInfo) => {
        const event = clickInfo.event;
        setEventTitle(event.title);
        setEventNumber(event.extendedProps.phone);
        setSelectedDate(event.extendedProps.start);
        setSelectedTime(event.extendedProps.start_time);

        const selectedService = services.find(service => service.id === event.extendedProps.service?.id);
        setSelectedService(selectedService ? {value: selectedService.id, label: selectedService.name} : '');

        setSelectedType(event.extendedProps.type || '');
        setSelectedEvent(event);
        setOpenDialog(true);
    };

    const handleSearchInputChange = (e) => {
        const searchText = e.target.value;
        setSearchText(searchText);
        filterEvents(searchText);
    };

    const filterEvents = (searchText) => {
        const filteredEvents = events.filter(event =>
            event.title?.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredEvents(filteredEvents);
    };


    const handleDeleteEvent = async () => {
        if (!selectedEvent?.id) return;
        try {
            const response = await axiosInstance.delete(`/calendar/${selectedEvent.id}`);
            if (response.status === 200) {
                fetchEvents();
                handleDialogClose();
            }
        } catch (error) {
            console.error("Ошибка при удалении события:", error);
        }
    };

    const handleUpdateEvent = async () => {
        if (validateFields()) return;

        const selectedDateTime = `${selectedDate} ${selectedTime}`;

        const updatedEventData = {
            title: eventTitle,
            phone: eventNumber,
            start_at: selectedDateTime,
        };

        try {
            const response = await axiosInstance.put(`/calendar/${selectedEvent.id}`, updatedEventData);
            if (response.status === 200) {
                fetchEvents();
                toast.success(`${response.data.data.title} учрашув малумоти ўзгартирилди `);
                handleDialogClose();
            }
        } catch (error) {
            console.error("Ошибка при обновлении события:", error);
        }
    };


    const handleEventDrop = async (info) => {
        const {event} = info;

        if (!event.start) return;

        const newStartDate = new Date(event.start);
        const formattedStartDate = `${newStartDate.getFullYear()}-${String(newStartDate.getMonth() + 1).padStart(2, '0')}-${String(newStartDate.getDate()).padStart(2, '0')} ${event.extendedProps.start_time}`;

        const updatedEvent = {
            title: event.title,
            phone: event.extendedProps.phone,
            start_at: formattedStartDate,
        };

        try {
            const response = await axiosInstance.put(`/calendar/${event.id}`, updatedEvent);
            if (response.status === 200) {
                toast.success(`Учрашув санаси ўзгартирилди ${response.data.data.start_at}`);
                fetchEvents();
            }
        } catch (error) {
            console.error("Error updating event:", error);
        }
    };


    const handleConfirmEvent = async () => {
        if (validateFields()) return;

        const selectedDateTime = `${selectedDate} ${selectedTime}`;
        const formattedDateTime = moment(selectedDateTime).format('YYYY-MM-DD HH:mm');

        const eventData = {
            title: eventTitle,
            phone: eventNumber,
            start_at: formattedDateTime,
        };

        try {
            const response = await axiosInstance.post("/calendar", eventData);
            fetchEvents();
            handleDialogClose();
        } catch (error) {
            console.error("Error creating event:", error);
        }
    };


    const renderEventContent = (eventInfo) => {
        const {title, phone, start_time} = eventInfo.event.extendedProps;
        return (
            <span
                class="inline-flex capitalize w-full items-center gap-x-3 py-1.5 px-3 rounded text-xs font-medium bg-[#fdf4ea] text-[#ff9f43]">
<span class="relative inline-flex items-center">
    <span
        class="absolute inline-flex w-6 h-6 rounded bg-[#ff9f43] opacity-75 animate-ping dark:bg-red-600"></span>
  <span class="relative inline-flex items-center justify-center w-8 h-8 rounded bg-[#ff9f43] text-white">
      <img src="/tooth2.png" className="pt-2 absolute"/>
  </span>
</span>

     <div className="ml-1">
        <span className="text-black font-medium">{title}</span> <br/>
         {start_time}
     </div>
    </span>
        );
    };


    const validateFields = () => {
        const newErrors = {};
        let hasError = false;

        if (!eventTitle.trim()) {
            newErrors.eventTitle = 'Илтимос ФИО-ни киринтинг';
            hasError = true;
        }

        if (!eventNumber.trim() || eventNumber.length < 10) {
            newErrors.eventNumber = 'Илтимос телефон ракамни киринтинг';
            hasError = true;
        }

        if (!selectedDate) {
            newErrors.selectedDate = 'Пожалуйста, выберите дату.';
            hasError = true;
        }

        if (!selectedTime) {
            newErrors.selectedTime = 'Илтимос санани киринтинг';
            hasError = true;
        }

        setErrors(newErrors);
        return hasError;
    };

    const customDayHeaders = ['Душанба', 'Сешанба', 'Чоршанба', 'Пайшанба', 'Жума', 'Шанба', 'Якшанба'];


    const formatPhoneNumber = (phone) => {
        return `+${phone.slice(0, 3)} ${phone.slice(3, 5)} ${phone.slice(5, 8)} ${phone.slice(8, 10)} ${phone.slice(10)}`;
    };

    const cardData = [
        {
            type: "Total Profit",
            percentage: "50.43%",
            arrow: "/up.svg",
            graph: "/stat.svg",
            price: "$ 329.50",
        },
        {
            type: "Total Profit",
            percentage: "50.43%",
            arrow: "/up.svg",
            graph: "/stat.svg",
            price: "$ 329.50",
        },
        {
            type: "Total Profit",
            percentage: "50.43%",
            arrow: "/up.svg",
            graph: "/stat2.svg",
            price: "$ 329.50",
        },
        {
            type: "Total Profit",
            percentage: "50.43%",
            arrow: "/up.svg",
            graph: "/stat.svg",
            price: "$ 329.50",
        },
    ];

    return (
        <div className="w-full">

            <div className="flex w-full relative">
                <div className="w-1/4  border-r px-3">
                    <div className="mb-4">
                        <AntCalendar fullscreen={false} />
                    </div>

                    <div className="">
                        <Input
                            prefix={<TbGridDots/>}
                            value={searchText}
                            onChange={handleSearchInputChange}
                            placeholder="Излаш..."
                            className="w-full border border-gray-300 p-2 mb-4 rounded-md"
                        />
                    </div>
                    <div className="flex items-center justify-between border-b flex-row-reverse pb-3 gap-x-3">
                        <div className="">
                            <DropDowns list={people}/>
                        </div>
                        <h1 className="text-sm text-left text-[#00AA81] flex items-center max-w-max w-full  font-medium rounded-r ">Барча
                            учрашувлар</h1>
                    </div>
                    <div className="max-h-[33vh] overflow-y-auto overflow-x-hidden">
                        {filteredEvents.map(event => (
                            <>
                                <div
                                    className="bg-white rounded-md p-1.5 flex flex-col transition-transform transform hover:scale-105 duration-200 ">
                                    <div className="flex justify-between items-center mb-1">
                                        <h3 className="text-sm font-medium text-gray-700 truncate">{event.title}</h3>
                                        <span
                                            className="text-xs text-gray-400">{event.start_at} {event.start_time}</span>
                                    </div>

                                    <div className="flex justify-between items-center">

                                        <Tag bordered={false} color="orange"  className="task-details-tag flex items-center gap-1 will-change-transform"
                                             style={{borderRadius: '5px', fontSize: '12px'}}>
                                            <IoCall className="w-4 h-4 mr-1"/>
                                            <span className="text-xs">{formatPhoneNumber(event.phone)}</span>
                                        </Tag>


                                        <Tag bordered={false}  color="#ff9f43"  className="task-details-tag will-change-transform"
                                             style={{borderRadius: '5px', fontSize: '12px'}}>
                                            Банд қилинган
                                        </Tag>
                                    </div>
                                </div>
                                <div className="border-b border-gray-200 my-1"/>
                            </>
                        ))}

                    </div>
                </div>

                <div className="w-full px-3">
                    <div
                        className="bg-gradient-to-tl mb-5 from-[#00AA81] to-[#38B2A8] rounded-xl p-5 relative overflow-hidden"
                    >
                        <svg
                            width="581"
                            height="239"
                            viewBox="0 0 581 239"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="absolute -top-14 -right-[27rem] sm:-right-64 lg:-top-28"
                        >
                            <path
                                d="M527.607 1.7273C580.879 -33.0372 543.535 -81.6071 518.204 -101.546C439.881 -162.473 430.701 -91.4797 317.582 -143.722C204.463 -195.965 227.141 -159.2 83.8863 17.2559C-59.3679 193.712 128.456 191.653 151.46 157.066C174.465 122.48 215.973 138.631 267.533 191.898C319.093 245.165 448.712 151.23 426.229 94.617C403.746 38.0046 461.017 45.183 527.607 1.7273Z"
                                stroke="white"
                                strokeOpacity="0.13"
                            />
                            <path
                                d="M504.037 3.63866C552.037 -27.7193 518.379 -71.544 495.551 -89.5367C424.965 -144.515 416.703 -80.4656 314.764 -127.614C212.825 -174.762 233.264 -141.589 104.2 17.5917C-24.8647 176.773 144.384 174.939 165.109 143.738C185.834 112.537 223.239 127.114 269.707 175.178C316.175 223.243 432.963 138.51 412.696 87.4313C392.43 36.3523 444.038 42.836 504.037 3.63866Z"
                                stroke="white"
                                strokeOpacity="0.13"
                            />
                            <path
                                d="M485.755 5.0211C529.659 -23.6299 498.882 -63.6584 478.006 -80.0913C413.456 -130.304 405.891 -71.7948 312.664 -114.85C219.438 -157.906 238.127 -127.606 120.065 17.8189C2.00333 163.244 156.797 161.547 175.756 133.043C194.715 104.539 228.924 117.849 271.416 161.749C313.909 205.649 420.734 128.232 402.205 81.5755C383.675 34.9188 430.875 40.8348 485.755 5.0211Z"
                                stroke="white"
                                strokeOpacity="0.13"
                            />
                            <path
                                d="M468.499 6.88949C508.518 -19.2443 480.459 -55.7637 461.428 -70.7567C402.583 -116.569 395.692 -63.1941 310.707 -102.48C225.722 -141.766 242.761 -114.123 135.153 18.5341C27.5446 151.191 168.648 149.656 185.928 123.654C203.208 97.6522 234.392 109.798 273.13 149.85C311.868 189.902 409.239 119.286 392.344 76.721C375.45 34.1558 418.476 39.5567 468.499 6.88949Z"
                                stroke="white"
                                strokeOpacity="0.13"
                            />
                            <path
                                d="M449.738 8.32684C485.547 -15.0868 460.432 -47.8173 443.399 -61.2558C390.732 -102.319 384.573 -54.4882 308.515 -89.7062C232.456 -124.924 247.709 -100.15 151.43 18.7138C55.1508 137.577 181.423 136.222 196.883 112.923C212.343 89.6242 240.251 100.513 274.924 136.411C309.596 172.309 396.722 109.041 381.597 70.8944C366.473 32.7477 404.977 37.5939 449.738 8.32684Z"
                                stroke="white"
                                strokeOpacity="0.13"
                            />
                            <path
                                d="M430.012 9.82216C461.401 -10.6816 439.392 -39.336 424.464 -51.1002C378.306 -87.047 372.903 -45.1683 306.242 -75.9951C239.581 -106.822 252.947 -85.1321 168.546 18.9493C84.1452 123.031 194.822 121.83 208.375 101.429C221.928 81.0282 246.389 90.5588 276.775 121.985C307.161 153.412 383.533 98.0083 370.281 64.6106C357.028 31.213 390.777 35.4519 430.012 9.82216Z"
                                stroke="white"
                                strokeOpacity="0.13"
                            />
                        </svg>
                        <div
                            className="flex gap-4 sm:flex-wrap ssm:gap-12 xl:gap-6 flex-col xl:flex-row xl:justify-between xl:items-end lg:h-fit">
                            <div className="flex flex-col gap-3">
                    <span className="text-lg font-semibold text-[#FDFDFF]">
                   OXU MED DENTAL
                    </span>
                                <div className="flex gap-1 items-center">
                      <span className="font-medium text-sm text-white/80">
                         Беморларнинг кутилаётган ташрифлари:   {dateRange.start} - {dateRange.end}
                      </span>
                                    <svg
                                        width="10"
                                        height="10"
                                        viewBox="0 0 10 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M8.90625 9.375H2.65625C2.53193 9.375 2.4127 9.32561 2.32479 9.23771C2.23689 9.1498 2.1875 9.03057 2.1875 8.90625V2.5C2.1875 2.41712 2.22042 2.33763 2.27903 2.27903C2.33763 2.22042 2.41712 2.1875 2.5 2.1875H8.90625C9.03057 2.1875 9.1498 2.23689 9.23771 2.32479C9.32561 2.4127 9.375 2.53193 9.375 2.65625V8.90625C9.375 9.03057 9.32561 9.1498 9.23771 9.23771C9.1498 9.32561 9.03057 9.375 8.90625 9.375Z"
                                            fill="white"
                                            fillOpacity="0.8"
                                        />
                                        <path
                                            d="M2.1875 1.5625H7.8125V1.09375C7.8125 0.96943 7.76311 0.850201 7.67521 0.762294C7.5873 0.674386 7.46807 0.625 7.34375 0.625H1.17188C1.02683 0.625 0.887735 0.682617 0.785176 0.785176C0.682617 0.887735 0.625 1.02683 0.625 1.17188V7.34375C0.625 7.46807 0.674386 7.5873 0.762294 7.67521C0.850201 7.76311 0.96943 7.8125 1.09375 7.8125H1.5625V2.1875C1.5625 2.02174 1.62835 1.86277 1.74556 1.74556C1.86277 1.62835 2.02174 1.5625 2.1875 1.5625Z"
                                            fill="white"
                                            fillOpacity="0.8"
                                        />
                                    </svg>
                                </div>
                                <span className="text-[22px] font-bold text-[#FDFDFF] flex items-center gap-2">
                        Хуш Келибсиз
                            <img className="h-10" src="/waving-hand_1f44b.png" />
                    </span>
                                <span className="text-sm font-medium text-[#FDFDFF] tracking-[1.4px]">
                        Умумий: {totalPatients} беморлар
                    </span>
                            </div>
                            <div
                                className="flex gap-7 flex-col sm:flex-row h-full sm:justify-start self-start sm:self-center z-10">
                                <div className="flex flex-col items-center text-center gap-3 h-fit">
                                    <div className="flex flex-col pl-1">
                        <span className="text-sm font-semibold text-white/80 tracking-[1.5px]">
                          Визит Сони
                        </span>
                                        <span className="text-lg font-semibold text-[#FDFDFF]">
                       {events?.length}
                        </span>
                                    </div>
                                    <button
                                        className="py-2.5 rounded-[10px] font-semibold text-sm text-white text-center bg-[#f9f9f94d] min-w-[150px] hover:scale-105">
                                        Янги қўшиш
                                    </button>
                                </div>
                                <div className="flex flex-col items-center text-center gap-3 h-fit">
                                    <div className="flex flex-col">
                        <span className="text-sm font-semibold text-white/80 tracking-[1.5px]">
                          Бугун
                        </span>
                                        <span className="text-lg font-semibold text-[#FDFDFF]">
                         {eventsToday}
                        </span>
                                    </div>
                                    <button
                                        className="py-2.5 rounded-[10px] font-semibold text-white text-center bg-[#f9f9f94d] min-w-[150px] text-sm hover:scale-105">
                                        Визитлар
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="calendar-container">
                        <FullCalendar
                            locales={allLocales}
                            locale={'uz'}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            headerToolbar={{
                                start: 'prev,next today',
                                center: 'title',
                                end: 'dayGridMonth,timeGridWeek,timeGridDay',
                            }}

                            customButtons={{
                                customMessage: {
                                    text: `Хуш келибсиз бугун ${new Date().toLocaleDateString('uz')}`,
                                    click: () => alert('This is a custom message!')
                                }
                            }}
                            themeSystem="standart"
                            editable={true}
                            height={'75vh'}
                            selectable={true}
                            selectMirror={true}
                            events={events.map(event => ({
                                id: event.id,
                                title: event.title,
                                start: event.start_at,
                                start_time: event.start_time,
                                end: event.end_at,
                                extendedProps: {
                                    phone: event.phone,
                                    title: event.title,
                                    start: event.start_at,
                                    start_time: event.start_time,
                                },
                            }))}
                            navLinks={true}
                            nowIndicator={true}
                            weekNumbers={false}
                            showNonCurrentDates={true}
                            dayHeaders={true}
                            select={handleDateSelect}
                            eventContent={renderEventContent}
                            eventClick={handleEventClick}
                            dayHeaderContent={(args) => {
                                const dayIndex = (args.date.getDay() + 6) % 7; // Сдвигаем индекс на 6 вперед
                                return customDayHeaders[dayIndex];
                            }}
                            eventDrop={handleEventDrop}
                        />


                        {/* React Tooltip for event hover */}
                        <Tooltip id="eventTooltip"/>
                    </div>
                </div>
            </div>
            <Modal title="Бемор қўшиш" centered footer={[
                <div className="flex gap-3 w-full">
                    <Button block type="primary"
                            className='flex w-full justify-between gap-x-1 items-center font-medium'
                            onClick={selectedEvent ? handleUpdateEvent : handleConfirmEvent}>
                        {selectedEvent ? 'Ўзгартириш' : 'Сақлаш'}
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"
                             className="lucide lucide-clipboard-plus">
                            <rect width="8" height="4" x="8" y="2" rx="1" ry="1"/>
                            <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"/>
                            <path d="M9 14h6"/>
                            <path d="M12 17v-6"/>
                        </svg>
                    </Button>
                    {selectedEvent && (
                        <Button
                            block
                            className='flex w-full justify-between gap-x-1 items-center group font-medium bg-gray-100 text-gray-800 hover:bg-gray-200 focus:ring-2 focus:ring-gray-300 rounded shadow-sm'
                            onClick={handleDeleteEvent}>
                            Кун учун ёзувни бекор қилиш
                            <TrashIcon className="h-4 w-4 group-hover:text-inherit"/>
                        </Button>

                    )}
                </div>
            ]} className='w-min' open={openDialog} onCancel={handleDialogClose}>
                <div className="mb-4">
                    <label htmlFor="time" className="block mb-1 text-sm font-medium text-gray-700">
                        ФИО:
                    </label>
                    <Input
                        name="name"
                        value={eventTitle}
                        onChange={handleTitleChange}
                        placeholder="ФИО: *"
                        labelProps={{
                            className: "hidden",
                        }}
                        containerProps={{className: "min-w-[100px]"}}
                        size="lg"

                        error={errors.eventTitle}
                    />
                    {errors.eventTitle && <p className="text-red-500 text-xs mt-1">{errors.eventTitle}</p>}
                    <div className="mt-4 ">
                        <label htmlFor="time" className="block text-sm font-medium text-gray-700">
                            Телефон раками:
                        </label>
                        <PhoneInput
                            dropdownClass="will-change-auto"
                            countryCodeEditable={false}
                            disableDropdown={true}
                            country={'uz'}
                            preferredCountries={['uz']}
                            onlyCountries={['uz']}
                            inputClass="ant-input"
                            containerClass="ant-input"
                            inputStyle={{width: '100%'}}
                            placeholder="Введите номер телефона"
                            value={eventNumber || ''}
                            onChange={(phone) => handleNumberChange(phone)}
                        />


                    </div>
                    {errors.eventNumber && <p className="text-red-500 text-xs mt-1">{errors.eventNumber}</p>}
                </div>
                <TimePickerInput
                    value={selectedTime ? moment(selectedTime, 'HH:mm') : null}
                    needConfirm={false}
                    id="time"
                    className="w-full"
                    onChange={handleTimeChange}
                    format={'HH:mm'}
                />

            </Modal>
        </div>
    );
}
