import React, {useEffect, useState} from 'react';
import {Modal, Form, Input, Button, message, Select} from 'antd';
import axiosInstance from "../axios/axiosInstance";
import toast from "react-hot-toast";
const { Option } = Select;


const AddServiceModal = ({ visible, onCancel, onSubmit, stationaryId, fetchStationary  }) => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [primaryServices, setPrimaryServices] = useState([]);
    const [selectedService, setSelectedService] = useState(null);


    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await axiosInstance.get("/admin/service?primary=0");
                const services = response.data.data; // Service data from API
                setPrimaryServices(services); // Set services to state
            } catch (error) {
                console.error("Ошибка при получении списка услуг:", error);
                message.error('Ошибка при получении списка услуг');
            }
        };

        fetchServices();
    }, []);

    const handleServiceSelect = (selectedOption) => {
        setSelectedService(selectedOption);
    };

    const handleSubmit = async (values) => {
        const formData = new FormData();
        formData.append('service_id', selectedService);
        formData.append('count', values.count);
        formData.append('remark', values.remark);

        setIsLoading(true);
        try {
            await axiosInstance.post(`/stationary/service/${stationaryId}`, formData);
            toast.success('Сервис успешно добавлен!');
            fetchStationary();
            form.resetFields();
            onSubmit();
        } catch (error) {
            console.error('Ошибка при добавлении сервиса:', error);
            message.error('Ошибка при добавлении сервиса');
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        form.resetFields();
        onCancel();
    }

    return (
        <Modal
            centered
            visible={visible}
            title="Добавить новый сервис"
            onCancel={handleClose}
            footer={[
                <Button key="cancel" onClick={handleClose}>
                    Отмена
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={() => form.submit()}
                >
                    Добавить
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
            >
                <Form.Item
                    label="Хизмат"
                    name="service_id"
                    rules={[{ required: true, message: 'Хизматни танланг' }]}
                >
                    <Select
                        placeholder="Хизмат турини танланг"
                        onChange={handleServiceSelect}
                    >
                        {primaryServices.map(service => (
                            <Option key={service.id} value={service.id}>
                                {service.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Количество"
                    name="count"
                    rules={[{ required: true, message: 'Введите количество' }]}
                >
                    <Input type="number" placeholder="Введите количество" />
                </Form.Item>
                <Form.Item
                    label="Примечание"
                    name="remark"
                    rules={[{ required: true, message: 'Введите примечание' }]}
                >
                    <Input placeholder="Введите примечание" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddServiceModal;
